import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Container,
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Popover
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppsIcon from '@material-ui/icons/Apps';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { FaDownload, FaFilter } from 'react-icons/fa';

import { getComparator, stableSort } from './utilities';
import {
  useStyles1,
  useToolbarStyles,
  useStyles,
  StyledCell,
  StyledTablePagination,
  StyledPagination,
  StyledButton
} from './styles';
import {
  Data,
  EnhancedTableProps,
  HeadCell,
  EnhancedTableToolbarProps,
  ShowFields,
  Export,
  Toast,
  Filter,
  Body
} from './Models';
import StyledDialog from './StyledDialog';
import FilterDialog from './FilterDialog';
import ReactExport from './Export.d';
import { fetchData, multipleDeletes } from './api';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'sampleCount', numeric: false, disablePadding: true, label: 'Sample Count' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'createdAt' }
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes1 = useStyles1();
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, fields } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledCell padding="checkbox">
          <Box pl={0} pr={10}>
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              indeterminate={numSelected > 0 && rowCount > numSelected}
              inputProps={{ 'aria-label': 'select all desserts' }}
              onChange={onSelectAllClick}
            />
          </Box>
        </StyledCell>
        {headCells.map(
          (headCell) =>
            fields[headCell.id] && (
              <StyledCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <b className={classes1.heading}>{headCell.label}</b>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, setDelToast } = props;
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popover = open ? 'simple-popover' : undefined;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMultipleDeletes = () => {
    setLoader(true);
    multipleDeletes(selected)
      .then(async (response: Response) => {
        setOpen(false);
        setLoader(false);
        const data = await response.json();

        if (data.status === 200) {
          setDelToast(data.message, 1);
        } else {
          setDelToast(data.message, 0);
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setDelToast(err.message, 0);
      });
  };

  return numSelected > 0 ? (
    <Box pb={5}>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 && (
          <Typography className={classes.title} color="inherit" component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}
        {/* <IconButton  aria-describedby={popover} aria-label="Edit" size="medium"  onClick={handleClickPopover} >
          <EditIcon fontSize="inherit" />
        </IconButton> */}
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          id={popover}
          open={openPopover}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handleClosePopover}
        >
          <Box p={3} pl={0}>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem label="Print Order Labels" nodeId="1" />
              <br />
              <TreeItem label="Download Reports" nodeId="2">
                <TreeItem label="To Zip" nodeId="3" />
                <TreeItem label="To PDF" nodeId="4" />
              </TreeItem>
            </TreeView>
          </Box>
        </Popover>
        {numSelected > 0 && (
          <Tooltip title="Delete">
            {loader ? (
              <CircularProgress />
            ) : (
              <>
                <IconButton aria-label="delete" onClick={handleClickOpen}>
                  <DeleteIcon />
                </IconButton>
                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={open}
                  onClose={handleClose}
                >
                  <DialogTitle id="alert-dialog-title">Delete all</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the selected {numSelected} rows
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button color="secondary" autoFocus onClick={handleMultipleDeletes}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Tooltip>
        )}
      </Toolbar>
    </Box>
  ) : (
    <></>
  );
};

function CustomerListing(): React.ReactElement {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(0);

  const classes1 = useStyles1();
  const [id, setId] = React.useState(true);
  const [name, setName] = React.useState(true);

  const [description, setDescription] = React.useState(true);
  const [sampleCount, setSampleCount] = React.useState(true);
  const [createdAt, setCreatedAt] = React.useState(true);
  const [rows, setRows] = React.useState<Data[]>([]);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: ''
  });
  const [loader, setLoader] = React.useState(true);
  const [successDelete, setSuccessDelete] = React.useState(false);
  const [filter, setFilter] = React.useState<Filter>({
    open: false,
    values: {
      id: '',
      name: '',
      description: '',
      sampleCount: '',
      createdAt: ''
    }
  });
  const [filter_body, setFilter_Body] = React.useState<Body[]>([]);

  const handleCloseFilter = (args?: Export) => {
    if (args) {
      setFilter({ open: false, values: args });
      const keys = Object.keys(args);
      const tmp_filter_body = [];

      for (const value of keys) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((args as any)[value].length) {
          tmp_filter_body.push({
            key: value,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: (args as any)[value]
          });
        }
      }

      setFilter_Body(tmp_filter_body);
    }
    setFilter({ ...filter, open: false });
  };

  const handleOpenFilter = () => {
    setFilter({ ...filter, open: true });
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, ids: string) => {
    const selectedIndex = selected.indexOf(ids);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ids);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (orderId: string) => selected.indexOf(orderId) !== -1;

  const fields: ShowFields = {
    id,
    name,
    description,
    sampleCount,
    createdAt
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (arg?: ShowFields) => {
    if (arg) {
      setId(arg.id);
      setName(arg.name);
      setSampleCount(arg.sampleCount);
      setDescription(arg.description);
      setCreatedAt(arg.createdAt);
    }
    setOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose2 = () => {
    setToast({ ...toast, open: false });
  };

  React.useEffect(() => {
    setLoader(true);
    fetchData(rowsPerPage, page + 1, filter_body)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);

        if (data.status === 200) {
          setCount(data.data.count);
          setRows(data.data.list);

          if (data.data.list.length === 0) {
            setToast({ open: true, severity: 0, message: 'No Data Found' });
          }
        } else {
          setToast({ open: true, severity: 0, message: data.message });
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ open: true, severity: 0, message: err.message });
      });
  }, [page, rowsPerPage, successDelete, filter_body]);

  const tmpExport: Export[] = [];

  return (
    <Container>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={toast.open} onClose={handleClose2}>
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose2}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Grid justify="flex-end" container>
        <Grid item>
          <Box pb={2}>
            <Button className={classes1.info}>
              <Link className={classes1.white} to="/center/batch/batchDetail/">
                + New Batch
              </Link>
            </Button>
          </Box>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Paper className={classes.paper} elevation={5}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            setDelToast={(message: string, severity: number) => {
              setToast({ open: true, message, severity });

              if (severity) {
                setSuccessDelete(!successDelete);
                setSelected([]);
              }
            }}
          />
          <Grid justify="flex-end" container>
            <Grid className={classes1.widthMaxContent} item>
              <Grid container>
                <Grid className={classes1.widthMaxContent} item>
                  <Tooltip placement="top" title="View Menu">
                    <StyledButton
                      className={classes1.info}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      <AppsIcon />
                    </StyledButton>
                  </Tooltip>
                  <StyledDialog
                    open={open}
                    selectedValue={{
                      id,
                      name,
                      description,
                      sampleCount,
                      createdAt
                    }}
                    onClose={handleClose}
                  />
                  <ExcelFile
                    element={
                      <Tooltip placement="top" title="Export">
                        <StyledButton className={classes1.btnStyle} color="primary" variant="contained">
                          {' '}
                          <FaDownload />
                        </StyledButton>
                      </Tooltip>
                    }
                  >
                    <ExcelSheet data={tmpExport} name="Employees">
                      <ExcelColumn label="Id" value="id" />
                      <ExcelColumn label="Name" value="name" />
                      <ExcelColumn label="Description" value="description" />
                      <ExcelColumn label="Sample Count" value="sampleCount" />
                      <ExcelColumn label="Created At" value="createdAt" />
                    </ExcelSheet>
                  </ExcelFile>
                </Grid>
                <Tooltip placement="top" title="Filter">
                  <StyledButton
                    className={classes1.filterBtn}
                    color="primary"
                    variant="contained"
                    onClick={handleOpenFilter}
                  >
                    <FaFilter />
                  </StyledButton>
                </Tooltip>
                <FilterDialog filterOpen={filter.open} filterValues={filter.values} onFilterClose={handleCloseFilter} />
                <Grid className={classes1.widthMaxContent} item>
                  <StyledTablePagination
                    ActionsComponent={() => {
                      return (
                        <Grid direction="row" container>
                          <Grid item>
                            <StyledPagination
                              boundaryCount={1}
                              className={classes1.widthparent}
                              color="primary"
                              count={Math.ceil(count / rowsPerPage)}
                              page={page + 1}
                              shape="rounded"
                              siblingCount={0}
                              variant="outlined"
                              showFirstButton
                              showLastButton
                              onChange={(e, p) => {
                                handleChangePage(e, p - 1);
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                    className={classes1.widthparent}
                    count={count}
                    labelRowsPerPage=""
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 50, 100]}
                    // onChangePage={handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table
              aria-label="enhanced table"
              aria-labelledby="tableTitle"
              className={classes.table}
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                classes={classes}
                fields={fields}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                rowCount={rows.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              {!loader && (
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    tmpExport.push(row);

                    return (
                      <TableRow
                        key={row.id}
                        aria-checked={isItemSelected}
                        className={classes1.selected}
                        role="checkbox"
                        tabIndex={-1}
                        hover
                      >
                        <StyledCell className={classes1.grey} padding="checkbox">
                          <Box pl={0} pr={10}>
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </Box>
                        </StyledCell>

                        {id && (
                          <StyledCell className={classes1.grey} component="th" id={labelId} padding="none" scope="row">
                            <Link className="a" to={'/center/batch/batchDetail/' + row.id}>
                              <Box className={classes1.border}>{row.id}</Box>
                            </Link>
                          </StyledCell>
                        )}
                        {name && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.name}
                          </StyledCell>
                        )}
                        {description && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.description}
                          </StyledCell>
                        )}
                        {sampleCount && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.sampleCount}
                          </StyledCell>
                        )}
                        {createdAt && (
                          <StyledCell align="left" className={classes1.grey}>
                            {moment(row.createdAt).format('DD-MM-YYYY,h:mm a')}
                          </StyledCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            <div hidden>
              <FormControlLabel
                control={<Switch checked={dense} color="primary" onChange={handleChangeDense} />}
                label="Dense padding"
              />
            </div>
          </TableContainer>
        </Paper>
        {loader && (
          <Grid xs={12}>
            <Grid justify="center" container>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
}

export default CustomerListing;
