import TabPanelContent from "./TabPanelContent";

export const tabs = [
    {
        id: 0,
        label: "Orders",
        component: <TabPanelContent id={0} />,
        moduleName: 'order'

    },
    {
        id: 1,
        label: "Samples",
        component: <TabPanelContent id={1} />,
        moduleName: 'sample'

    },
    {
        id: 2,
        label: "Testing",
        component: <TabPanelContent id={2} />,
        moduleName: 'test',

    },
    {
        id: 3,
        label: "Customers",
        component: <TabPanelContent id={3} />,
        moduleName: 'customer'

    },
    {
        id: 4,
        label: "Assay",
        component: <TabPanelContent id={4} />,
        moduleName: 'testType'

    },
    {
        id: 5,
        label: "Batches",
        component: <TabPanelContent id={5} />,
        moduleName: 'batch'

    },
    {
        id: 6,
        label: "Collection Methods",
        component: <TabPanelContent id={6} />,
        moduleName: 'sampleType'

    },
];
