import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    width: {
        width: '100%',
        color: 'grey',
        fontSize: 'small'
    },
    label: {
        color: 'grey',
        padding: '5px'
    },
    padding: {
        padding: '10px'
    },
    height: {
        height:'max-content'
    },
    heightFull: {
        height:'100%'
    },
    heightScroll: {
        height: '70vh',
        overflowY:'scroll'
    },
    heightForm: {
        height:'70vh'
    },
    makecenter:{
        textAlign:'center'
    }
}));
