import React from 'react';
import JsBarcode from 'jsbarcode';

type labelProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  id?: any;
  name?: string;
  testName?: string;
};

const SampleLabel: React.FC<labelProps> = ({ id, name, testName }) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, id);
  const barcode = canvas.toDataURL();

  return (
    <div
      style={{
        margin: '0',
        padding: '50px',
        background: 'white',
        width: '595px',
        height: '842px'
      }}
    >
      <div style={{ height: '200px', width: '600px' }}>
        <div style={{ float: 'left', width: '300px', fontSize: '8px', fontFamily: 'sans-serif' }}>
          <p>
            <h3>Sample Id: {id}</h3>
            <h4>
              <b>NAME: {name}</b>
            </h4>
            <h3>TestName:{testName}</h3>
            <img alt="barcode" src={barcode} style={{ height: '42px', width: '105px' }} />
          </p>
        </div>
        <div style={{ float: 'left', width: '300px', fontSize: '8px', fontFamily: 'sans-serif' }}>
          <p>
            <h3>Test Result </h3>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p>POSITIVE</p>
              <input type="checkbox" />
              <p>NEGATIVE</p>
              <input type="checkbox" />
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SampleLabel;
