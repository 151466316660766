import React, { forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type AppMenuItemComponentProps = {
  className?: string;
  link?: string | null; // because the InferProps props allows alows null value
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const AppMenuItemComponent: React.FC<AppMenuItemComponentProps> = (props) => {
  const { className, onClick, link, children } = props;

  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    return <ListItem children={children} className={className} button onClick={onClick} />;
  }

  // Return a LitItem with a link component
  return (
    <ListItem
      children={children}
      className={className}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component={forwardRef((prop: NavLinkProps, ref: any) => (
        <NavLink exact {...prop} innerRef={ref} />
      ))}
      to={link}
      button
    />
  );
};

export default AppMenuItemComponent;
