import React from 'react';

import TestTypeListing from './TestTypeListing';
const LocationsPage: React.FC = () => {
  return (
    <div>
      <TestTypeListing />
    </div>
  );
};

export default LocationsPage;
