import { userDropdown } from "../Orders/TestDetails/api";

type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dropdown: any = {
  "results": [
    { label: 'Detected', value: 'DETECTED' },
    { label: 'Not Detected', value: 'NOT-DETECTED' }
  ],
  "testStatus": [
    { label: 'Not Started', value: 'NOT STARTED' },
    { label: 'In Progress', value: 'INPROGRESS' },
    { label: 'Created', value: 'CREATED' },
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Cancelled', value: 'CANCELLED' }
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dropdownApi: any = {
  "technician": userDropdown,
  "reviewedBy": userDropdown
}

export const menuItems = [
  {
    label: "Samples",
    children: [
      {
        label: "Sample Report",
        value: "sampleReport",
        dropdown: false,
        date: false,
      }
    ]
  },
  {
    label: "Test",
    children: [
      {
        label: "Status",
        value: "testStatus",
        dropdown: true,
        date: false,
      },
      {
        label: "Results",
        value: 'results',
        dropdown: true,
        date: false
      },
      {
        label: "Technician",
        value: 'technician',
        dropdown: true,
        date: false
      },
      {
        label: "Comments",
        value: 'comments',
        dropdown: false,
        date: false
      },
      {
        label: "Location",
        value: 'testLocation',
        dropdown: false,
        date: false
      },
      {
        label: "Kit Lot",
        value: 'kitLot',
        dropdown: false,
        date: false
      },
      {
        label: "Start Date",
        value: 'startDate',
        dropdown: false,
        date: true
      },
      {
        label: "Completed Date",
        value: 'completedDate',
        dropdown: false,
        date: true
      },
      {
        label: "Tags",
        value: 'tags',
        dropdown: false,
        date: false
      },
      {
        label: "Reviewed By",
        value: 'reviewedBy',
        dropdown: true,
        date: false
      },
      {
        label: "Reviewed Date",
        value: 'reviewedDate',
        dropdown: false,
        date: true
      }
    ]
  }
];

