import { config } from '../../config';

import { Body } from './Models';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchData = async (rowsPerPage: number, page: number, filters: Body[]) => {
  return  await fetch(config.baseUrl + config.apiURL.orderList, {
    method: 'POST',
    body: JSON.stringify({
      pageSize: rowsPerPage,
      pageNo: page,
      field: 'id',
      sort: 'DESC',
      filters
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });

};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const multipleDeletes = async (ids: string[]) => {
  return await fetch(config.baseUrl + config.apiURL.orderDeleteById, {
    method: 'POST',
    body: JSON.stringify({
      id: [...ids],
      deleteStatus: 1
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });

};
