import { Dialog, DialogTitle, Grid, Button, Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

import { DialogProps, ShowFields } from './Models';
import { useStyles1 } from './styles';

export default function StyledDialog(props: DialogProps): React.ReactElement {
  const classes = useStyles1();
  const { open, onClose } = props;

  const [values, setValues] = React.useState({
      id:props.selectedValue.id,
      customerAccountName:props.selectedValue.customerAccountName,
      dateReceived:props.selectedValue.dateReceived,
      confirmationId:props.selectedValue.confirmationId,
      email:props.selectedValue.email,
      appointmentDate:props.selectedValue.appointmentDate,
      travelType:props.selectedValue.travelType,
      destination:props.selectedValue.destination,
      isCancelled:props.selectedValue.isCancelled,
      cancelledDate:props.selectedValue.cancelledDate,
      rescheduledDate:props.selectedValue.rescheduledDate,
      samplesCount:props.selectedValue.samplesCount,
      testCount:props.selectedValue.testCount,
      status:props.selectedValue.status,
  })


  const handleClose = ( arg?: ShowFields) => {
    onClose(arg);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="xs"
      open={open}
      fullWidth
      onClose={() => {
        handleClose(values);
      }}
    >
      <Grid className={classes.padding} justify="center" container>
        <DialogTitle className={classes.widthparent} id="simple-dialog-title">
          View
        </DialogTitle>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid
              className={values.customerAccountName ? classes.cells : classes.hiddenCells}
              justify="space-between"
              container
            >
              <Grid className={classes.heading} item>
                <b>Customer Account</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.customerAccountName}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,customerAccountName:!values.customerAccountName})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.id ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Order Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.id}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,id:!values.id})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.dateReceived ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Date Received</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.dateReceived}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,dateReceived:!values.dateReceived})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.confirmationId ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b> Confirmation Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.confirmationId}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,confirmationId:!values.confirmationId})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.email ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Email</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.email}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,email:!values.email})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.appointmentDate ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Appointment Date</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.appointmentDate}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,appointmentDate:!values.appointmentDate})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.travelType ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Travel Type</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.travelType}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,travelType:!values.travelType})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.destination ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Destination</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.destination}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,destination:!values.destination})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.isCancelled ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Is Cancelled</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.isCancelled}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,isCancelled:!values.isCancelled})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.cancelledDate ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Cancelled Date</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.cancelledDate}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,cancelledDate:!values.cancelledDate})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.rescheduledDate ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Rescheduled Date</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.rescheduledDate}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,rescheduledDate:!values.rescheduledDate})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.samplesCount ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Samples Count</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.samplesCount}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,samplesCount:!values.samplesCount})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.testCount ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Test Count</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.testCount}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,testCount:!values.testCount})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.status ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Status</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.status}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({...values,status:!values.status})
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => {
                handleClose( values);
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
