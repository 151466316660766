import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Grid, TextField, Container, FormControl, MenuItem, Select, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useStyles, SaveButton, DeleteButton } from './Styles';
import { Params, User, Toast, Users, UserTech } from './Models';
import { fetchData, submit, del, userDropdown, technicianDropdown } from './api';

import './TestDetails.css';

export default function TestTypeDetails(): React.ReactElement {
  const params = useParams<Params>();
  const history = useHistory();
  const classes = useStyles();
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });
  const [user, setUser] = React.useState<User>({
    title: '',
    method: '',
    category: '',
    defaultTechnician: '',
    team: '',
    tags: '',
    sortOrder: '',
    isActive: '',
    showInCustomerPortal: ''
  });
  const [userList, setUserList] = React.useState<Users[]>([
    {
      id: '',
      title: '',
      method: ''
    }
  ]);

  const [techList, setTechList] = React.useState<UserTech[]>([
    {
      id: '',
      userName: ''
    }
  ]);
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [, setTitles] = React.useState('');
  const [methods, setMethods] = React.useState('');
  const [ids, setIds] = React.useState('');

  const [titlesm, setTitlesm] = React.useState('');
  const [, setMethodsm] = React.useState('');
  const [idsm, setIdsm] = React.useState('');

  const [userNames, setUserNames] = React.useState('');
  const [idt, setIdt] = React.useState('');

  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false
  });

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });

    if (toast.redirect) {
      history.push('/center/assay');
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    setLoader(true);
    const body = JSON.stringify({
      ...user,
      id: params.id,
      isActive: state.checkedB ? '1' : '0',
      showInCustomerPortal: state.checkedA ? '1' : '0'
    });
    submit(params, body)
      .then(async (response: Response) => {
        const message = await response.json();
        let redirect = false;
        let loaders = false;

        if (message.status === 200) {
          redirect = true;
          loaders = true;
        }
        setLoader(loaders);
        setToast({
          ...toast,
          open: true,
          severity: message.status === 200 ? 1 : 0,
          message: message.message,
          redirect
        });
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleDelete = () => {
    setOpen(false);
    setLoader(true);
    del(+params.id)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);
        setToast({ ...toast, open: true, severity: 1, message: data.message, redirect: true });
      })
      .catch((err) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  React.useEffect(() => {
    if (params.id) {
      setLoader(true);
      fetchData(+params.id)
        .then(async (response: Response) => {
          const data = await response.json();

          if (data.status === 200) {
            const tit = data.data.title;
            const met = data.data.method;
            const dt = data.data.defaultTechnician;

            userDropdown()
              .then(async (resp: Response) => {
                const datas = await resp.json();

                if (datas.status === 200) {
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  const names = datas.data.find((e: any) => e.title === tit) as Users;
                  setTitles(names.title);
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  const names1 = data.data.find((e: any) => e.method === met) as Users;
                  setMethodsm(names1.method);
                } else {
                  setToast({ ...toast, open: true, severity: 0, message: datas.message, redirect: false });
                }
              })
              .catch((err: Error) => {
                setLoader(false);
                setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
              });

            technicianDropdown()
              .then(async (responseTech: Response) => {
                const dataT = await responseTech.json();

                if (dataT.status === 200) {
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  const names = dataT.data.find((e: any) => e.userName === dt) as UserTech;
                  setUserNames(names.userName);
                } else {
                  setToast({ ...toast, open: true, severity: 0, message: dataT.message, redirect: false });
                }
              })
              .catch((err: Error) => {
                setLoader(false);
                setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
              });

            setUser(data.data);
          } else {
            setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
          }
        })
        .catch((err: Error) => {
          setLoader(false);
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    }
    userDropdown()
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);

        if (data.status === 200) {
          setUserList(data.data);
        } else {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });

    technicianDropdown()
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);

        if (data.status === 200) {
          setTechList(data.data);
        } else {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const back = () => {
    history.push('/center/assay');
  };

  return (
    <Grid className="root" container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress />
      </Backdrop>
      <Container className={classes.white}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <ChevronLeftIcon
            style={{
              color: '#4BB543'
            }}
          />
          <span
            style={{
              color: '#4BB543',

              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={back}
          >
            Back to Assay List
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid className={classes.padding} xs={12} item>
            <h2 className={classes.label}>Assay </h2>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Title</b>
            <b className="red-star">*</b>
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.title}
                id="controllable-states-demo"
                options={userList}
                renderInput={(paramsL) => (
                  <TextField {...paramsL} size="small" value={user.title} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ id: ids, title: user.title, method: methods }}
                onChange={(event, newValue: Users | null) => {
                  if (newValue) {
                    const tmp = { ...user };
                    tmp.title = newValue.title;
                    setTitles(newValue.title);
                    setMethods(newValue.method);
                    setIds(newValue.id);
                    setUser(tmp);
                  }
                }}
              />

              {/* <Select
                value={user.title}
                variant="outlined"
                required
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.title = event.target.value as string;
                  setUser(tmp);
                }}
              >
                <MenuItem value="">Select Title</MenuItem>
                {userList.map((e) => (
                  <MenuItem key={e.id} value={e.title}>
                    {e.title}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Method</b>
            <b className="red-star">*</b>
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.method}
                id="controllable-states-demo"
                options={userList}
                renderInput={(paramsUL) => (
                  <TextField {...paramsUL} size="small" value={user.method} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ id: idsm, title: titlesm, method: user.method }}
                onChange={(event, newValue: Users | null) => {
                  if (newValue) {
                    const tmp = { ...user };
                    tmp.method = newValue.method;
                    setTitlesm(newValue.title);
                    setMethodsm(newValue.method);
                    setIdsm(newValue.id);
                    setUser(tmp);
                  }
                }}
              />

              {/* <Select
                value={user.method}
                variant="outlined"
                required
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.method = event.target.value as string;
                  setUser(tmp);
                }}
              >
                <MenuItem value="">Select Method</MenuItem>
                {userList.map((e) => (
                  <MenuItem key={e.id} value={e.method}>
                    {e.method}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Category</b>
            <b className="red-star">*</b>
            <br />
            <FormControl className={classes.width} size="small">
              <Select
                value={user.category}
                variant="outlined"
                // required
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.category = event.target.value as string;
                  setUser(tmp);
                }}
              >
                <MenuItem value="">--Category--</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Default Technician</b>
            <b className="red-star">*</b>
            <br />
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.userName}
                id="controllable-states-demo"
                options={techList}
                renderInput={(paramsTl) => (
                  <TextField {...paramsTl} size="small" value={user.defaultTechnician} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ id: idt, userName: userNames }}
                onChange={(event, newValue: UserTech | null) => {
                  if (newValue) {
                    const tmp = { ...user };
                    tmp.defaultTechnician = newValue.userName;
                    setUserNames(newValue.userName);
                    setIdt(newValue.id);
                    setUser(tmp);
                  }
                }}
              />

              {/* <Select
                value={user.defaultTechnician}
                variant="outlined"
                required
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.defaultTechnician = event.target.value as string;
                  setUser(tmp);
                }}
              >
                {techList.map((tech) => {
                  return (
                    <MenuItem key={tech.id} value={tech.userName}>
                      {tech.userName}
                    </MenuItem>
                  );
                })} 
              </Select>*/}
            </FormControl>
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Team</b>
            <b className="red-star">*</b>
            <br />
            <FormControl className={classes.width} size="small">
              <Select
                value={user.team}
                variant="outlined"
                // required
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.team = event.target.value as string;
                  setUser(tmp);
                }}
              >
                <MenuItem value="">--Team--</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Tags</b>

            <TextField
              className={classes.width}
              placeholder="Tags"
              size="small"
              type="text"
              value={user.tags}
              variant="outlined"
              required
              onChange={(event) => {
                const tmp = { ...user };
                tmp.tags = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Sort Order</b>
            <TextField
              className={classes.width}
              placeholder="Sort Order"
              size="small"
              type="text"
              value={user.sortOrder}
              variant="outlined"
              onChange={(event) => {
                const tmp = { ...user };
                tmp.sortOrder = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <FormControlLabel
              control={<Checkbox checked={state.checkedB} color="primary" name="checkedB" onChange={handleChange} />}
              label="Active"
            />
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <FormControlLabel
              control={<Checkbox checked={state.checkedA} color="primary" name="checkedA" onChange={handleChange} />}
              label="Show in Customer Portal"
            />
          </Grid>

          <Grid className={classes.padding} container>
            <Box mr={2}>
              <SaveButton type="submit" variant="contained">
                Save
              </SaveButton>
            </Box>
            {params.id && (
              <>
                <Box mr={2}>
                  <DeleteButton variant="contained" onClick={handleClickOpen}>
                    Delete
                  </DeleteButton>
                </Box>
                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={open}
                  onClose={handleCloseAlert}
                >
                  <DialogTitle id="alert-dialog-title"> Delete alert</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the record
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={handleCloseAlert}>
                      Cancel
                    </Button>
                    <Button color="secondary" autoFocus onClick={handleDelete}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
        </form>
      </Container>
    </Grid>
  );
}
