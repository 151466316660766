import React from 'react';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import image from '../../assets/images/image.jpeg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import './styles.scss';
import { config } from '../../config';

import { forgetPassword } from './api';
import { Toast } from './Model';

const ForgetPassword: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const [load, setLoad] = React.useState(false);
  const [, setValidate] = React.useState(false);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });

  const [, setMail] = React.useState('');

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const login = (accessTokens: string) => {
    if (email) {
      forgetPassword(email, accessTokens)
        .then(async (response: Response) => {
          const data = await response.json();
          setLoad(false);

          if (data.status === 200) {
            setMail(data.data.email);
            setToast({
              ...toast,
              open: true,
              severity: 1,
              message: 'Mail sent successfully to the Registered Mail-Id',
              redirect: true
            });
          } else {
            setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
          }
        })
        .catch((err: Error) => {
          setLoad(false);
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    } else {
      setValidate(false);
      setLoad(false);
      setToast({
        ...toast,
        open: true,
        severity: 0,
        message: 'Enter a valid email',
        redirect: false
      });
    }
  };

  const accessToken = (token: string) => {
    axios({
      method: 'post',
      url: config.baseUrl + config.apiURL.userGetAccessToken,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      data: {
        assertion: token
      }
    })
      .then((response) => {
        if (response.data.status === 200) {
          login(response.data.data.token);
        }
      })
      .catch((err) => alert(err.message));
  };

  const loginHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const token: string = jwt.sign({ sub: config.clent.id }, config.clent.secretKey, {
      expiresIn: config.clent.expiresIn
    });

    accessToken(token);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoad(true);

    if (email) {
      loginHandler(event);
    }
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <div className="loginPage">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={1500}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>
      <div className="login-wrapper">
        <Backdrop
          className="a"
          open={load}
          style={{
            zIndex: 3000,
            color: '#fff'
          }}
        >
          <CircularProgress />
        </Backdrop>

        <Header />
        <div className="row login-container">
          <div className="left-container">
            <div>
              <h2 className="login-header">Forgot Password</h2>
              <h3>Enter the registered email to recover your password </h3>
            </div>

            <form className="pad-top-20" onSubmit={handleSubmit}>
              <TextField
                color="primary"
                placeholder="janedoe@test.com"
                size="small"
                style={{ width: '63.5%' }}
                type="email"
                variant="outlined"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <br />
              <br />
              <button className="button-success width-65" type="submit">
                {load ? 'Submitting...' : 'Submit'}
              </button>
              <br />
              <br />
            </form>
            <div>
              <p>
                Remembered Password?{' '}
                <Link className="forgot-password-link" to="/login">
                  &nbsp; Login
                </Link>
              </p>
            </div>
          </div>
          <div className="right-container">
            <img alt="promotional" draggable={false} src={image} />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ForgetPassword;
