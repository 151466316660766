import { Dialog, DialogTitle, Grid, Button, Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

import { DialogProps, ShowFields } from './Models';
import { useStyles1 } from './styles';

export default function StyledDialog(props: DialogProps): React.ReactElement {
  const classes = useStyles1();
  const { open, onClose } = props;
  const [ values, setValues ] = React.useState<ShowFields>({
    orderId: props.selectedValue.orderId,
    orderStatus: props.selectedValue.orderStatus,
    sampleId:props.selectedValue.sampleId,
    sampleReport:props.selectedValue.sampleReport,
    id:props.selectedValue.id,
    testStatus:props.selectedValue.testStatus,
    assayId:props.selectedValue.assayId,
    technician:props.selectedValue.technician,
    startDate:props.selectedValue.startDate,
    completeDate:props.selectedValue.completeDate,
    results:props.selectedValue.results,
    testReport:props.selectedValue.testReport
  })

  const handleClose = ( arg?: ShowFields) => {
    onClose(arg);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="xs"
      open={open}
      fullWidth
      onClose={() => {
        handleClose( values);
      }}
    >
      <Grid className={classes.padding} justify="center" container>
        <DialogTitle className={classes.widthparent} id="simple-dialog-title">
          View
        </DialogTitle>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.orderId ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Order Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.orderId}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, orderId: !values.orderId });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.orderStatus ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Order Status</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.orderStatus}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, orderStatus: !values.orderStatus });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.sampleId ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Sample Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.sampleId}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, sampleId: !values.sampleId });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.sampleReport ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Sample Report</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.sampleReport}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, sampleReport: !values.sampleReport });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.id ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Test Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.id}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, id: !values.id });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.testStatus ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Test Status</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.testStatus}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, testStatus: !values.testStatus });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.assayId ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Assay Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.assayId}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, assayId: !values.assayId });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.technician ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Technician</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.technician}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, technician: !values.technician });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.startDate ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Start Date</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.startDate}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, startDate: !values.startDate });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.completeDate ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Complete Date</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.completeDate}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, completeDate: !values.completeDate });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.results ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Results</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.results}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, results: !values.results });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={values.testReport ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Test Report</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={values.testReport}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setValues({ ...values, testReport: !values.testReport });
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => {
                handleClose( values);
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
