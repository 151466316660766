import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/images/wl-logo.png';

type labelProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  sampleId?: any;
  createdAt?: string;
  result?: string;
  orderId?: number;
  name?: string;
  dob?: string;
  address?: string;
  sex?: string;
  ethnicity?: string;
  race?: string;
  sampleType?: string;
  reviewedDate?: string;
};

const Boxu = styled.div`
  border: 2px solid black;
  padding: 2px;
`;

const Table = styled.table`
  border: 0.5px solid black;
  border-collapse: collapse;

  width: 475px;

  padding: 20px;
`;
const Tables = styled.table`
  margin-top: 10px;
  border: 0.5px solid black;
  border-collapse: collapse;
  width: 50%;
  padding: 3px;
  font-size: 10px;
`;
const Th = styled.th`
  border: 0.5px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 6px;
  font-size: 10px;
  font-weight: bold;
`;

const Tblhead = styled.div`
  text-align: center;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  font-weight: bold;
  padding: 3px;
`;

const Td = styled.td`
  border: 0.5px solid black;
  border-bottom: 0.5px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 7px;
  font-size: 10px;
`;
const Tr = styled.tr``;

const Boldtext = styled.h4`
  margin-top: 4px;
  font-size: 13px;
`;

const Paragraph = styled.p`
  font-size: 7px;
  margin-top: -10px;
`;
const Hs3 = styled.h3`
  margin-top: -20px;
  font-size: 8px;
`;

const H3 = styled.h3`
  margin-top: -10px;
  font-size: 8px;
`;

const Line = styled.span`
  width: 140px;
  margin-left: -10px;
`;

const Div = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
`;

const B = styled.b`
  width: 78px;
`;
const Bs = styled.b`
  width: 20px;
`;

const GenericReport: React.FC<labelProps> = ({
  sampleId,
  createdAt,
  result,
  orderId,
  reviewedDate,
  name,
  dob,
  address,
  sex,
  ethnicity,
  race,
  sampleType
}) => {
  return (
    <div
      style={{
        margin: '0',
        padding: '20px 50px',
        background: 'white',
        width: '595px',
        height: '842px'
      }}
    >
      <div style={{ padding: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <img alt="WSL-Logo" src={logo} style={{ width: '170px', height: '40px' }} />
          <div style={{ marginTop: '-30px' }}>
            <p
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                fontWeight: 400,
                fontSize: '21px'
              }}
            >
              Laboratory Report
            </p>
            <div style={{ marginTop: '-3px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              <span style={{ fontSize: '8px', marginBottom: '5px' }}>
                132-02 89th Ave. Ste 103 Richmond Hill, NY 11418
              </span>
              <span style={{ fontSize: '8px', marginBottom: '5px' }}> Phone #: +1 (833) 747-1122</span>
              <span style={{ fontSize: '8px', marginBottom: '5px' }}>CLIA License #: 10D2126590Medical</span>
              <span style={{ fontSize: '8px', marginBottom: '5px' }}>Director: SUSANA T. FERRA, M.D., FCAP, FASCP</span>
            </div>
          </div>
        </div>
        <hr style={{ border: '1px solid black', background: 'black' }} />
        <div
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            padding: '0 5px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            fontSize: '10px'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}
          >
            <div>
              <b>Report Date:</b> {reviewedDate}
            </div>
            <div>
              <b> QBench Order ID:</b> 256946
            </div>
            <div>
              <b>Order ID:</b> {orderId}
            </div>
            <div>
              <b>NPI #:</b> 1467030536
            </div>
            <div>
              <b>CPT Code(s):</b> U0003, U0005
            </div>
            <div>
              <b>ICD-10:</b> Z11.52
            </div>
            <div>
              <b>Specimen Count:</b> 1
            </div>
          </div>

          <Boxu>
            <Div>
              <B>Patient Name</B>
              <Bs>:</Bs>
              <Line>{name}</Line>
            </Div>
            <Div>
              <B>Date of Birth</B>
              <Bs>:</Bs>
              <Line> {dob}</Line>
            </Div>
            <Div>
              <B>Address</B>
              <Bs>:</Bs>
              <Line>{address}</Line>
            </Div>
            <Div>
              <B>Patient Sex</B>
              <Bs>:</Bs>
              <Line> {sex}</Line>
            </Div>
            <Div>
              <B>Patient Race</B>
              <Bs>:</Bs>
              <Line> {race}</Line>
            </Div>
            <Div>
              <B>Patient Ethnicity</B>
              <Bs>:</Bs>
              <Line> {ethnicity}</Line>
            </Div>
          </Boxu>
        </div>
        <hr style={{ border: '1px solid black', background: 'black' }} />

        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Tblhead>Speciman Results</Tblhead>
          <Table>
            <Tr>
              <Th>Sample ID</Th>
              <Th>Collection Date/Time</Th>
              <Th>Tested Date</Th>
              <Th>Sample Type</Th>
              <Th>Method</Th>
              <Th>Technician</Th>
              <Th>Results</Th>
            </Tr>
            <Tr>
              <Td>{sampleId}</Td>
              <Td>{createdAt}</Td>
              <Td>Tested Date</Td>
              <Td>{sampleType}</Td>
              <Td>Method</Td>
              <Td>Technician</Td>
              <Td>{result}</Td>
            </Tr>
          </Table>
        </div>
        <hr style={{ border: '1px solid black', background: 'black' }} />

        <Tables>
          <Tr>
            <Th>Reviewed By: Shelton Becknel, CLS</Th>
          </Tr>
          <Tr>
            <Td>Shelton Becknel</Td>
          </Tr>
        </Tables>
        <Boldtext>Test Methodology</Boldtext>
        <br />
        <div>
          <Hs3>Clinical Interpretation</Hs3>
          <H3>
            <i>Detected </i>
          </H3>
          <Paragraph>
            A Detected result indicates the presence of RNA from SARS-CoV-2 in the patient sample. Patient is
            recommended to quarantine themselves and contacttheir health care provider for specific instructions. There
            is a chance that positive results might be associated with a coinfection with other viruses or aslight false
            positive rate of the PCR test{' '}
          </Paragraph>
          <H3>
            <i>Not Detected</i>
          </H3>
          <Paragraph>
            A Not Detected result indicates that RNA from SARS-CoV-2 in the patient sample was either absent or present
            below the limit of detection of the assay. Itdoes not rule the possibility of COVID-19 infection and
            clinical manifestations must be considered by the health care pro
          </Paragraph>
          <H3>
            <i>Inconclusive Test Result</i>
          </H3>
          <Paragraph>
            An Inconclusive Test Result means that not all COVID-19 targets were not detected and a sample recollection
            is required.{' '}
          </Paragraph>
          <H3>
            <i>Invalid Test Resu</i>
          </H3>
          <Paragraph>
            An Invalid Test Result is a consequence of improper performance of test and accurate interpretation of the
            results cannot be made. Sample recollection isrecommended.
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default GenericReport;
