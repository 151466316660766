import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';

import { PropsTests } from './Models';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  border: {
    border: '2px solid rgb(1,1,1,0.3)',
    borderRadius: '5px',
    padding: '10px',
    width: 'max-content'
  }
});

export default function TestsLists(props: PropsTests): React.ReactElement {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell padding="default">TestId</TableCell>
            <TableCell padding="default">SampleId</TableCell>
            <TableCell padding="default">Technician</TableCell>
            <TableCell padding="default">TestStatus</TableCell>
            <TableCell padding="default">Title</TableCell>
            <TableCell padding="default">Results</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left" padding="default">
                <Link className={classes.border} to={'/center/samples/testDetail/' + row.id}>
                  {row.id}
                </Link>
              </TableCell>

              <TableCell align="left" padding="default">
                <Link className={classes.border} to={'/center/samples/sampleDetail/' + row.sampleId}>
                  {row.sampleId}
                </Link>
              </TableCell>
              <TableCell align="left" padding="default">
                {row.technician}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.testStatus}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.title}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.results}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
