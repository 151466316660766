import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Container,
  MenuItem,
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  Divider,
  Select
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import AppsIcon from '@material-ui/icons/Apps';
import { FaDownload, FaFilter } from 'react-icons/fa';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import { config } from '../../config';

import ReactExport from './Export.d';
import { getComparator, stableSort, menuItems, dropdown } from './utilities';
import {
  useStyles1,
  useToolbarStyles,
  useStyles,
  StyledCell,
  StyledTablePagination,
  StyledPagination,
  StyledButton
} from './styles';
import { multipleDeletes, updateMany } from './api';
import {
  Data,
  EnhancedTableProps,
  HeadCell,
  EnhancedTableToolbarProps,
  ShowFields,
  Export,
  Toast,
  Filter,
  Body,
  UpdateMenuProps,
  UpdateInputProps,
  UpdateValues,
  SetUpdateOptions
} from './Models';
import StyledDialog from './StyledDialog';
import FilterDialog from './FilterDialog';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Sample Id' },
  { id: 'orderId', numeric: false, disablePadding: false, label: 'Order Id' },
  { id: 'labId', numeric: false, disablePadding: false, label: 'Lab Id' },
  { id: 'accessionId', numeric: false, disablePadding: false, label: 'Accession Id' },
  {
    id: 'externalId',
    numeric: false,
    disablePadding: false,
    label: 'External Accession Id'
  },
  { id: 'source', numeric: false, disablePadding: false, label: 'Source' },
  { id: 'tests', numeric: false, disablePadding: false, label: 'Tests' },
  { id: 'assigningAuthority', numeric: false, disablePadding: false, label: 'Assigning Authority' },
  { id: 'firstName', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'lastName', numeric: false, disablePadding: false, label: 'Last Name' },
  { id: 'sex', numeric: false, disablePadding: false, label: 'Sex' },
  { id: 'birthDate', numeric: false, disablePadding: false, label: 'Birth date' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'city', numeric: false, disablePadding: false, label: 'City' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  { id: 'zipCode', numeric: false, disablePadding: false, label: 'ZipCode' },
  { id: 'specimanType', numeric: false, disablePadding: false, label: 'Speciman Type' }
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes1 = useStyles1();
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, fields } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledCell padding="checkbox">
          <Box pl={0} pr={10}>
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              indeterminate={numSelected > 0 && rowCount > numSelected}
              inputProps={{ 'aria-label': 'select all desserts' }}
              onChange={onSelectAllClick}
            />
          </Box>
        </StyledCell>
        {headCells.map(
          (headCell) =>
            fields[headCell.id] && (
              <StyledCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'center'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <th className={classes1.heading}>{headCell.label}</th>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

const UpdateInput = (props: UpdateInputProps) => {
  const { update, setUpdate } = props;
  const classes = useToolbarStyles();

  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: ''
  });

  const index: string = update.dropdown.values;

  function Alert(prop: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...prop} />;
  }

  const handleClose2 = () => {
    setToast({ ...toast, open: false });
  };

  return (
    <Grid className={classes.padding} xs={12} item>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={toast.open} onClose={handleClose2}>
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose2}>
          {toast.message}
        </Alert>
      </Snackbar>
      <b className={clsx(classes.label, classes.capitalize)}>{update.field}</b>
      {!update.dropdown.open ? (
        <TextField
          className={classes.width}
          color="primary"
          placeholder={update.field}
          size="small"
          type={update.date ? 'datetime-local' : 'text'}
          value={update.date ? moment(update.fieldValue).format('YYYY-MM-DDThh:mm') : update.fieldValue}
          variant="outlined"
          fullWidth
          required
          onChange={(event) => {
            setUpdate(update.field, event.target.value, {
              date: update.date,
              dropdown: update.dropdown
            });
          }}
        />
      ) : (
        <FormControl className={classes.width} size="small">
          <Select
            value={update.fieldValue}
            variant="outlined"
            required
            onChange={(event) => {
              setUpdate(update.field, event.target.value as string, {
                date: update.date,
                dropdown: update.dropdown
              });
            }}
          >
            {dropdown[index].map((item: { value: string; label: string }) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

const UpdateMenu = (props: UpdateMenuProps) => {
  const { handleDialog, setUpdate } = props;

  return (
    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      {menuItems.map((item) => {
        return (
          <>
            <TreeItem
              key={item.label}
              label={item.label}
              nodeId={item.label}
              onClick={() => {
                handleDialog(true, true, false);
                setUpdate(item.value, '', {
                  date: item.date,
                  dropdown: {
                    open: item.dropdown,
                    values: item.value
                  }
                });
              }}
            />

            <br />
          </>
        );
      })}
    </TreeView>
  );
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, setDelToast } = props;
  const [loader, setLoader] = React.useState<{
    update: boolean;
    delete: boolean;
  }>({
    update: false,
    delete: false
  });
  const [dialogOpen, setDialogOpen] = React.useState<{ show: boolean; update: boolean; updateMenu: boolean }>({
    show: false,
    update: false,
    updateMenu: false
  });
  const [update, setUpdate] = React.useState<UpdateValues>({
    field: '',
    fieldValue: '',
    date: false,
    dropdown: {
      open: false,
      values: ''
    }
  });
  const handleClickDialogOpen = (show: boolean, modify: boolean, updateMenu: boolean) => {
    setDialogOpen({ ...dialogOpen, show, update: modify, updateMenu });
  };

  const handleDialogClose = () => {
    setDialogOpen({ ...dialogOpen, show: false, update: false, updateMenu: false });
  };

  const handleSetUpdate = (field: string, fieldValue: string, options?: SetUpdateOptions) => {
    let date = false;
    const drop: { open: boolean; values: string } = {
      open: false,
      values: ''
    };

    if (options && options.date) {
      date = options.date;
    }

    if (options && options.dropdown) {
      drop.open = options.dropdown.open;
      drop.values = options.dropdown.values;
    }

    setUpdate({ ...update, field, fieldValue, date, dropdown: drop });
  };

  const handleLoader = (modify: boolean, del: boolean) => {
    setLoader({
      update: modify,
      delete: del
    });
  };

  const successfulOperation = async (response: Response) => {
    const data = await response.json();
    handleLoader(false, false);

    if (data.status === 200) {
      setDelToast(data.message, 1);
    } else {
      setDelToast(data.message, 0);
    }
  };

  const errorOperation = (err: Error) => {
    handleLoader(false, false);
    setDelToast(err.message, 0);
  };

  const handleMultipleDeletes = () => {
    handleLoader(false, true);
    handleDialogClose();
    multipleDeletes(selected)
      .then(async (response: Response) => {
        await successfulOperation(response);
      })
      .catch((err: Error) => {
        errorOperation(err);
      });
  };

  const handleUpdateMany = () => {
    if (update.fieldValue.length) {
      handleLoader(true, false);
      handleDialogClose();
      updateMany(selected, {
        [update.field]: [update.fieldValue]
      })
        .then(async (response: Response) => {
          await successfulOperation(response);
        })
        .catch((err: Error) => {
          errorOperation(err);
        });
    }
  };

  return numSelected > 0 ? (
    <Box pb={5}>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 && (
          <Typography className={classes.title} color="inherit" component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}

        {numSelected > 0 && (
          <>
            <Tooltip title="Actions">
              {loader.update ? (
                <CircularProgress />
              ) : (
                <IconButton
                  aria-label="Edit"
                  size="medium"
                  onClick={() => {
                    handleClickDialogOpen(true, true, true);
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </Tooltip>
            <Tooltip title="Delete">
              {loader.delete ? (
                <CircularProgress />
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleClickDialogOpen(true, false, false);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Tooltip>
          </>
        )}
      </Toolbar>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        maxWidth="xs"
        open={dialogOpen.show}
        fullWidth
        onClose={handleDialogClose}
      >
        <DialogTitle className={classes.dialogHead} id="alert-dialog-title" disableTypography>
          <Grid justify="space-between" container>
            <Grid item>{dialogOpen.update ? `Update selected` : `Delete selected`}</Grid>
            <Grid item>
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogOpen.update ? (
              <Box p={3} pl={0}>
                {dialogOpen.updateMenu ? (
                  <UpdateMenu handleDialog={handleClickDialogOpen} setUpdate={handleSetUpdate} />
                ) : (
                  <UpdateInput setUpdate={handleSetUpdate} update={update} />
                )}
              </Box>
            ) : (
              `Are you sure you want to delete the selected ${numSelected} rows`
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleDialogClose}>
            Cancel
          </Button>
          {!dialogOpen.update && (
            <Button className={classes.capitalize} color="secondary" autoFocus onClick={handleMultipleDeletes}>
              Delete
            </Button>
          )}
          {dialogOpen.update && !dialogOpen.updateMenu && (
            <Button
              className={classes.capitalize}
              color="secondary"
              disabled={update.fieldValue.length ? false : true}
              autoFocus
              onClick={handleUpdateMany}
            >
              Update {update.field}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  ) : (
    <></>
  );
};

function SampleListing(): React.ReactElement {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);

  const classes1 = useStyles1();
  const [id, setid] = React.useState(true);
  const [orderId, setOrderId] = React.useState(true);
  const [externalId, setExternalId] = React.useState(true);
  const [tests, setTests] = React.useState(true);
  const [labId, setLabId] = React.useState(true);
  const [source, setSource] = React.useState(true);
  const [accessionId, setAccessionId] = React.useState(true);
  const [address, setAddress] = React.useState(true);
  const [assigningAuthority, setAssigningAuthority] = React.useState(true);
  const [birthDate, setBirthDate] = React.useState(true);
  const [city, setCity] = React.useState(true);
  const [state, setState] = React.useState(true);
  const [firstName, setFirstName] = React.useState(true);
  const [lastName, setLastName] = React.useState(true);
  const [sex, setSex] = React.useState(true);
  const [zipCode, setZipCode] = React.useState(true);
  const [confirmationId, setConfirmationId] = React.useState(true);
  const [detailPage] = React.useState(true);
  const [specimanType, setSpecimanType] = React.useState(true);
  const [rows, setRows] = React.useState<Data[]>([]);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: ''
  });
  const [count, setCount] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const [successDelete, setSuccessDelete] = React.useState(false);
  const [filter, setFilter] = React.useState<Filter>({
    open: false,
    values: {
      id: '',
      externalId: '',

      labId: '',
      order: '',
      accessionId: '',
      address: '',
      assigningAuthority: '',
      birthDate: '',
      firstName: '',
      lastName: '',
      sex: '',
      city: '',
      state: '',
      zipCode: '',
      specimanType: ''
    }
  });

  const [filters, setFilter_Body] = React.useState<Body[]>([]);

  const handleCloseFilter = (args?: Export) => {
    if (args) {
      setFilter({ open: false, values: args });
      const keys = Object.keys(args);
      const tmp_filter_body = [];

      for (const key of keys) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((args as any)[key].length) {
          tmp_filter_body.push({
            key,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: (args as any)[key]
          });
        }
      }

      setFilter_Body(tmp_filter_body);
    }
    setFilter({ ...filter, open: false });
  };

  const handleOpenFilter = () => {
    setFilter({ ...filter, open: true });
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose2 = () => {
    setToast({ ...toast, open: false });
  };
  React.useEffect(() => {
    setLoader(true);
    fetch(config.baseUrl + config.apiURL.sampleList, {
      method: 'POST',
      body: JSON.stringify({
        pageSize: rowsPerPage,
        pageNo: page + 1,
        field: 'id',
        sort: 'DESC',
        filters
      }),
      headers: {
        authorization: window.localStorage.limsKey,
        'content-type': config.contentType
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setLoader(false);

        if (data.data && data.data.list) {
          setRows(data.data.list);
          setCount(data.data.count);

          if (data.data.list.length === 0) {
            setToast({ open: true, severity: 0, message: 'No Data Found' });
          }
        } else {
          setToast({ open: true, severity: 0, message: data.message });
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ open: true, severity: 0, message: err.message });
      });
  }, [page, rowsPerPage, successDelete, filters]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, i: string) => {
    const selectedIndex = selected.indexOf(i);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, i);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (i: string) => selected.indexOf(i) !== -1;

  const fields: ShowFields = {
    id,
    orderId,
    externalId,
    tests,
    labId,
    source,
    accessionId,
    address,
    assigningAuthority,
    birthDate,
    firstName,
    lastName,
    sex,
    city,
    state,
    zipCode,
    confirmationId,
    specimanType
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (arg?: ShowFields) => {
    if (arg) {
      setid(arg.id);
      setOrderId(arg.orderId);
      setExternalId(arg.externalId);
      setTests(arg.tests);
      setSource(arg.source);
      setLabId(arg.labId);
      setAccessionId(arg.accessionId);
      setAddress(arg.address);
      setAssigningAuthority(arg.assigningAuthority);
      setBirthDate(arg.birthDate);
      setCity(arg.city);
      setState(arg.state);
      setFirstName(arg.firstName);
      setLastName(arg.lastName);
      setSex(arg.sex);
      setZipCode(arg.zipCode);
      setConfirmationId(arg.confirmationId);
      setSpecimanType(arg.specimanType);
    }
    setOpen(false);
  };

  const tmpExport: Export[] = [];

  return (
    <Container>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={toast.open} onClose={handleClose2}>
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose2}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Grid justify="flex-end" container>
        <Grid item>
          <Box pb={2}>
            <Button className={classes1.info}>
              <Link className={classes1.white} to="/center/samples/sampleDetail">
                + New Sample
              </Link>
            </Button>
          </Box>
        </Grid>
      </Grid>
      {detailPage ? (
        <div>
          <Paper className={classes.paper} elevation={5}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              selected={selected}
              setDelToast={(message: string, severity: number) => {
                setToast({ open: true, message, severity });

                if (severity) {
                  setSuccessDelete(!successDelete);
                  setSelected([]);
                }
              }}
            />
            <Grid justify="space-between" container>
              <Grid md={3} xs={12} item>
                <Grid justify="space-between" container>
                  {/* <Grid item>
                    <FormControl className={classes1.formControl} style={{ display: 'none' }} variant="outlined">
                      <Select input={<BootstrapInput />} value="Action" onChange={handleAction}>
                        <MenuItem value="Action">Action</MenuItem>
                        <MenuItem value="sampleId">Sample Id</MenuItem>
                        <MenuItem value="orderId">Order Id</MenuItem>
                        <MenuItem value="externalId">External Accession Id</MenuItem>
                        <MenuItem value="tests">Tests</MenuItem>
                        <MenuItem value="labId">Lab Id</MenuItem>
                        <MenuItem value="source">Source</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                </Grid>
              </Grid>
              <Grid className={classes1.widthMaxContent} item>
                <Grid container>
                  <Grid className={classes1.widthMaxContent} item>
                    <Tooltip placement="top" title="View Menu">
                      <StyledButton
                        className={classes1.info}
                        color="primary"
                        variant="contained"
                        onClick={handleClickOpen}
                      >
                        <AppsIcon />
                      </StyledButton>
                    </Tooltip>
                    <StyledDialog
                      open={open}
                      selectedValue={{
                        id,
                        orderId,
                        externalId,
                        tests,
                        labId,
                        source,
                        accessionId,
                        address,
                        assigningAuthority,
                        birthDate,
                        firstName,
                        lastName,
                        sex,
                        city,
                        state,
                        zipCode,
                        confirmationId,
                        specimanType
                      }}
                      onClose={handleClose}
                    />
                    <ExcelFile
                      element={
                        <Tooltip placement="top" title="Export">
                          <StyledButton className={classes1.btnStyle} color="primary" variant="contained">
                            {' '}
                            <FaDownload />
                          </StyledButton>
                        </Tooltip>
                      }
                    >
                      <ExcelSheet data={tmpExport} name="Employees">
                        <ExcelColumn label="Sample ReportId" value="id" />
                        <ExcelColumn label="Order Id" value="confirmationId" />
                        <ExcelColumn label="Lab Id" value="labId" />
                        <ExcelColumn label="Accession Id" value="accessionId" />
                        <ExcelColumn label=" External Accession Id" value="externalId" />
                        <ExcelColumn label="Tests" value="tests" />
                        <ExcelColumn label="Source" value="source" />
                        <ExcelColumn label="Tests" value="tests" />
                        <ExcelColumn label="Assigning Authority" value="assigningAuthority" />
                        <ExcelColumn label="First Name" value="firstName" />
                        <ExcelColumn label="Last Name" value="lastName" />
                        <ExcelColumn label="Sex" value="sex" />
                        <ExcelColumn label="Birth Date" value="birthDate" />
                        <ExcelColumn label="Address" value="address" />
                        <ExcelColumn label="City" value="city" />
                        <ExcelColumn label="State" value="state" />
                        <ExcelColumn label="ZipCode" value="zipCode" />
                        <ExcelColumn label="Specimen Type" value="specimanType" />{' '}
                      </ExcelSheet>
                    </ExcelFile>
                  </Grid>
                  <Tooltip placement="top" title="Filter">
                    <StyledButton
                      className={classes1.filterBtn}
                      color="primary"
                      variant="contained"
                      onClick={handleOpenFilter}
                    >
                      <FaFilter />
                    </StyledButton>
                  </Tooltip>
                  <FilterDialog
                    filterOpen={filter.open}
                    filterValues={filter.values}
                    onFilterClose={handleCloseFilter}
                  />
                  <Grid className={classes1.widthMaxContent} item>
                    <StyledTablePagination
                      ActionsComponent={() => {
                        return (
                          <Grid direction="row" container>
                            <Grid item>
                              <StyledPagination
                                boundaryCount={1}
                                className={classes1.widthparent}
                                color="primary"
                                count={Math.ceil(count / rowsPerPage)}
                                page={page + 1}
                                shape="rounded"
                                siblingCount={0}
                                variant="outlined"
                                showFirstButton
                                showLastButton
                                onChange={(e, p) => {
                                  handleChangePage(e, p - 1);
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      }}
                      className={classes1.widthparent}
                      count={count}
                      labelRowsPerPage=""
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[10, 50, 100]}
                      // onChangePage={handleChangePage}
                      // onChangeRowsPerPage={handleChangeRowsPerPage}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer>
              <Table
                aria-label="enhanced table"
                aria-labelledby="tableTitle"
                className={classes.table}
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  classes={classes}
                  fields={fields}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  rowCount={rows.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                {!loader && (
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      tmpExport.push(row);

                      return (
                        <TableRow
                          key={row.id}
                          aria-checked={isItemSelected}
                          className={classes1.selected}
                          role="checkbox"
                          // selected={isItemSelected}
                          tabIndex={-1}
                          hover
                        >
                          <StyledCell className={classes1.grey} padding="checkbox">
                            <Box pl={0} pr={10}>
                              <Checkbox
                                checked={isItemSelected}
                                color="primary"
                                inputProps={{ 'aria-labelledby': labelId }}
                                onClick={(event) => handleClick(event, row.id)}
                              />
                            </Box>
                          </StyledCell>
                          {id && (
                            <StyledCell align="left" className={classes1.grey}>
                              <Link className="a" to={'/center/samples/sampleDetail/' + row.id}>
                                <Box className={classes1.border}>{row.id}</Box>
                              </Link>
                            </StyledCell>
                          )}

                          {orderId && (
                            <StyledCell align="left" className={classes1.grey}>
                              <Link to={'/center/samples/orderDetail/' + row.orderId}>
                                <Box className={row.orderId ? classes1.border : ''}>
                                  {row.orderId ? row.confirmationId : ''}
                                </Box>
                              </Link>
                            </StyledCell>
                          )}
                          {labId && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.labId}
                            </StyledCell>
                          )}
                          {accessionId && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.accessionId}
                            </StyledCell>
                          )}
                          {externalId && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.externalId}
                            </StyledCell>
                          )}
                          {source && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.source}
                            </StyledCell>
                          )}
                          {tests && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.tests}
                            </StyledCell>
                          )}
                          {assigningAuthority && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.assigningAuthority}
                            </StyledCell>
                          )}
                          {firstName && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.firstName}
                            </StyledCell>
                          )}
                          {lastName && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.lastName}
                            </StyledCell>
                          )}
                          {sex && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.sex}
                            </StyledCell>
                          )}
                          {birthDate && (
                            <StyledCell align="left" className={classes1.grey}>
                              {moment(row.birthDate).format('DD-MM-YYYY')}
                            </StyledCell>
                          )}
                          {address && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.address}
                            </StyledCell>
                          )}
                          {city && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.city}
                            </StyledCell>
                          )}
                          {state && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.state}
                            </StyledCell>
                          )}
                          {zipCode && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.zipCode}
                            </StyledCell>
                          )}
                          {specimanType && (
                            <StyledCell align="left" className={classes1.grey}>
                              {row.specimanType}
                            </StyledCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
              <div hidden>
                <FormControlLabel
                  control={<Switch checked={dense} color="primary" onChange={handleChangeDense} />}
                  label="Dense padding"
                />
              </div>
            </TableContainer>
          </Paper>
          {loader && (
            <Grid xs={12}>
              <Grid justify="center" container>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      ) : (
        ''
      )}
    </Container>
  );
}

export default SampleListing;
