import { config } from '../../config';

export const forgetPassword = async (email: string, accessToken: string): Promise<Response> => {
  return await fetch(config.baseUrl + config.apiURL.userForgetPassword, {
    method: 'POST',
    body: JSON.stringify({
      email
    }),
    headers: {
      authorization: accessToken,
      'content-type': config.contentType
    }
  });
};

export const resetPassword = async (email: string, password: string): Promise<Response> => {
  return await fetch(config.baseUrl + config.apiURL.userResetPassword, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password
    }),
    headers: {
      authorization: 'cb0f9368-b007-4e17-8409-847da6f0fc98',
      'content-type': config.contentType
    }
  });
};
