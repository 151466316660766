import { Dialog, DialogTitle, Grid, Box, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { FilterProps, Export } from './Models';
import { useStyles1, SaveButton, DeleteButton, CloneButton } from './styles';

export default function FilterDialog(props: FilterProps): React.ReactElement {
  const classes = useStyles1();

  const { filterOpen, filterValues, onFilterClose } = props;

  const [filters, setFilters] = React.useState<Export>({
    id: filterValues.id,
    customerAccountId: filterValues.customerAccountId,
    name: filterValues.name,
    title: filterValues.title,
    customerAbbreviation: filterValues.customerAbbreviation,
    phone: filterValues.phone,
    fax: filterValues.fax,
    defaultReport: filterValues.defaultReport,
    defaultSampleLabel: filterValues.defaultSampleLabel,
    poNumber: filterValues.poNumber
  });

  const [state, setState] = React.useState(false);

  const [able, setAble] = React.useState(false);

  let count = 1;

  const handleClose = (prop: boolean) => {
    if (prop) {
      onFilterClose({ ...filters });
    }
    onFilterClose();
  };

  const handleCloses = (propses: boolean) => {
    if (propses) {
      onFilterClose({
        id: '',
        customerAccountId: '',
        name: '',
        title: '',
        customerAbbreviation: '',
        phone: '',
        fax: '',
        defaultReport: '',
        defaultSampleLabel: '',
        poNumber: ''
      });
    }
    onFilterClose();
  };

  const close = () => {
    handleClose(true);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
      open={filterOpen}
      fullWidth
      onClose={() => {
        handleClose(false);
      }}
    >
      <Box p={4}>
        <div className={classes.widthparents}>
          <DialogTitle className={classes.widthparent} id="simple-dialog-title">
            Filters
          </DialogTitle>
          <div className={classes.close} onClick={() => handleClose(false)}>
            <CloseIcon />
          </div>
        </div>
        <form
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
            handleClose(true);
          }}
        >
          <Grid container>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Customer Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Customer Id"
                size="small"
                value={filters.id}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.id = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Customer Abbreviation</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Abbreviation"
                size="small"
                value={filters.customerAbbreviation}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.customerAbbreviation = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Name</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Name"
                size="small"
                value={filters.name}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.name = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Title</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Title"
                size="small"
                value={filters.title}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.title = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Phone</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Phone"
                size="small"
                type="number"
                value={filters.phone}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.phone = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Fax</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Fax"
                size="small"
                value={filters.fax}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.fax = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Default Report</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Default Report"
                size="small"
                value={filters.defaultReport}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.defaultReport = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}> Default Sample Label</b>

              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.defaultSampleLabel}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const tmp = { ...filters };
                    tmp.defaultSampleLabel = event.target.value as string;
                    setFilters(tmp);
                    setAble(true);
                  }}
                >
                  <MenuItem value="Country">Country</MenuItem>
                  <MenuItem value="Passenger">Passenger</MenuItem>
                  <MenuItem value="Discovery Health">Discovery Health</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>PO Number</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="PO Number"
                size="small"
                type="number"
                value={filters.poNumber}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.poNumber = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} container>
              <Box mr={2}>
                <SaveButton type="submit" variant="contained">
                  Apply Filters
                </SaveButton>
              </Box>
              <Box mr={2}>
                <DeleteButton
                  onClick={() => {
                    setFilters({
                      id: '',
                      customerAccountId: '',
                      name: '',
                      title: '',
                      customerAbbreviation: '',
                      phone: '',
                      fax: '',
                      defaultReport: '',
                      defaultSampleLabel: '',
                      poNumber: ''
                    });
                    setState(able);
                  }}
                >
                  Clear Filter
                </DeleteButton>
              </Box>
              <Box mr={2}>
                <CloneButton
                  onClick={() => {
                    setFilters({
                      id: '',
                      customerAccountId: '',
                      name: '',
                      title: '',
                      customerAbbreviation: '',
                      phone: '',
                      fax: '',
                      defaultReport: '',
                      defaultSampleLabel: '',
                      poNumber: ''
                    });
                    setState(able);
                    handleCloses(true);

                    if (count !== 1) {
                      if (!state) {
                        close();
                      }
                      count++;
                    }
                  }}
                >
                  Reset Filter
                </CloneButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}
