type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dropdown: any = {
  specimanType: [
    { label: 'Mid-turbinate', value: 'Mid-turbinate' },
    { label: 'Nasopharyngel', value: 'Nasopharyngel' }
  ]
};

export const menuItems = [
  {
    label: 'Speciman Type',
    value: 'specimanType',
    dropdown: true,
    date: false
  },
  {
    label: 'Time of Collection',
    value: 'timeOfCollection',
    dropdown: false,
    date: true
  },
  {
    label: 'LabId',
    value: 'labId',
    dropdown: false,
    date: false
  }
];
