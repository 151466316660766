import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';



import AppMenuItemComponent from './AppMenuItemComponent';

// React runtime PropTypes
export const AppMenuItemProp = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  Icon: PropTypes.elementType,
  items: PropTypes.array
};

type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemProp>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>;

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[];
  small?: boolean
};

const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      '&.active': {
        background: 'rgba(0, 0, 0, 0.48)',
        '& .MuiListItemIcon-root': {
          color: '#fff'
        }
      }
    },
    menuItemIcon: {
      color: '#FFF'
    },
    list: {
      background: "rgb(1,1,1,0.1)",
    }
  })
);

export const SmallListItemText = withStyles({
  primary: {
    fontSize: 'small'

  },
  root: {

  }
})(ListItemText);

const AppMenuItem: React.FC<AppMenuItemProps> = (props) => {
  const { name, link, Icon, items = [], small } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={classes.menuItem}
      link={link}
      onClick={handleClick}
    >
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon />
        </ListItemIcon>
      )}
      {small ?
        <SmallListItemText primary={name} inset />
        :
        <ListItemText inset={!Icon} primary={name} />
      }

      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List className={classes.list} component="div" disablePadding>
        {items.map((item) => (
          <AppMenuItem {...item} key={item.name} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default AppMenuItem;
