import React from 'react';
import {
  Container,
  Paper,
  Grid,
  Box,
  TextField,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Divider
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { Toast } from '../../SamplePage/Models';
import { SaveButton } from '../../SamplePage/styles';

import { addCustomeFields, getCustomFields } from './api';
import { useStyles } from './Styles';
import { NewFields, GetFields } from './Models';

import './styles.css';

export default function TabPanelContent(props: { id: number }): React.ReactElement {
  const classesCustom = useStyles();
  const [newField, setNewField] = React.useState<NewFields>({
    customField: '',
    type: '',
    defaultValue: '',
    isRequired: '0'
  });
  const [added, setAdded] = React.useState<boolean>(false);
  const [getFields, setGetFields] = React.useState<GetFields[]>([]);
  const [loaderAdd, setLoaderAdd] = React.useState<boolean>(false);
  const [loaderFetch, setLoaderFetch] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: ''
  });

  function Alert(prop: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...prop} />;
  }

  const handleCloseAlert = () => {
    setToast({ ...toast, open: false });
  };

  const handleSubmit = async (newField: NewFields) => {
    setLoaderAdd(true);
    addCustomeFields(props.id, newField.customField, newField.type, newField.defaultValue, newField.isRequired)
      .then(async (response: Response) => {
        const message = await response.json();

        setLoaderAdd(false);

        if (message.status === 200) {
          setNewField({
            customField: '',
            type: '',
            defaultValue: '',
            isRequired: '0'
          });
          setAdded(!added);
        }
        setToast({
          ...toast,
          open: true,
          severity: message.status === 200 ? 1 : 0,
          message: message.message
        });
      })
      .catch((err: Error) => {
        setNewField({
          customField: '',
          type: '',
          defaultValue: '',
          isRequired: '0'
        });

        setLoaderAdd(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message });
      });
  };

  React.useEffect(() => {
    setLoaderFetch(true);
    getCustomFields(props.id)
      .then(async (response: Response) => {
        const message = await response.json();

        setLoaderFetch(false);

        const values: GetFields[] = Object.values(message.data);

        setGetFields(values);
        setToast({
          ...toast,
          open: true,
          severity: message.status === 200 ? 1 : 0,
          message: message.message
        });
      })
      .catch((err: Error) => {
        setLoaderFetch(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message });
      });
  }, [added]);

  return (
    <Container>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={toast.open} onClose={handleCloseAlert}>
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleCloseAlert}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Grid className={classesCustom.height} justify="center" container>
        <Grid className={classesCustom.heightFull} md={6} xs={12} item>
          <Box className={classesCustom.heightFull} m={2}>
            <div className="heading">
              New Custom Field
              <Divider />
            </div>
            <Paper className={classesCustom.heightForm} elevation={1}>
              <Box p={2}>
                <form
                  onSubmit={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    handleSubmit(newField);
                  }}
                >
                  <Grid className={classesCustom.padding} xs={12} item>
                    <b className={classesCustom.label}>Custom Field</b>
                    <b className="red-star">*</b>
                    <TextField
                      className={classesCustom.width}
                      color="primary"
                      placeholder="Custom Field..."
                      size="small"
                      value={newField.customField}
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(event) => {
                        const tmp = { ...newField };

                        tmp.customField = event.target.value;

                        setNewField(tmp);
                      }}
                    />
                  </Grid>
                  <Grid className={classesCustom.padding} xs={12} item>
                    <b className={classesCustom.label}>Type of Custom Field</b>
                    <b className="red-star">*</b>
                    <TextField
                      className={classesCustom.width}
                      color="primary"
                      placeholder="Type..."
                      size="small"
                      value={newField.type}
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(event) => {
                        const tmp = { ...newField };

                        tmp.type = event.target.value;

                        setNewField(tmp);
                      }}
                    />
                  </Grid>
                  <Grid className={classesCustom.padding} xs={12} item>
                    <b className={classesCustom.label}>Default Value (Optional)</b>
                    <TextField
                      className={classesCustom.width}
                      color="primary"
                      placeholder="Default Value..."
                      size="small"
                      value={newField.defaultValue}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        const tmp = { ...newField };

                        tmp.defaultValue = event.target.value;

                        setNewField(tmp);
                      }}
                    />
                  </Grid>
                  <Grid className={classesCustom.padding} xs={12} item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newField.isRequired === '1' ? true : false}
                          color="primary"
                          name="checkedB"
                          onChange={(event) => {
                            const tmp = { ...newField };

                            tmp.isRequired = '0';

                            if (event.target.checked) {
                              tmp.isRequired = '1';
                            }

                            setNewField(tmp);
                          }}
                        />
                      }
                      label="Is Required ?"
                    />
                  </Grid>

                  <Grid className={classesCustom.padding} container>
                    <Box mr={2}>
                      <SaveButton type="submit" variant="contained">
                        {loaderAdd ? <CircularProgress color="inherit" size={25} /> : '+ Add'}
                      </SaveButton>
                    </Box>
                  </Grid>
                </form>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid className={classesCustom.heightFull} md={6} xs={12} item>
          <Box className={classesCustom.heightFull} m={2}>
            <div className="heading">
              Custom Fields
              <Divider />
            </div>
            <Paper className={classesCustom.heightScroll} elevation={1}>
              <Box p={2}>
                {loaderFetch ? (
                  <div className={classesCustom.makecenter}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="lists-container">
                    {getFields.map((field) => {
                      return (
                        <div key={field.id} className="lists">
                          {field.fieldName}
                        </div>
                      );
                    })}
                  </div>
                )}
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
