import React from 'react';

import MiniDrawer from '../../components/sidemenu/MiniDrawer';

const CenterLoginLayout: React.FC = () => {
  return (
    <div>
      <MiniDrawer />
    </div>
  );
};

export default CenterLoginLayout;
