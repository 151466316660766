import { Dialog, DialogTitle, Grid, Box, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { Toast, Users, Assay } from '../Orders/TestDetails/Models';
import { userDropdown, assayDropdown } from '../Orders/TestDetails/api';

import { FilterProps, Export } from './Models';
import { useStyles1, SaveButton, DeleteButton, CloneButton } from './styles';

export default function FilterDialog(props: FilterProps): React.ReactElement {
  const classes = useStyles1();
  const { filterOpen, filterValues, onFilterClose } = props;

  const [filters, setFilters] = React.useState<Export>({
    id: filterValues.id,
    orderId: filterValues.orderId,
    orderStatus: filterValues.orderStatus,
    sampleId: filterValues.sampleId,
    testStatus: filterValues.testStatus,
    assayTitle: filterValues.assayTitle,
    assayId: filterValues.assayId,
    technician: filterValues.technician,
    results: filterValues.results
  });

  const [state, setState] = React.useState(false);

  const [able, setAble] = React.useState(false);

  let count = 1;

  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });

  const [userList, setUserList] = React.useState<Users[]>([
    {
      id: '',
      userName: ''
    }
  ]);

  const [assayId, setAssayId] = React.useState<Assay[]>([
    {
      id: '',
      title: ''
    }
  ]);

  const handleClose = (props: boolean) => {
    if (props) {
      onFilterClose({ ...filters });
    }
    onFilterClose();
  };

  const handleCloses = (props: boolean) => {
    if (props) {
      onFilterClose({
        orderId: '',
        orderStatus: '',
        sampleId: '',
        id: '',
        testStatus: '',
        assayTitle: '',
        assayId: '',
        technician: '',
        results: ''
      });
    }
    onFilterClose();
  };

  const close = () => {
    handleClose(true);
  };

  React.useEffect(() => {
    userDropdown()
      .then(async (response: Response) => {
        const data = await response.json();

        if (data.status === 200) {
          setUserList(data.data);
        } else {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
        }
      })
      .catch((err: Error) => {
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });

    assayDropdown()
      .then(async (response: Response) => {
        const data = await response.json();

        if (data.status === 200) {
          setAssayId(data.data);
        } else {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
        }
      })
      .catch((err: Error) => {
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  }, []);

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
      open={filterOpen}
      fullWidth
      onClose={() => {
        handleClose(false);
      }}
    >
      <Box p={4}>
        <div className={classes.widthparents}>
          <DialogTitle className={classes.widthparent} id="simple-dialog-title">
            Filters
          </DialogTitle>
          <div className={classes.close} onClick={() => handleClose(false)}>
            <CloseIcon />
          </div>
        </div>
        <form
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
            handleClose(true);
          }}
        >
          <Grid container>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>order Id</b>
              <TextField
                className={classes.width}
                color="primary"
                placeholder="Order Id"
                size="small"
                value={filters.orderId}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.orderId = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Order Status</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Order Status"
                size="small"
                value={filters.orderStatus}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.orderStatus = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Sample Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Sample Id"
                size="small"
                value={filters.sampleId}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.sampleId = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Test Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Test Id"
                size="small"
                value={filters.id}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.id = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Test Status</b>
              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.testStatus}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const tmp = { ...filters };
                    tmp.testStatus = event.target.value as string;
                    setFilters(tmp);
                    setAble(true);
                  }}
                >
                  <MenuItem value="NOT STARTED">Not Started</MenuItem>
                  <MenuItem value="INPROGRESS">In Progress</MenuItem>
                  <MenuItem value="NEEDS REVIEW">Needs Review</MenuItem>
                  <MenuItem value="CREATED">Created</MenuItem>
                  <MenuItem value="COMPLETED">Completed</MenuItem>
                  <MenuItem value="CANCELLED">Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Assay Title</b>
              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.assayTitle}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const tmp = { ...filters };
                    tmp.assayTitle = event.target.value as string;
                    setFilters(tmp);
                    setAble(true);
                  }}
                >
                  {assayId.map((asy) => {
                    return (
                      <MenuItem key={asy.id} value={asy.title}>
                        {asy.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Technician</b>
              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.technician}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const tmp = { ...filters };
                    tmp.technician = event.target.value as string;
                    setFilters(tmp);
                    setAble(true);
                  }}
                >
                  {userList.map((tech) => {
                    return (
                      <MenuItem key={tech.id} value={`${tech.id}`}>
                        {tech.userName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Results</b>
              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.results}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const tmp = { ...filters };
                    tmp.results = event.target.value as string;
                    setFilters(tmp);
                    setAble(true);
                  }}
                >
                  <MenuItem value="DETECTED">Detected</MenuItem>
                  <MenuItem value="NOT-DETECTED">Not Detected</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid className={classes.padding} container>
              <Box mr={2}>
                <SaveButton type="submit" variant="contained">
                  Apply Filters
                </SaveButton>
              </Box>
              <Box mr={2}>
                <DeleteButton
                  onClick={() => {
                    setFilters({
                      orderId: '',
                      orderStatus: '',
                      sampleId: '',
                      id: '',
                      testStatus: '',
                      assayTitle: '',
                      assayId: '',
                      technician: '',
                      results: ''
                    });
                    setState(able);
                  }}
                >
                  Clear Filter
                </DeleteButton>
              </Box>
              <Box mr={2}>
                <CloneButton
                  onClick={() => {
                    setFilters({
                      orderId: '',
                      orderStatus: '',
                      sampleId: '',
                      id: '',
                      testStatus: '',
                      assayTitle: '',
                      assayId: '',
                      technician: '',
                      results: ''
                    });
                    setState(able);
                    handleCloses(true);

                    if (count !== 1) {
                      if (!state) {
                        close();
                      }
                      count++;
                    }
                  }}
                >
                  Reset Filter
                </CloneButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}
