import React from 'react';
import styled from 'styled-components';

const PrintableBodyWrapper = styled.div`
  @media print {
    .noprint {
      display: none;
    }
    margin: 0px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Paper = styled.div`
  margin: 0;
  padding: 20px 50px;
  background: white;
  width: 595px;
  height: 842px;
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 70px;
`;

const B = styled.b`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

const Boldtext = styled.b`
  font-size: 13px;
`;

const End = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: -260px;
  font-size: 12px;
`;

const Jap = styled.div`
  font-size: 12px;
`;

const P = styled.p`
  margin-top: -18px;
  font-size: 12px;
`;

const Centers = styled.div`
  display: flex;
  width: 400px;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -20px;
`;

const CenterP = styled.p`
  margin-top: -15px;
  font-size: 11px;
`;

const Table = styled.table`
  border: 0.5px solid black;
  border-collapse: collapse;
  margin-top: -15px;
  margin-left: 0px;
  width: 595px;
  font-size: 12px;
  padding: 20px;
`;

const Tds = styled.td`
  border: 0.5px solid black;
  border-bottom: 0.5px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 5px 0;
  font-size: 10px;
  width: 100px;
`;
const Tds1 = styled.td`
  border: 0.5px solid black;
  border-bottom: 0.5px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 7px 0;
  font-size: 10px;
  width: 300px;
`;
const Tds2 = styled.td`
  border: 0.5px solid black;
  border-bottom: 0.5px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 7px 0;
  font-size: 10px;
  width: 200px;
`;

const Tds3 = styled.td`
  border: 0.5px solid black;
  border-bottom: 0.5px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 7px 0;
  font-size: 10px;
  width: 190px;
`;
const Tds4 = styled.td`
  border: 0.5px solid black;
  border-bottom: 0.5px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 7px 0;
  font-size: 10px;
  width: 200px;
`;
const Trbg = styled.tr`
  background: #d9d9d9;
`;

const Tr = styled.tr``;

const Td = styled.td`
  border-right: 0.5px solid black;
  padding: 0px 2px;
  font-size: 7px;
  margin-top: -10px;
  align-item: center;
`;

const Box = styled.div`
  border: 2px solid black;
  padding: 2px;
  margin-top: 10px;
  text-align: center;
`;

const Bottom = styled.div`
  display: flex;
  font-size: 8px;
  align-item: center;
  justify-content: space-between;
`;

const Print = styled.button`
  margin: 30px 0 5px 600px;
  outline: none;
  border: none;
  padding: 5px 15px;
  font-size: 15px;
  border-radius: 50px;
  width: 80px;
  background: #008080;
  color: white;
  letter-spacing: 1px;
`;

const J = styled.p``;

const Japanese: React.FC = () => {
  return (
    <div>
      <PrintableBodyWrapper>
        <Print className="noprint" onClick={() => window.print()}>
          Print
        </Print>
        <Paper className="print">
          <Head>
            <B>Quarantine Station,</B>
            <B>Ministry of Health, Labour and Welfare, Japanese Government</B>
          </Head>
          <hr style={{ border: '2.4px solid black', background: 'black' }} />
          <hr style={{ marginTop: '-5px', border: '.7px solid black', background: 'black' }} />
          <Middle>
            <Center>
              <Boldtext>COVID-19{` に関する検査証明`}</Boldtext>
              <Boldtext>Certificate of Testing for COVID-19</Boldtext>
            </Center>
            <End>
              <Jap>
                <J>交付年月日</J>
                <P>Date of issue</P>
              </Jap>
            </End>
            <Centers>
              <Jap>
                <p>氏 名 </p>
                <P>Name</P>
              </Jap>
              <Jap>
                <p>パスポート番号 </p>
                <P>Passport No. </P>
              </Jap>
            </Centers>
            <Centers>
              <Jap>
                <p>国籍 </p>
                <P>Nationality</P>
              </Jap>
              <Jap>
                <p>生年月日 </p>
                <P>Date of Birth</P>
              </Jap>
              <Jap>
                <p>性別 </p>
                <P>Sex</P>
              </Jap>
            </Centers>
            <CenterP>
              <p>
                上記の者の COVID-19 に関する検査を行った結果、その結果は下記のとおりである。
                よって、この証明を交付する。
              </p>
              <p style={{ marginTop: '-10px' }}>
                This is to certify the following results which have been confirmed by testing for COVID-19 conducted
                with the sample taken from the above-mentioned person.
              </p>
            </CenterP>
          </Middle>
          <Table>
            <Trbg>
              <Tds>採取検体 Sample （下記いずれかをチェック /Check one of the boxes below）</Tds>
              <Tds1>
                createdAt検査法 Testing Method for COVID-19 （下記いずれかをチェック/Check one of the boxes below）
              </Tds1>
              <Tds2>結果 Result</Tds2>
              <Tds3>①結果判明日 Test Result Date ②検体採取日時 Specimen Collection Date and Time</Tds3>
              <Tds4>備考 Remarks</Tds4>
            </Trbg>
            <Tr>
              <Td>
                <input type="checkbox" /> 鼻咽頭ぬぐい液 Nasopharyngeal Swab
              </Td>
              <Td>
                <input type="checkbox" />
                核酸増幅検査（RT-PCR 法） Nucleic acid amplification test（RTPCR）
              </Td>
              <Td>
                <input type="checkbox" />
                陰性 Negative
              </Td>
              <Td>① Date(yyyy /mm /dd)</Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <input type="checkbox" />
                唾液 Saliva
              </Td>
              <Td>
                <input type="checkbox" />
                核酸増幅検査（LAMP 法） Nucleic acid amplification test （LAMP）
              </Td>
              <Td>
                <input type="checkbox" />
                陽性 Positive →入国不可 No entry into Japan
              </Td>
              <Td>
                ② Date(yyyy /mm /dd) <br />
                Time AM/PM :
              </Td>
              <Td />
            </Tr>
            <Tr>
              <Td>
                <input type="checkbox" />
                鼻咽頭ぬぐい液と咽頭 ぬぐい液の混合 Nasopharyngeal and oropharyngeal swabs
              </Td>
              <Td>
                <input type="checkbox" />
                核酸増幅検査（TMA 法） Nucleic acid amplification test （TMA）
              </Td>
              <Td />
              <Td />
              <Td />
            </Tr>
            <Tr>
              <Td />
              <Td>
                <input type="checkbox" />
                核酸増幅検査（TMA 法） Nucleic acid amplification test （TMA）
              </Td>
              <Td />
              <Td />
              <Td />
            </Tr>
            <Tr>
              <Td />
              <Td>
                <input type="checkbox" />
                核酸増幅検査（TRC 法） Nucleic acid amplification test （TRC）
              </Td>
              <Td />
              <Td />
              <Td />
            </Tr>
            <Tr>
              <Td />
              <Td>
                <input type="checkbox" />
                核酸増幅検査（Smart Amp 法） Nucleic acid amplification test （Smart Amp）
              </Td>
              <Td />
              <Td />
              <Td />
            </Tr>
            <Tr>
              <Td />
              <Td>
                <input type="checkbox" />
                核酸増幅検査（NEAR 法） Nucleic acid amplification test （NEAR）
              </Td>
              <Td />
              <Td />
              <Td />
            </Tr>
            <Tr>
              <Td />
              <Td>
                <input type="checkbox" />
                抗原定量検査* Quantitative antigen test* (CLEIA/ECLEIA)
              </Td>
              <Td />
              <Td />
              <Td />
            </Tr>
          </Table>
          <Bottom>
            <div>
              <p>医療機関名 Name of Medical institution</p>
              <p>住所 Address of the institution</p>
              <p>医師名 Signature by doctor</p>
            </div>
            <Box>
              <p>印影</p> <p>An imprint of a seal</p>
            </Box>
          </Bottom>
        </Paper>
      </PrintableBodyWrapper>
    </div>
  );
};

export default Japanese;
