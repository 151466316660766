import { config } from '../../../config';

import { Params } from './Models';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchData = async (id: number) => {
  return await fetch(config.baseUrl + config.apiURL.customerDetails, {
    method: 'POST',
    body: JSON.stringify({
      id
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const submit = async (params: Params, body: string) => {
  let url = config.baseUrl + config.apiURL.customerAdd;

  if (params.id) {
    url = config.baseUrl + config.apiURL.customerModify;
  }

  return await fetch(url, {
    method: 'POST',
    body,
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const del = async (id: number) => {
  return await fetch(config.baseUrl + config.apiURL.customerDeleteById, {
    method: 'POST',
    body: JSON.stringify({
      id,
      deleteStatus: 1
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
