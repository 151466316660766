import { makeStyles, withStyles } from '@material-ui/core/styles';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AccessAlarmRoundedIcon from '@material-ui/icons/AccessAlarmRounded';
import PieChartRoundedIcon from '@material-ui/icons/PieChartRounded';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px'
  },
  white: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 20px rgb(197, 197, 197)'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));
export const StyledGlass = withStyles({
  root: {
    color: 'white'
  }
})(HourglassEmptyIcon);
export const StyledAccessAlarmRoundedIcon = withStyles({
  root: {
    color: 'white'
  }
})(AccessAlarmRoundedIcon);
export const StyledPieChartRoundedIcon = withStyles({
  root: {
    color: 'white'
  }
})(PieChartRoundedIcon);
