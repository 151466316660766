import { config } from '../../config';

import { UpdateMany } from './Models';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const multipleDeletes = async (ids: string[]) => {
  return await fetch(config.baseUrl + config.apiURL.sampleDeleteById, {
    method: 'POST',
    body: JSON.stringify({
      id: [...ids],
      deleteStatus: 1
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateMany = async (ids: string[], updated: UpdateMany) => {
  const body: UpdateMany = { ...updated, id: [...ids] };

  return await fetch(config.baseUrl + config.apiURL.sampleUpdateMany, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
