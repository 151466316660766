import React from "react";

import App from "./App";
const Dashboardpage: React.FC = () => {
  return (
    <>
      <App />
    </>
  );
};

export default Dashboardpage;
