import { makeStyles, withStyles, createStyles, TableCell, TablePagination, Button } from '@material-ui/core';
import { lighten, Theme } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { Pagination } from '@material-ui/lab';

export const useStyles1 = makeStyles({
  formControl: {
    borderRadius: '5px',
    minWidth: 120,
    backgroundColor: '#5D9CEC',
    color: 'white',
    height: '40px',
    margin: '10px'
  },
  width: {
    width: '100%',
    color: 'grey'
  },
  label: {
    color: 'grey',
    padding: '5px'
  },
  btn: {
    color: 'white',
    padding: '5px',
    backgroundColor: '#5D9CEC'
  },
  filterBtn: {
    width: '40px',
    height: '35px',
    backgroundColor: '#58C9C7',
    '&:hover': {
      backgroundColor: '#58C9C7'
    }
  },
  zero: {
    padding: '0px'
  },
  select: {
    borderRadius: '4px',
    color: 'grey',
    border: '1px soild rgb(173, 173, 173,0.1)',
    padding: '8px'
  },
  col: {
    color: 'grey',
    textAlign: 'left',
    padding: '0px',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  border: {
    border: '1px solid rgb(1,1,1,0.3)',
    borderRadius: '5px',
    padding: '10px',
    minWidth: 'max-content'
  },

  borderViolet: {
    border: 'none',
    borderRadius: '5px',
    padding: '10px',
    width: '115px',
    color: 'white',
    fontWeight: 'bold',
    background: '#7266ba',
    textAlign: 'center'
  },
  borderRed: {
    border: 'none',
    borderRadius: '5px',
    padding: '10px',
    width: '115px',
    color: 'white',
    fontWeight: 'bold',
    background: '#f05050',
    textAlign: 'center'
  },
  borderYellow: {
    border: 'none',
    borderRadius: '5px',
    padding: '10px',
    width: '115px',
    color: 'white',
    fontWeight: 'bold',
    background: '#ffbd4a',
    textAlign: 'center'
  },
  borderGreen: {
    border: 'none',
    borderRadius: '5px',
    padding: '10px',
    width: '115px',
    color: 'white',
    fontWeight: 'bold',
    background: '#5cb85c',
    textAlign: 'center'
  },
  borderSkyblue: {
    border: 'none',
    borderRadius: '5px',
    padding: '10px',
    width: '115px',
    color: 'white',
    fontWeight: 'bold',
    background: '#34d3eb',
    textAlign: 'center'
  },
  table: {
    minWidth: 650
  },
  action: {
    height: '40px',
    color: 'white',
    border: '0px',
    '&:focus': {
      border: '0px',
      boxShadow: '0 0 0'
    }
  },
  heading: {
    color: '#707070',
    width: 'max-content',
    padding: '5px'
  },
  grey: {
    color: 'grey',
    '$selected &': {
      backgroundColor: 'transparent'
    }
  },
  greys: {
    border: '1px solid black',
    color: 'grey',
    '$selected &': {
      backgroundColor: 'transparent'
    },
    padding: '10px',
    width: '110px',
    borderRadius: '5px'
  },

  selected: {
    '$selected &': {
      backgroundColor: 'transparent'
    }
  },
  widthparent: {
    width: 'max-content'
  },
  widthparents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  close: {
    cursor: 'pointer',
    padding: '10px 10px 5px 10px',
    borderRadius: '25px',
    backgroundColor: 'none',
    '&:hover': {
      backgroundColor: '#fafafa'
    }
  },
  widthMaxContent: {
    width: 'max-content',
    display: 'inline'
  },
  padding: {
    padding: '10px'
  },
  cells: {
    border: '1px solid rgb(1,1,1,0.1)',
    padding: '0px',
    marginBottom: '5px',
    borderRadius: '5px',
    backgroundColor: 'rgb(1,1,1,0.1)'
  },
  hiddenCells: {
    border: '1px solid rgb(1,1,1,0.1)',
    padding: '0px',
    marginBottom: '5px',
    borderRadius: '5px'
  },
  info: {
    backgroundColor: '#58C9C7',
    '&:hover': {
      backgroundColor: '#58C9C7'
    }
  },
  btnStyle: {
    width: '40px',
    height: '35px'
  },
  primary: {
    backgroundColor: '#5D9CEC',
    '&:hover': {
      backgroundColor: '#5D9CEC'
    }
  },
  white: {
    color: 'white',
    textTransform: 'capitalize'
  }
});

export const BootstrapInput = withStyles(() =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      color: 'white',
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  })
)(InputBase);

export const StyledCell = withStyles({
  root: {
    fontSize: 'small',
    paddingRight: '10px'
  }
})(TableCell);
export const StyledPagination = withStyles({
  ul: {
    width: 'max-content',
    paddingLeft: '10px'
  }
})(Pagination);

export const StyledButton = withStyles({
  root: {
    marginTop: '10px',
    paddingLeft: '0',
    paddingRight: '0',
    padding: '5px',
    marginRight: '0px',
    marginLeft: '10px'
  }
})(Button);

export const StyledTablePagination = withStyles({
  root: {
    width: '100%',
    display: 'block',
    border: '0',
    paddingLeft: '0',
    paddingBottom: '10px',
    marginLeft: '0'
  },
  selectRoot: {
    border: '2px solid rgb(1,1,1,0.3)',
    borderRadius: '5px',
    marginLeft: '0',
    marginRight: '10px',
    fontSize: 'small'
  },
  caption: {
    paddingLeft: '0',
    marginLeft: '0',
    fontWeight: 'bold',
    fontSize: 'small'
  }
})(TablePagination);

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
    title: {
      flex: '1 1 100%'
    },
    width: {
      width: '100%',
      color: 'grey'
    },
    label: {
      color: 'grey',
      padding: '5px'
    },
    padding: {
      padding: '10px'
    },
    capitalize: {
      textTransform: 'capitalize'
    },
    grey: {
      color: 'grey'
    },
    dialogHead: {
      color: 'black',
      backgroundColor: 'rgba(1,1,1,0.1)',
      paddingBottom: '5px',
      fontSize: '15px'
    }
  })
);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: 0,
      padding: 0
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      border: 0,
      margin: 0,
      padding: '20px'
      // marginRight: "100px",
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  })
);
export const SaveButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: 'rgb(0, 165, 165)',
    '&:hover': {
      backgroundColor: 'rgb(0, 169, 169)'
    },
    textTransform: 'capitalize'
  }
})(Button);

export const DeleteButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'rgb(199, 1, 1)'
    },
    textTransform: 'capitalize'
  }
})(Button);

export const CloneButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: 'rgb(0, 204, 204)',
    '&:hover': {
      backgroundColor: 'rgb(0, 209, 209)'
    },
    textTransform: 'capitalize'
  }
})(Button);
