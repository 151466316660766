import { Dialog, DialogTitle, Grid, Box, Button } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

import { DialogProps, ShowFields } from './Models';
import { useStyles1 } from './styles';

export default function StyledDialog(props: DialogProps): React.ReactElement {
  const classes = useStyles1();
  const { open, onClose } = props;
  const [orderId, setOrderId] = React.useState(props.selectedValue.orderId);
  const [id, setid] = React.useState(props.selectedValue.id);
  const [tests, setTests] = React.useState(props.selectedValue.tests);
  const [externalId, setExternalId] = React.useState(props.selectedValue.externalId);
  const [labId, setLabId] = React.useState(props.selectedValue.labId);
  const [source, setSource] = React.useState(props.selectedValue.source);
  const [accessionId, setAccessionId] = React.useState(props.selectedValue.accessionId);
  const [address, setAddress] = React.useState(props.selectedValue.address);
  const [assigningAuthority, setAssigningAuthority] = React.useState(props.selectedValue.assigningAuthority);
  const [birthDate, setBirthDate] = React.useState(props.selectedValue.birthDate);
  const [city, setCity] = React.useState(props.selectedValue.city);
  const [state, setState] = React.useState(props.selectedValue.state);
  const [firstName, setFirstName] = React.useState(props.selectedValue.firstName);
  const [lastName, setLastName] = React.useState(props.selectedValue.lastName);
  const [sex, setSex] = React.useState(props.selectedValue.sex);
  const [zipCode, setZipCode] = React.useState(props.selectedValue.zipCode);
  const [confirmationId, setConfirmationId] = React.useState(props.selectedValue.confirmationId);
  const [specimanType, setSpecimanType] = React.useState(props.selectedValue.specimanType);

  const handleClose = (prop: boolean, arg?: ShowFields) => {
    onClose(arg);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="xs"
      open={open}
      fullWidth
      onClose={() => {
        handleClose(true, {
          orderId,
          id,
          externalId,
          tests,
          source,
          labId,
          accessionId,
          address,
          assigningAuthority,
          birthDate,
          firstName,
          lastName,
          sex,
          city,
          state,
          zipCode,
          confirmationId,
          specimanType
        });
      }}
    >
      <Grid className={classes.padding} justify="center" container>
        <DialogTitle className={classes.widthparent} id="simple-dialog-title">
          View
        </DialogTitle>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={id ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Sample Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={id}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setid(!id);
                  }}
                />
              </Grid>
            </Grid>

            <Grid xs={12} item>
              <Grid className={orderId ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Order ID</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={orderId}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setOrderId(!orderId);
                      setConfirmationId(!confirmationId);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={labId ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Lab ID</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={labId}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setLabId(!labId);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={externalId ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>External Id</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={externalId}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setExternalId(!externalId);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={source ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Source</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={source}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setSource(!source);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} item>
              <Grid className={tests ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Tests</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={tests}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setTests(!tests);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={accessionId ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>accessionId</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={accessionId}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setAccessionId(!accessionId);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={address ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>address</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={address}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setAddress(!address);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid
                className={assigningAuthority ? classes.cells : classes.hiddenCells}
                justify="space-between"
                container
              >
                <Grid className={classes.heading} item>
                  <b>assigningAuthority</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={assigningAuthority}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setAssigningAuthority(!assigningAuthority);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={birthDate ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>birthDate</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={birthDate}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setBirthDate(!birthDate);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={city ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>city</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={city}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setCity(!city);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={state ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>state</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={state}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setState(!state);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={zipCode ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>zipCode</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={zipCode}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setZipCode(!zipCode);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={firstName ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>firstName</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={firstName}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setFirstName(!firstName);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={lastName ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>LastName</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={lastName}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setLastName(!lastName);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={sex ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Sex</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={sex}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setSex(!sex);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <Grid className={sex ? classes.cells : classes.hiddenCells} justify="space-between" container>
                  <Grid className={classes.heading} item>
                    <b>Speciman Type</b>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={specimanType}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      name="checkedB"
                      onChange={() => {
                        setSpecimanType(!specimanType);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={function () {
                    handleClose(true, {
                      orderId,
                      id,
                      externalId,
                      tests,
                      source,
                      labId,
                      accessionId,
                      address,
                      assigningAuthority,
                      birthDate,
                      firstName,
                      lastName,
                      sex,
                      city,
                      state,
                      zipCode,
                      confirmationId,
                      specimanType
                    });
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
