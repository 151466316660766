import { Select, Button, Grid } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: "max-content",
    alignItems: "flex-start",
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: "rgb(1, 1, 68)",
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    border: "0px",
  },
  bordertop: {
    borderTop: "1px solid rgb(168, 168, 168)",
    marginTop: "8px",
    paddingTop: "15px",
    paddingBottom: "20px",
  },
  white: {
    backgroundColor: "white",
    padding: "10px",
  },
  width: {
    width: "100%",
    color: "grey",
  },
  label: {
    color: "grey",
    padding: "5px",
  },
  padding: {
    padding: "10px",
  },
  textArea: {
    paddingTop: "10px",
  },
  btn: {
    backgroundColor: "rgb(1,1,1,0.1)",
  },
  snackBar: {
    backgroundColor: "rgb(1,1,1,0.1)"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  cancel: {
    textTransform: 'capitalize'
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}));
export const StyledSelect = withStyles({
  icon: {
    fill: "white",
  },
  root: {
    color: "white",
  },
})(Select);
export const StyledButton = withStyles({
  root: {
    color: "white",
    textTransform: "capitalize"
  },
})(Button);
export const SaveButton = withStyles({
  root: {
    color: "white",
    backgroundColor: "rgb(0, 165, 165)",
    "&:hover": {
      backgroundColor: "rgb(0, 169, 169)",
    },
    textTransform: "capitalize"
  },
})(Button);
export const CloneButton = withStyles({
  root: {
    color: "white",
    backgroundColor: "rgb(0, 209, 209)",

    "&:hover": {
      backgroundColor: "rgb(0, 204, 190)",
      boxShadow: "0px 2px 5px rgb(1,1,1,0.5)",
    },
    boxShadow: "0px 1px 5px rgb(1,1,1,0.5)",
    textTransform: "capitalize"
  },
})(Button);
export const DeleteButton = withStyles({
  root: {
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "rgb(199, 1, 1)",
    },
    textTransform: "capitalize"
  },
})(Button);
export const StyledGrid = withStyles({
  root: {
    textAlign: "center",
    color: "grey",
    padding: "5px",
  },
})(Grid);
