import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/images/wl-logo.png';

type labelProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  sampleId?: any;
  createdAt?: string;
  result?: string;
  orderId?: number;
  name?: string;
  dob?: string;
  address?: string;
  sex?: string;
  ethnicity?: string;
  race?: string;
  sampleType?: string;
  reviewedDate?: string;
  lastName?: string;
};

const Table = styled.table`
  border: 0.5px solid black;
  border-collapse: collapse;

  width: 475px;

  padding: 20px;
`;

const Th = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  text-align: start;
  padding: 6px;
  font-size: 12px;
  font-weight: bold;
  width: 50%;
`;

const Ths = styled.th`
  border: 1px solid black;
  border-collapse: collapse;
  text-align: start;
  padding: 3px;
  font-size: 21px;
  font-weight: bold;
  width: 50%;
`;
const Tr = styled.tr``;

const HawaiiReport: React.FC<labelProps> = ({
  sampleId,
  createdAt,
  result,

  name,
  dob,

  lastName
}) => {
  return (
    <div
      style={{
        margin: '0',
        padding: '40px 70px',
        background: 'white',
        width: '595px',
        height: '842px'
      }}
    >
      <div style={{ padding: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
          <img alt="" src="" />
          <img alt="WSL-Logo" src={logo} style={{ width: '170px', height: '40px' }} />
        </div>
        <br />
        <div style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'bold' }}>
          <p>State of Hawai'i Safe Travels Program</p>
          <p style={{ marginTop: '-13px' }}>Trusted Testing Partner COVID-19 Test Result Reporting Form </p>
        </div>
        <div>
          <Table>
            <Tr>
              <Th>Trusted Testing Partner</Th>
              <Th>Worksite Labs</Th>
            </Tr>
            <Tr>
              <Th>Name of Lab/Site Performing Test </Th>
              <Th>QDx Pathology</Th>
            </Tr>
            <Tr>
              <Th>Address of Lab</Th>
              <Th>300 Columbus Circle Suite A., Edison NJ 08837</Th>
            </Tr>
            <Tr>
              <Th>Lab Telephone</Th>
              <Th>+1 833-747-1122</Th>
            </Tr>
            <Tr>
              <Th>CLIA Number/Waiver</Th>
              <Th>
                <span
                  style={{
                    marginBottom: '5px',
                    textAlign: 'center',
                    background: '#FFF192',
                    padding: '3px'
                  }}
                >
                  10D2126590
                </span>
              </Th>
            </Tr>
          </Table>
        </div>
        <br />
        <div>
          <h3 style={{ textDecoration: 'underline' }}>Traveler Information:</h3>
          <Table>
            <Tr>
              <Th>Last Name</Th>
              <Th>{lastName}</Th>
            </Tr>
            <Tr>
              <Th>First Name</Th>
              <Th>{name}</Th>
            </Tr>
            <Tr>
              <Th>Date of Birth (mm/dd/yyyy)</Th>
              <Th>{dob}</Th>
            </Tr>
          </Table>
        </div>
        <br />
        <div>
          <h3 style={{ textDecoration: 'underline' }}>Test Information:</h3>
          <Table>
            <Tr>
              <Th>Test Performed</Th>
              <Th>
                <span
                  style={{
                    marginBottom: '5px',
                    textAlign: 'center',
                    background: '#FFF192',
                    padding: '3px'
                  }}
                >
                  LumiraDx SARS-CoV-2 RNA STAR NAAT RTPCR Detection
                </span>
              </Th>
            </Tr>
            <Tr>
              <Th>Sample Collection Date </Th>
              <Th>{createdAt}</Th>
            </Tr>
            <Tr>
              <Th>
                Sample Reference ID# <br />
                Or
                <br /> Unique Test Result ID#
              </Th>
              <Th>{sampleId}</Th>
            </Tr>
          </Table>
        </div>
        <br />
        <div>
          <Table>
            <Tr>
              <Ths>Test Result:</Ths>
              <Ths>{result}</Ths>
            </Tr>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default HawaiiReport;
