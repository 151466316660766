import { Button, TablePagination } from '@material-ui/core';
import { makeStyles, withStyles, createStyles, lighten, Theme } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    toolbar: {
        minHeight: 'max-content',
        alignItems: 'flex-start',
        paddingTop: '5px',
        paddingBottom: '5px',
        backgroundColor: 'rgb(1, 1, 68)'
    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        border: '0px'
    },
    bordertop: {
        borderTop: '1px solid rgb(168, 168, 168)',
        marginTop: '8px',
        paddingTop: '15px',
        paddingBottom: '20px'
    },
    white: {
        backgroundColor: 'white',
        padding: '10px'
    },
    width: {
        width: '100%',
        color: 'grey',
        fontSize: 'small'
    },
    label: {
        color: 'grey',
        padding: '5px'
    },
    padding: {
        padding: '10px'
    },
    textArea: {
        paddingTop: '10px',
        fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans- serif"
    },
    btn: {
        backgroundColor: 'rgb(1,1,1,0.1)'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    grey: {
        color: 'grey'
    },
    widthparent: {
        width: 'max-content'
    },
    list: {
        backgroundColor: "rgba(216, 216, 216, 0.356)",
        borderRadius: "5px",
        textTransform: 'capitalize'
    }
}));

export const SaveButton = withStyles({
    root: {
        color: 'white',
        backgroundColor: 'rgb(0, 165, 165)',
        '&:hover': {
            backgroundColor: 'rgb(0, 169, 169)'
        },
        textTransform: 'capitalize'
    }
})(Button);

export const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.primary.main,
                    backgroundColor: lighten(theme.palette.primary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.primary.dark,
                },
        title: {
            flex: '1 1 100%',
        },
    }),
);

export const useStylesTable = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        table: {

        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }),
);
export const StyledTablePagination = withStyles({
    root: {
        width: '100%',
        display: 'block',
        border: '0',
        paddingLeft: '0',
        paddingBottom: '10px',
        marginLeft: '0'
    },
    selectRoot: {
        border: '2px solid rgb(1,1,1,0.3)',
        borderRadius: '5px',
        marginLeft: '0',
        marginRight: '10px',
        fontSize: 'small'
    },
    caption: {
        paddingLeft: '0',
        marginLeft: '0',
        fontWeight: 'bold',
        fontSize: 'small'
    }
})(TablePagination);

export const StyledPagination = withStyles({
    ul: {
        width: 'max-content',
        paddingLeft: '10px'
    }
})(Pagination);