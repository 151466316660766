import React from 'react';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Container,
  MenuItem,
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  TextField,
  FormControl,
  Divider,
  Select
} from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import { FaDownload, FaFilter } from 'react-icons/fa';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import AppsIcon from '@material-ui/icons/Apps';
import EditIcon from '@material-ui/icons/Edit';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';

import ReactExport from './Export.d';
import { fetchData, multipleDeletes, updateMany } from './api';
import { getComparator, stableSort, menuItems, dropdown, dropdownApi } from './utilities';
import {
  useStyles1,
  useToolbarStyles,
  useStyles,
  StyledCell,
  StyledPagination,
  StyledButton,
  StyledTablePagination
} from './styles';
import {
  Data,
  EnhancedTableProps,
  HeadCell,
  EnhancedTableToolbarProps,
  ShowFields,
  Export,
  SimpleDialogProps,
  Toast,
  Filter,
  Body,
  UpdateMenuProps,
  UpdateInputProps,
  UpdateValues,
  SetUpdateOptions
} from './Models';
import StyledDialog from './StyledDialog';
import FilterDialog from './FilterDialog';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Order = 'asc' | 'desc';

type Params = {
  status: string;
  techId?: string;
};

const fileExport: Export[] = [];
const headCells: HeadCell[] = [
  { id: 'orderId', numeric: false, disablePadding: true, label: 'Order Id' },
  {
    id: 'orderStatus',
    numeric: false,
    disablePadding: false,
    label: 'Order Status'
  },
  { id: 'sampleId', numeric: false, disablePadding: false, label: 'Sample Id' },
  {
    id: 'sampleReport',
    numeric: false,
    disablePadding: false,
    label: 'Sample Report'
  },
  { id: 'id', numeric: false, disablePadding: false, label: 'Test Id' },
  { id: 'testStatus', numeric: false, disablePadding: false, label: 'Test Status' },
  { id: 'assayId', numeric: false, disablePadding: false, label: 'Assay Id' },
  { id: 'technician', numeric: false, disablePadding: false, label: 'Technician' },
  { id: 'startDate', numeric: false, disablePadding: false, label: 'Start Date' },
  { id: 'completeDate', numeric: false, disablePadding: false, label: 'Complete Date' },
  { id: 'results', numeric: false, disablePadding: false, label: 'Results' },
  { id: 'testReport', numeric: false, disablePadding: false, label: 'Test Report' }
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes1 = useStyles1();
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, fields } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledCell padding="checkbox">
          <Box pl={0} pr={10}>
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              indeterminate={numSelected > 0 && rowCount > numSelected}
              inputProps={{ 'aria-label': 'select all desserts' }}
              onChange={onSelectAllClick}
            />
          </Box>
        </StyledCell>
        {headCells.map(
          (headCell) =>
            fields[headCell.id] && (
              <StyledCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <th className={classes1.heading}>{headCell.label}</th>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

const UpdateInput = (props: UpdateInputProps) => {
  const { update, setUpdate } = props;
  const classes = useToolbarStyles();
  const [loading, setLoading] = React.useState(false);
  const [fetchedDropdown, setFetchedDropdown] = React.useState<{ label: string; value: string }[]>([
    {
      label: '',
      value: ''
    }
  ]);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: ''
  });

  const index: string = update.dropdown.values;

  function Alert(prop: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...prop} />;
  }

  const handleClose2 = () => {
    setToast({ ...toast, open: false });
  };

  React.useEffect(() => {
    if (update.dropdown.open && !dropdown[index]) {
      setLoading(true);
      dropdownApi[index]()
        .then(async (response: Response) => {
          setLoading(false);
          const data = await response.json();

          if (data.status === 200) {
            const tmp: { label: string; value: string }[] = [];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.data.forEach((d: any) => {
              tmp.push({
                label: d.userName,
                value: d.id
              });
            });

            setFetchedDropdown([...tmp]);
          }
        })
        .catch((err: Error) => {
          setLoading(false);
          setToast({ open: true, severity: 0, message: err.message });
        });
    }
  }, []);

  return (
    <Grid className={classes.padding} xs={12} item>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={toast.open} onClose={handleClose2}>
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose2}>
          {toast.message}
        </Alert>
      </Snackbar>
      <b className={clsx(classes.label, classes.capitalize)}>{update.field}</b>
      {!update.dropdown.open ? (
        <TextField
          className={classes.width}
          color="primary"
          placeholder={update.field}
          size="small"
          type={update.date ? 'date' : 'text'}
          value={update.date ? moment(update.fieldValue).format('YYYY-MM-DD') : update.fieldValue}
          variant="outlined"
          fullWidth
          required
          onChange={(event) => {
            setUpdate(update.field, event.target.value, {
              date: update.date,
              dropdown: update.dropdown
            });
          }}
        />
      ) : dropdown[index] ? (
        <FormControl className={classes.width} size="small">
          <Select
            value={update.fieldValue}
            variant="outlined"
            required
            onChange={(event) => {
              setUpdate(update.field, event.target.value as string, {
                date: update.date,
                dropdown: update.dropdown
              });
            }}
          >
            {dropdown[index].map((item: { value: string; label: string }) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <FormControl className={classes.width} size="small">
          {loading ? (
            <CircularProgress size={25} />
          ) : (
            <Autocomplete
              getOptionLabel={(option) => option.label}
              id="controllable-states-demo"
              options={fetchedDropdown}
              renderInput={(params) => <TextField {...params} size="small" variant="outlined" required />}
              style={{ width: 100 + '%' }}
              onChange={(event, newValue: { label: string; value: string } | null) => {
                if (newValue) {
                  setUpdate(update.field, `${newValue.value}`, {
                    date: update.date,
                    dropdown: update.dropdown
                  });
                }
              }}
            />
          )}
        </FormControl>
      )}
    </Grid>
  );
};

const UpdateMenu = (props: UpdateMenuProps) => {
  const { handleDialog, setUpdate } = props;

  return (
    <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
      {menuItems.map((item) => {
        return (
          <>
            <TreeItem key={item.label} label={item.label} nodeId={item.label}>
              {item.children.map((child) => {
                return (
                  <>
                    <br />
                    <TreeItem
                      label={child.label}
                      nodeId={child.label}
                      onClick={() => {
                        handleDialog(true, true, false);
                        setUpdate(child.value, '', {
                          date: child.date,
                          dropdown: {
                            open: child.dropdown,
                            values: child.value
                          }
                        });
                      }}
                    />
                    <Divider />
                  </>
                );
              })}
            </TreeItem>
            <br />
          </>
        );
      })}
    </TreeView>
  );
};

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, setDelToast } = props;
  const [loader, setLoader] = React.useState<{
    update: boolean;
    delete: boolean;
  }>({
    update: false,
    delete: false
  });
  const [dialogOpen, setDialogOpen] = React.useState<{ show: boolean; update: boolean; updateMenu: boolean }>({
    show: false,
    update: false,
    updateMenu: false
  });
  const [update, setUpdate] = React.useState<UpdateValues>({
    field: '',
    fieldValue: '',
    date: false,
    dropdown: {
      open: false,
      values: ''
    }
  });
  const handleClickDialogOpen = (show: boolean, update: boolean, updateMenu: boolean) => {
    setDialogOpen({ ...dialogOpen, show, update, updateMenu });
  };

  const handleDialogClose = () => {
    setDialogOpen({ ...dialogOpen, show: false, update: false, updateMenu: false });
  };

  const handleSetUpdate = (field: string, fieldValue: string, options?: SetUpdateOptions) => {
    let date = false;
    const dropdown: { open: boolean; values: string } = {
      open: false,
      values: ''
    };

    if (options && options.date) {
      date = options.date;
    }

    if (options && options.dropdown) {
      dropdown.open = options.dropdown.open;
      dropdown.values = options.dropdown.values;
    }

    setUpdate({ ...update, field, fieldValue, date, dropdown });
  };

  const handleLoader = (update: boolean, del: boolean) => {
    setLoader({
      update,
      delete: del
    });
  };

  const handleMultipleDeletes = () => {
    handleLoader(false, true);
    handleDialogClose();
    multipleDeletes(selected)
      .then(async (response: Response) => {
        handleLoader(false, false);
        const data = await response.json();

        if (data.status === 200) {
          setDelToast(data.message, 1);
        } else {
          setDelToast(data.message, 0);
        }
      })
      .catch((err: Error) => {
        handleLoader(false, false);
        setDelToast(err.message, 0);
      });
  };

  const handleUpdateMany = () => {
    if (update.fieldValue.length) {
      handleLoader(true, false);
      handleDialogClose();
      updateMany(selected, {
        [update.field]: [update.fieldValue]
      })
        .then(async (response: Response) => {
          handleLoader(false, false);
          const data = await response.json();

          if (data.status === 200) {
            setDelToast(data.message, 1);
          } else {
            setDelToast(data.message, 0);
          }
        })
        .catch((err: Error) => {
          handleLoader(false, false);
          setDelToast(err.message, 0);
        });
    }
  };

  return numSelected > 0 ? (
    <Box pb={5}>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 && (
          <Typography className={classes.title} color="inherit" component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}

        {numSelected > 0 && (
          <>
            <Tooltip title="Actions">
              {loader.update ? (
                <CircularProgress />
              ) : (
                <IconButton
                  aria-label="Edit"
                  size="medium"
                  onClick={() => {
                    handleClickDialogOpen(true, true, true);
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
            </Tooltip>
            <Tooltip title="Delete">
              {loader.delete ? (
                <CircularProgress />
              ) : (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleClickDialogOpen(true, false, false);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Tooltip>
          </>
        )}
      </Toolbar>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        maxWidth="xs"
        open={dialogOpen.show}
        fullWidth
        onClose={handleDialogClose}
      >
        <DialogTitle className={classes.dialogHead} id="alert-dialog-title" disableTypography>
          <Grid justify="space-between" container>
            <Grid item>{dialogOpen.update ? `Update selected` : `Delete selected`}</Grid>
            <Grid item>
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogOpen.update ? (
              <Box p={3} pl={0}>
                {dialogOpen.updateMenu ? (
                  <UpdateMenu handleDialog={handleClickDialogOpen} setUpdate={handleSetUpdate} />
                ) : (
                  <UpdateInput setUpdate={handleSetUpdate} update={update} />
                )}
              </Box>
            ) : (
              `Are you sure you want to delete the selected ${numSelected} rows`
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleDialogClose}>
            Cancel
          </Button>
          {!dialogOpen.update && (
            <Button className={classes.capitalize} color="secondary" autoFocus onClick={handleMultipleDeletes}>
              Delete
            </Button>
          )}
          {dialogOpen.update && !dialogOpen.updateMenu && (
            <Button
              className={classes.capitalize}
              color="secondary"
              disabled={update.fieldValue.length ? false : true}
              autoFocus
              onClick={handleUpdateMany}
            >
              Update {update.field}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  ) : (
    <></>
  );
};

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const datas = [
    {
      title: 'ABCD',
      method: 'X-Y-Z',
      category: '',
      technician: ''
    },
    {
      title: 'EFGH',
      method: 'X-Y-Q',
      category: '',
      technician: ''
    },
    {
      title: 'IJKL',
      method: 'X-Y-P',
      category: '',
      technician: ''
    },
    {
      title: 'MNOP',
      method: 'X-Y-R',
      category: '',
      technician: ''
    }
  ];

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
      <DialogTitle id="simple-dialog-title">Exports the Data</DialogTitle>
      <List>
        <ListItem>
          <ExcelFile
            element={
              <button>
                <MenuItem>Export All Data</MenuItem>
              </button>
            }
          >
            <ExcelSheet data={datas} name="TEST">
              <ExcelColumn label="Title" value="title" />
              <ExcelColumn label="Methods" value="method" />
              <ExcelColumn label="Category" value="category" />
              <ExcelColumn label="Category" value="technician" />
            </ExcelSheet>
          </ExcelFile>
        </ListItem>
        <ListItem>
          <ExcelFile
            element={
              <button>
                <MenuItem>Export Current Data</MenuItem>
              </button>
            }
          >
            <ExcelSheet data={fileExport} name="TEST">
              <ExcelColumn label="Order ID" value="orderId" />
              <ExcelColumn label="Order Status" value="orderStatus" />
              <ExcelColumn label="Sample ID" value="sampleId" />
              <ExcelColumn label="Sample Report" value="sampleReport" />
              <ExcelColumn label="Test ID" value="TestId" />
              <ExcelColumn label="Test Status" value="testStatus" />
              <ExcelColumn label="Assay" value="assayTitle" />
              <ExcelColumn label="Assay ID" value="assayId" />
              <ExcelColumn label="Technician" value="technician" />
              <ExcelColumn label="Start Date" value="startDate" />
              <ExcelColumn label="Complete Date" value="completeDate" />
              <ExcelColumn label="Results" value="results" />
              <ExcelColumn label="Test Report" value="testReport" />
            </ExcelSheet>
          </ExcelFile>
        </ListItem>
      </List>
    </Dialog>
  );
}

export default function EnhancedTable(): React.ReactElement {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);

  const classes1 = useStyles1();
  const [orderId, setOrderId] = React.useState(true);
  const [orderStatus, setOrderStatus] = React.useState(true);
  const [sampleId, setSampleId] = React.useState(true);
  const [sampleReport, setSampleReport] = React.useState(true);
  const [id, setId] = React.useState(true);
  const [testStatus, setTestStatus] = React.useState(true);
  const [assayTitle] = React.useState(true);
  const [assayId, setAssayId] = React.useState(true);
  const [technician, setTechnician] = React.useState(true);
  const [startDate, setStartDate] = React.useState(true);
  const [completeDate, setCompleteDate] = React.useState(true);
  const [results, setResults] = React.useState(true);
  const [testReport, setTestReport] = React.useState(true);

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [opens] = React.useState(false);
  const [rows, setRows] = React.useState<Data[]>([]);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: ''
  });
  const [loader, setLoader] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [successDelete, setSuccessDelete] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [filter, setFilter] = React.useState<Filter>({
    open: false,
    values: {
      orderId: '',
      orderStatus: '',
      sampleId: '',
      id: '',
      testStatus: '',
      assayTitle: '',
      assayId: '',
      technician: '',
      results: ''
    }
  });
  const [filter_body, setFilter_Body] = React.useState<Body[]>([]);

  const params = useParams<Params>();

  const handleCloseFilter = (args?: Export) => {
    if (args) {
      setFilter({ open: false, values: args });
      const keys = Object.keys(args);
      const tmp_filter_body = [];

      for (let i = 0; i < keys.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value: string = (args as any)[keys[i]];

        if (value.length) {
          tmp_filter_body.push({
            key: keys[i],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value
          });
        }
      }

      setFilter_Body(tmp_filter_body);
    }
    setFilter({ ...filter, open: false });
  };

  const handleOpenFilter = () => {
    setFilter({ ...filter, open: true });
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose2 = () => {
    setToast({ ...toast, open: false });
  };

  React.useEffect(() => {
    setLoader(true);

    if (params.status) {
      let filterBody: { key: string; value: string | string[] }[] = [];

      filterBody = [
        {
          key: 'testStatus',
          value: params.status === ':INPROGRESS' ? 'INPROGRESS' : ['NOT STARTED', 'CREATED']
        }
      ];

      if (params.techId) {
        filterBody.push({ key: 'technician', value: params.techId });
      }

      fetchData(
        rowsPerPage,
        page + 1,
        filter_body
          ? [
              ...filter_body,
              { key: 'testStatus', value: params.status === 'INPROGRESS' ? 'INPROGRESS' : 'NOT STARTED' },
              { key: 'technician', value: params.techId ? params.techId : '' }
            ]
          : filterBody
      )
        .then(async (response: Response) => {
          const data = await response.json();
          setLoader(false);

          if (data.status === 200) {
            setCount(data.data.count);
            setRows(data.data.list);

            if (data.data.list.length === 0) {
              setToast({ open: true, severity: 0, message: 'No Data Found' });
            }
          } else {
            setToast({ open: true, severity: 0, message: data.message });
          }
        })
        .catch((err: Error) => {
          setToast({ open: true, severity: 0, message: err.message });
        });
    } else {
      fetchData(rowsPerPage, page + 1, filter_body)
        .then(async (response: Response) => {
          const data = await response.json();
          setLoader(false);

          if (data.status === 200) {
            setCount(data.data.count);
            setRows(data.data.list);

            if (data.data.list.length === 0) {
              setToast({ open: true, severity: 0, message: 'No Data Found' });
            }
          } else {
            setToast({ open: true, severity: 0, message: data.message });
          }
        })
        .catch((err: Error) => {
          setToast({ open: true, severity: 0, message: err.message });
        });
    }
  }, [page, rowsPerPage, successDelete, filter_body, params.status]);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = (value: string) => {
    setOpen1(false);
    setSelectedValue(value);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && opens === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = opens;
  }, [opens]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (arg?: ShowFields) => {
    if (arg) {
      setOrderId(arg.orderId);
      setOrderStatus(arg.orderStatus);
      setSampleId(arg.sampleId);
      setSampleReport(arg.sampleReport);
      setId(arg.id);
      setTestStatus(arg.testStatus);
      setAssayId(arg.assayId);
      setTechnician(arg.technician);
      setStartDate(arg.startDate);
      setCompleteDate(arg.completeDate);
      setResults(arg.results);
      setTestReport(arg.testReport);
    }
    setOpen(false);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const fields: ShowFields = {
    orderId,
    orderStatus,
    sampleId,
    sampleReport,
    id,
    testStatus,
    assayTitle,
    assayId,
    technician,
    startDate,
    completeDate,
    results,
    testReport
  };

  return (
    <Container>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={toast.open} onClose={handleClose2}>
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose2}>
          {toast.message}
        </Alert>
      </Snackbar>
      <div>
        <Paper className={classes.paper} elevation={5}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            setDelToast={(message: string, severity: number) => {
              setToast({ open: true, message, severity });

              if (severity) {
                setSuccessDelete(!successDelete);
                setSelected([]);
              }
            }}
          />
          <Grid justify="space-between" container>
            <Grid md={3} xs={12} item>
              <Grid justify="space-between" container>
                <Grid item />
              </Grid>
            </Grid>
            <Grid className={classes1.widthMaxContent} item>
              <Grid container>
                <Grid className={classes1.widthMaxContent} item>
                  <Tooltip placement="top" title="View Menu">
                    <StyledButton
                      className={classes1.info}
                      color="primary"
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      <AppsIcon />
                    </StyledButton>
                  </Tooltip>
                  <StyledDialog
                    open={open}
                    selectedValue={{
                      orderId,
                      orderStatus,
                      sampleId,
                      sampleReport,
                      id,
                      testStatus,
                      assayId,
                      technician,
                      startDate,
                      completeDate,
                      results,
                      testReport
                    }}
                    onClose={handleClose}
                  />
                  <Tooltip placement="top" title="Export">
                    <StyledButton className={classes1.btnStyle} color="primary" variant="contained">
                      <FaDownload onClick={handleClickOpen1} />
                    </StyledButton>
                  </Tooltip>
                  <SimpleDialog open={open1} selectedValue={selectedValue} onClose={handleClose1} />
                </Grid>
                <Tooltip placement="top" title="Filter">
                  <StyledButton
                    className={classes1.filterBtn}
                    color="primary"
                    variant="contained"
                    onClick={handleOpenFilter}
                  >
                    <FaFilter />
                  </StyledButton>
                </Tooltip>
                <FilterDialog filterOpen={filter.open} filterValues={filter.values} onFilterClose={handleCloseFilter} />

                <Grid className={classes1.widthMaxContent} item>
                  <StyledTablePagination
                    ActionsComponent={() => {
                      return (
                        <Grid direction="row" container>
                          <Grid item>
                            <StyledPagination
                              boundaryCount={1}
                              className={classes1.widthparent}
                              color="primary"
                              count={Math.ceil(count / rowsPerPage)}
                              page={page + 1}
                              shape="rounded"
                              siblingCount={0}
                              variant="outlined"
                              showFirstButton
                              showLastButton
                              onChange={(e, p) => {
                                handleChangePage(e, p - 1);
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                    className={classes1.widthparent}
                    count={count}
                    labelRowsPerPage=""
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 50, 100]}
                    // onChangePage={handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table
              aria-label="enhanced table"
              aria-labelledby="tableTitle"
              className={classes.table}
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                classes={classes}
                fields={fields}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                rowCount={rows.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              {!loader && (
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    fileExport.push(row);

                    return (
                      <TableRow
                        key={row.id}
                        aria-checked={isItemSelected}
                        className={classes1.selected}
                        role="checkbox"
                        tabIndex={-1}
                        hover
                      >
                        <StyledCell className={classes1.grey} padding="checkbox">
                          <Box pl={0} pr={10}>
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </Box>
                        </StyledCell>
                        {orderId && (
                          <StyledCell className={classes1.grey}>
                            <Link to={'/center/samples/orderDetail/' + row.orderId}>
                              <Box className={row.orderId ? classes1.border : ''}>{row.orderId}</Box>
                            </Link>
                          </StyledCell>
                        )}
                        {orderStatus && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Box
                              className={
                                row.orderStatus
                                  ? row.orderStatus === 'IN PROGRESS'
                                    ? classes1.borderRed
                                    : classes1.borderViolet
                                  : ''
                              }
                            >
                              {row.orderStatus}
                            </Box>
                          </StyledCell>
                        )}
                        {sampleId && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Link to={'/center/samples/sampleDetail/' + row.sampleId}>
                              <Box className={classes1.border}>{row.sampleId}</Box>
                            </Link>
                          </StyledCell>
                        )}
                        {sampleReport && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.sampleReport}
                          </StyledCell>
                        )}
                        {id && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Link to={'/center/samples/testDetail/' + row.id}>
                              <Box className={classes1.border}>{row.id}</Box>
                            </Link>
                          </StyledCell>
                        )}
                        {testStatus && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Box
                              className={
                                row.testStatus === 'COMPLETED'
                                  ? classes1.borderGreen
                                  : row.testStatus === 'CANCELLED'
                                  ? classes1.borderRed
                                  : row.testStatus === 'NOT STARTED'
                                  ? classes1.borderYellow
                                  : row.testStatus === 'CREATED'
                                  ? classes1.borderYellow
                                  : classes1.borderSkyblue
                              }
                            >
                              {row.testStatus}
                            </Box>
                          </StyledCell>
                        )}
                        {assayId && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Box className={classes1.border}>
                              {' '}
                              {row.assayId}&nbsp;-&nbsp;{row.assayTitle}
                            </Box>
                          </StyledCell>
                        )}
                        {technician && (
                          <StyledCell align="center" className={classes1.grey}>
                            <Box className={row.technician ? classes1.border : ''}>{row.technician}</Box>
                          </StyledCell>
                        )}
                        {startDate && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Box> {moment(row.startDate).format('DD-MM-YYYY,h:mm a')}</Box>
                          </StyledCell>
                        )}
                        {completeDate && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Box> {moment(row.completeDate).format('DD-MM-YYYY,h:mm a')}</Box>
                          </StyledCell>
                        )}
                        {results && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Box> {row.results}</Box>
                          </StyledCell>
                        )}
                        {testReport && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Box> {row.testReport}</Box>
                          </StyledCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            <div hidden>
              <FormControlLabel control={<Switch checked={dense} color="primary" />} label="Dense padding" />
            </div>
          </TableContainer>
        </Paper>
        {loader && (
          <Grid xs={12}>
            <Grid justify="center" container>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
}
