import React from 'react';
import clsx from 'clsx';
import { Switch, Route, BrowserRouter, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Settings from '@material-ui/icons/Settings';

import './styles.scss';
import DashboardPage from '../../pages/DashboardPage';
import SamplePage from '../../pages/SamplePage';
import TestingPage from '../../pages/TestingPage';
import OrdersPage from '../../pages/OrdersPage';
import LocationsPage from '../../pages/LocationsPage';
import TestTypePage from '../../pages/TestTypePage';
import BatchesPage from '../../pages/Batches';
import Japanese from '../../pages/Report/Japanese';
import CollectionMethods from '../../pages/Settings/CollectionMethods';
import TestDetail from '../../pages/Orders/TestDetails';
import OrderDetail from '../../pages/Orders/OrderForms';
import TestTypeDetail from '../../pages/Orders/TestTypeForm';
import BatchDetail from '../../pages/Orders/BatchForm';
import LocationDetail from '../../pages/Orders/CustomerLocationForm/CustomerLocation';
import Logo from '../../assets/images/logo.png';
import SampleDetail from '../../pages/Orders/SampleForm/NewSample';
import ScrollableTabsButtonAuto from '../../pages/Settings/FieldAndDataTypes';

import AppMenuItem from './AppMenuItem';
import AppMenu from './AppMenu';

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeIn,
      duration: '0.5s'
    }),
    background: '#5D9CEC',
    overflow: 'hidden',
    boxShadow: '0px 0px 20px rgb(119, 119, 119)'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeIn,
      duration: '0.5s'
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    },
    background: '#5D9CEC',
    overflow: 'hidden',
    boxShadow: '0px 0px 20px rgb(119, 119, 119)'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

const MiniDrawer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutHandler = () => {
    localStorage.clear();
    history.push('/');
  };

  return (
    <BrowserRouter>
      <div className={clsx('App', classes.root, 'noprint')}>
        <CssBaseline />
        <div>
          <Drawer
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
            variant="permanent"
            onMouseEnter={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
          >
            <img alt="Logo" className="wsl-logo" src={Logo} />
            <AppMenu />
            <div className="settings">
              <AppMenuItem
                Icon={Settings}
                items={[
                  {
                    name: 'Collection Methods',
                    small: true,
                    link: '/center/settings/collection_methods'
                  },
                  {
                    name: 'Field And Data Settings',
                    small: true,
                    link: '/center/settings/field_and_data_type'
                  }
                ]}
                name="Settings"
              />
            </div>
            <div className="logout-wrapper" onClick={() => logoutHandler()}>
              <AppMenuItem Icon={ExitToApp} name="Logout" />
            </div>
          </Drawer>
        </div>
        <main className={classes.content}>
          <Container className={classes.container} maxWidth="lg">
            <Switch>
              <Route component={DashboardPage} path="/center/dashboard" exact />
              <Route component={SamplePage} path="/center/samples" exact />
              <Route component={TestingPage} path="/center/testing" exact />
              <Route component={OrdersPage} path="/center/orders" exact />
              <Route component={LocationsPage} path="/center/customers" exact />
              <Route component={TestTypePage} path="/center/assay" exact />
              <Route component={BatchesPage} path="/center/batchs" exact />
              <Route component={Japanese} path="/center/report/japanese" exact />

              {/* <Route component={SettingsPage} path="/center/settings" exact/> */}
              <Route component={TestDetail} path="/center/samples/testDetail" exact />
              <Route component={OrderDetail} path="/center/samples/orderDetail" exact />
              <Route component={SampleDetail} path="/center/samples/sampleDetail" exact />
              <Route component={LocationDetail} path="/center/samples/customerDetail" exact />
              <Route component={TestDetail} path="/center/samples/testDetail/:id" exact />
              <Route component={TestDetail} path="/center/samples/testDetail/sampleId/:sampleId" exact />
              <Route component={TestingPage} path="/center/testings/status/:status" exact />
              <Route component={TestingPage} path="/center/testings/status/:status/:techId" exact />
              <Route component={OrderDetail} path="/center/samples/orderDetail/:id" exact />
              {/* <Route component={OrderDetail} path="/center/samples/orderDetail/customerDetail/:customerId" exact /> */}
              <Route component={SampleDetail} path="/center/samples/sampleDetail/:id" exact />
              <Route component={SampleDetail} path="/center/samples/sampleDetail/orderId/:orderId/:travelDest" exact />
              <Route component={LocationDetail} path="/center/samples/customerDetail/:id" exact />
              <Route component={TestTypeDetail} path="/center/samples/assayDetail" exact />
              <Route component={TestTypeDetail} path="/center/samples/assayDetail/:id" exact />
              <Route component={CollectionMethods} path="/center/settings/collection_methods" exact />
              <Route component={BatchDetail} path="/center/batch/batchDetail" exact />
              <Route component={BatchDetail} path="/center/batch/batchDetail/:id" exact />
              <Route component={ScrollableTabsButtonAuto} path="/center/settings/field_and_data_type" exact />
            </Switch>
          </Container>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default MiniDrawer;
