import React from 'react';

const Footer: React.FC = () => {
  return (
    <div className="row text-center">
      <a className="footer-links" href="/">
        About WorkSiteLabs for Diagnostics
      </a>
      <a className="footer-links" href="/">
        WorkSiteLabs in Press
      </a>
      <a className="footer-links" href="/">
        Terms & Conditions
      </a>
      <a className="footer-links" href="/">
        Privacy Policy
      </a>
      <a className="footer-links" href="/">
        Version 1.0
      </a>
    </div>
  );
};

export default Footer;
