import { Dialog, DialogTitle, Grid, Box, TextField } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { FilterProps, Export } from './Models';
import { useStyles1, SaveButton, DeleteButton, CloneButton } from './styles';

export default function FilterDialog(props: FilterProps): React.ReactElement {
  const classes = useStyles1();

  const { filterOpen, filterValues, onFilterClose } = props;

  const [filters, setFilters] = React.useState<Export>({
    id: filterValues.id,
    name: filterValues.name,
    description: filterValues.description,
    sampleCount: filterValues.sampleCount,
    createdAt: filterValues.createdAt
  });

  const [state, setState] = React.useState(false);

  const [able, setAble] = React.useState(false);

  let count = 1;

  const handleClose = (prop: boolean) => {
    if (prop) {
      onFilterClose({ ...filters });
    }
    onFilterClose();
  };

  const handleCloses = (propses: boolean) => {
    if (propses) {
      onFilterClose({
        id: '',
        name: '',
        description: '',
        sampleCount: '',
        createdAt: ''
      });
    }
    onFilterClose();
  };

  const close = () => {
    handleClose(true);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
      open={filterOpen}
      fullWidth
      onClose={() => {
        handleClose(false);
      }}
    >
      <Box p={4}>
        <div className={classes.widthparents}>
          <DialogTitle className={classes.widthparent} id="simple-dialog-title">
            Filters
          </DialogTitle>
          <div className={classes.close} onClick={() => handleClose(false)}>
            <CloseIcon />
          </div>
        </div>
        <form
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
            handleClose(true);
          }}
        >
          <Grid container>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Id"
                size="small"
                value={filters.id}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.id = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}> Name</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Name"
                size="small"
                value={filters.name}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.name = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Description</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Description"
                size="small"
                value={filters.description}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.description = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Sample Count</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Sample Count"
                size="small"
                value={filters.sampleCount}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.sampleCount = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Created At</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Created At"
                size="small"
                type="number"
                value={filters.createdAt}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.createdAt = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} container>
              <Box mr={2}>
                <SaveButton type="submit" variant="contained">
                  Apply Filters
                </SaveButton>
              </Box>
              <Box mr={2}>
                <DeleteButton
                  onClick={() => {
                    setFilters({
                      id: '',
                      name: '',
                      description: '',
                      sampleCount: '',
                      createdAt: ''
                    });
                    setState(able);
                  }}
                >
                  Clear Filter
                </DeleteButton>
              </Box>
              <Box mr={2}>
                <CloneButton
                  onClick={() => {
                    setFilters({
                      id: '',
                      name: '',
                      description: '',
                      sampleCount: '',
                      createdAt: ''
                    });
                    setState(able);
                    handleCloses(true);

                    if (count !== 1) {
                      if (!state) {
                        close();
                      }
                      count++;
                    }
                  }}
                >
                  Reset Filter
                </CloneButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}
