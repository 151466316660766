const config = {
  // baseUrl: 'http://localhost:5001/wsl-lims-dev/us-central1/', // for local
  baseUrl: 'https://us-central1-wsl-lims-dev.cloudfunctions.net/', // for dev
  apiURL: {
    userLogin: 'user-login',
    userDropdown: 'user-dropdown',
    userAdd: 'user-add',
    userGetAccessToken: 'user-getAccessToken',
    userForgetPassword: 'user-forgetPasswordUrl',
    userResetPassword: 'user-resetPassword',
    locationDropdown: 'location-dropdown',
    customerList: 'customer-list',
    customerDetails: 'customer-details',
    customerAdd: 'customer-add',
    customerModify: 'customer-modify',
    customerDeleteById: 'customer-deleteById',
    customerDropdown: 'customer-dropdown',
    sampleList: 'sample-list',
    sampleAdd: 'sample-add',
    sampleModify: 'sample-modify',
    sampleDetails: 'sample-details',
    sampleDeleteById: 'sample-deleteById',
    sampleUpdateMany: 'sample-updateMany',
    orderAdd: 'order-add',
    orderList: 'order-list',
    orderModify: 'order-modify',
    orderDetails: 'order-details',
    orderCancelOrder: 'order-cancelOrder',
    orderDeleteById: 'order-deleteById',
    orderRescheduleOrder: 'order-rescheduleOrder',
    testList: 'test-list',
    testDetails: 'test-details',
    testAdd: 'test-add',
    testModify: 'test-modify',
    testDeleteById: 'test-deleteById',
    testSendTestResults: 'test-sendTestResults',
    testUpdateMany: 'test-updateMany',
    testReleaseResultFromCsv: 'test-releaseResultFromCsv',
    testTypeAdd: 'testType-add',
    testTypeList: 'testType-list',
    testTypeDetails: 'testType-details',
    testTypeModify: 'testType-modify',
    testTypeDeleteById: 'testType-deleteById',
    testTypeDropdown: 'testType-dropdown',
    dashboardLabStats: 'dashboard-labStats',
    dashboardMyStats: 'dashboard-myStats',
    sampleTypeAdd: 'sampleType-add',
    sampleTypeList: 'sampleType-list',
    sampleTypeModify: 'sampleType-modify',
    sampleTypeDetails: 'sampleType-details',
    sampleTypeDeleteById: 'sampleType-deleteById',
    batchAdd: 'batch-add',
    batchList: 'batch-list',
    batchDetails: 'batch-details',
    batchInsertSample: 'batch-insertSample',
    batchDeleteById: 'batch-deleteById',
    customAddCustomField: 'custom-addCustomField',
    customGetModuleCustomFields: 'custom-getModuleCustomFields',
  },
  // authorization: window.localStorage.limsKey,
  contentType: 'application/json',
  clent: {
    id: '711a9c15-3b31-467c-ad33-77ea610038bb',
    secretKey: '363f80aa-c7a0-4185-a085-d209ad2d45c1',
    expiresIn: '1h'
  }
};

export { config };
