import { config } from '../../../config';

import { Params } from './Models';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchData = async (id: number) => {
  return await fetch(config.baseUrl + config.apiURL.batchDetails, {
    method: 'POST',
    body: JSON.stringify({
      id
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const submit = async (params: Params, body: string) => {
  const url = config.baseUrl + config.apiURL.batchAdd;

  return await fetch(url, {
    method: 'POST',
    body,
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const del = async (id: number) => {
  return await fetch(config.baseUrl + config.apiURL.batchDeleteById, {
    method: 'POST',
    body: JSON.stringify({
      id,
      deleteStatus: 1
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadCsv = async (fileName: string) => {
  return await fetch(config.baseUrl + config.apiURL.testReleaseResultFromCsv, {
    method: 'POST',
    body: JSON.stringify({
      fileName,
      path: 'folder/location'
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const insertNewSample = async (id: string, sampleId: string) => {
  return await fetch(config.baseUrl + config.apiURL.batchInsertSample, {
    method: 'POST',
    body: JSON.stringify({
      id,
      sampleId
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
