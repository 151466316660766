import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Grid, TextField, Container, FormControl, MenuItem, Select, Box, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import { jsPDF } from 'jspdf';
import { renderToString } from 'react-dom/server';
import moment from 'moment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

// eslint-disable-next-line import/order
import { config } from '../../../config';
import './NewSample.css';

import { Params, Users } from '../../SamplePage/Models';

import { Country } from './Country';
import { useStyles, SaveButton, DeleteButton, CloneButton } from './Styles';
import SampleLabel from './SampleLabel';

type Toast = {
  open: boolean;
  severity: number;
  message: string;
  redirect: boolean;
};

type Countrys = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label: string | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: string | any;
};

type Data = {
  id: string;
  name: string;
  type: string;
};

type Race = {
  race: string;
};

type Ethinicity = {
  ethinicity: string;
};

export default function NewSample(): React.ReactElement {
  const classes = useStyles();

  const [loader, setLoader] = React.useState(false);

  const history = useHistory();

  const [assigningAuthority, setAssigningAuthority] = React.useState<string>('');
  const [externalId, setExternalId] = React.useState<string>('');
  const [accessionId, setAccessionId] = React.useState<string>('');
  const [accountNumber, setAccountNumber] = React.useState<string>('');
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [address, setAddress] = React.useState<string>('');
  const [ethinicity, setEthinicity] = React.useState<string>('');
  const [race, setRace] = React.useState<string>('');
  const [birthDate, setBirthDate] = React.useState<string>('');
  const [sex, setSex] = React.useState<string>('');
  const [city, setCity] = React.useState<string>('');
  const [state, setState] = React.useState<string>('');
  const [zipCode, setZipCode] = React.useState<string>('');
  const [number, setNumber] = React.useState<string>('');
  const [passortCountry, setPassortCountry] = React.useState<string>('');
  const [specimanType, setSpecimanType] = React.useState<string>('');
  const [location, setLocation] = React.useState<string>('');
  const [timeOfCollection, setTimeOfCollection] = React.useState<string>('');
  const [labId, setLabId] = React.useState<string>('');
  const [, setName] = React.useState('');
  const [, setType] = React.useState('');

  const [user, setUser] = React.useState<Users>({
    lastName: '',
    firstName: '',
    sex: '',
    address: '',
    city: '',
    state: '',
    passportCountry: '',
    zipCode: '',
    birthDate: '',
    externalId: '',
    ethinicity: '',
    race: '',
    accessionId: '',
    assigningAuthority: '',
    passportNo: '',
    accountNumber: '',
    createdAt: '',
    country: '',
    specimanType: '',
    labId: '',
    locationId: '',
    timeOfCollection: ''
  });
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });

  const [locations, setLocations] = React.useState<Data[]>([
    {
      id: '',
      name: '',
      type: ''
    }
  ]);

  const Genders = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Prefer Not to State', value: 'Prefer Not to State' }
  ];

  const specimantype = [
    { label: 'Mid-turbinate', value: 'Mid-turbinate' },
    { label: 'Nasopharyngel', value: 'Nasopharyngel' }
  ];

  const Races = [
    { race: 'American Indian or Alaska Native' },
    { race: 'Asian' },
    { race: 'Black or African American' },
    { race: 'Native Hawaiian or Other Pacific Islander' },
    { race: 'White' },
    { race: 'Other' },
    { race: 'Prefer Not to State' }
  ];

  const Ethinicitys = [
    { ethinicity: 'Hispanic or Latino' },
    { ethinicity: 'Not Hispanic or Latino' },
    { ethinicity: 'Prefer Not to State' }
  ];

  const [label, setLabel] = React.useState('');
  const [labels, setLabels] = React.useState('');
  const [specimans, setSpeciman] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const params = useParams<Params>();
  const sampleIds = +params.id;

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });

    if (toast.redirect) {
      history.push('/center/samples');
    }
  };

  React.useEffect(() => {
    setLoader(true);
    let locId: number;

    if (params.id) {
      fetch(config.baseUrl + config.apiURL.sampleDetails, {
        method: 'post',
        headers: {
          authorization: window.localStorage.limsKey,
          'Content-Type': config.contentType
        },
        body: JSON.stringify({
          id: sampleIds
        })
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            setUser(data.data);
            locId = data.data.locationId;

            const labels1 = Country.find((e) => e.value === data.data.passportCountry) as Countrys;

            if (labels1) setLabel(labels1.label);
            const labelG = Genders.find((e) => e.value === data.data.sex) as Countrys;

            if (labelG) setLabels(labelG.label);
            const speciman = specimantype.find((e) => e.value === data.data.specimanType) as Countrys;

            if (speciman) setSpeciman(speciman.label);
          } else {
            setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
          }
        })
        .catch((err: Error) => {
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    } else {
      if (params.travelDest !== 'Japan' && params.travelDest !== 'JPN' && params.travelDest !== 'japan') {
        setUser({ ...user, specimanType: 'Mid-turbinate' });
        setSpeciman('Mid-turbinate');
        setSpecimanType('Mid-turbinate');
      }
    }

    axios({
      method: 'POST',
      url: config.baseUrl + config.apiURL.locationDropdown,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': config.contentType,
        authorization: window.localStorage.limsKey
      },
      data: {
        id: sampleIds
      }
    })
      .then((response) => {
        setLoader(false);
        setLocations(response.data.data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const locName = response.data.data.find((e: any) => e.id === locId) as Data;

        if (locName) setName(locName.name);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const locType = response.data.data.find((e: any) => e.id === locId) as Data;

        if (locType) setType(locType.type);
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  }, []);

  const save = (e: React.SyntheticEvent) => {
    setLoader(true);
    e.preventDefault();

    axios({
      method: 'POST',
      url: config.baseUrl + config.apiURL.sampleAdd,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': config.contentType,
        authorization: window.localStorage.limsKey
      },
      data: {
        orderId: params.orderId,
        lastName,
        firstName,
        sex,
        address,
        city,
        state,
        passportCountry: passortCountry,
        zipCode,
        birthDate,
        externalId,
        ethinicity,
        race,
        accessionId,
        assigningAuthority,
        passportNo: number,
        accountNumber,
        timeOfCollection,
        specimanType,
        locationId: location,
        labId
      }
    })
      .then((data) => {
        let redirect = false;
        let loaders = false;

        if (data.data.status === 200) {
          redirect = true;
          loaders = true;
        }
        setLoader(loaders);
        setToast({
          ...toast,
          open: true,
          severity: data.data.status === 200 ? 1 : 0,
          message: data.data.message,
          redirect
        });
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const saves = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoader(true);

    axios({
      method: 'POST',
      url: config.baseUrl + config.apiURL.sampleModify,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': config.contentType,
        authorization: window.localStorage.limsKey
      },
      data: JSON.stringify({ ...user })
    })
      .then(function (data) {
        let redirect = false;
        let loader1 = false;

        if (data.data.status === 200) {
          redirect = true;
          loader1 = true;
        }
        setLoader(loader1);
        setToast({
          ...toast,
          open: true,
          severity: data.data.status === 200 ? 1 : 0,
          message: data.data.message,
          redirect
        });
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const Delete = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoader(true);
    setOpen(false);
    axios({
      method: 'POST',
      url: config.baseUrl + config.apiURL.sampleDeleteById,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': config.contentType,
        authorization: window.localStorage.limsKey
      },
      data: {
        id: sampleIds,
        deleteStatus: 1
      }
    })
      .then((data) => {
        let redirect = false;

        if (data.data.status === 200) {
          redirect = true;
        }
        setToast({
          ...toast,
          open: true,
          severity: data.data.status === 200 ? 1 : 0,
          message: data.data.message,
          redirect
        });
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const back = () => {
    if (!params.orderId) history.push('/center/samples');
    else history.push(`/center/samples/orderDetail/${params.orderId}`);
  };

  //fUNCTION TO GENERATE LABEL
  const onGeneratePdf = () => {
    //pass ID(cus id)as string,name{pat name} and testName(name of the test)
    //change static id
    // eslint-disable-next-line
    const string = renderToString(
      <SampleLabel id={sampleIds} name={(user.firstName || '') + ' ' + (user.lastName || '')} testName="" />
    );

    const pdf = new jsPDF('p', 'pt', 'a4', false);
    pdf.html(string, {
      callback(doc) {
        doc.save();
      },
      x: 10,
      y: 15
    });
  };

  return (
    <Grid className="root" container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress />
      </Backdrop>
      <Container className={classes.white}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <ChevronLeftIcon
            style={{
              color: '#4BB543'
            }}
          />
          <span
            style={{
              color: '#4BB543',

              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={back}
          >
            {params.orderId ? 'Back to Order Details' : 'Back to Sample List'}
          </span>
        </div>
        {sampleIds ? (
          <div>
            <form onSubmit={saves}>
              <br />
              <Grid justify="flex-end" container>
                <SaveButton
                  style={{ marginTop: '-40px', cursor: 'pointer' }}
                  variant="contained"
                  onClick={() => history.push('/center/samples/testDetail/sampleId/' + params.id)}
                >
                  + New Test
                </SaveButton>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <h2 className={classes.label}>Sample </h2>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Lab Id</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Lab Id"
                  size="small"
                  value={user.labId}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.labId = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>External Accession Id</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="External Accession Id"
                  size="small"
                  value={user.accessionId}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.accessionId = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Source</b>
                <br />
                <FormControl className={classes.width} size="small">
                  <Select variant="outlined">
                    <MenuItem value={undefined}>Select Source</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Id Assigning Authority</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient Id Assigning Authority"
                  size="small"
                  value={user.assigningAuthority}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.assigningAuthority = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient External Id</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient External Id"
                  size="small"
                  value={user.externalId}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.externalId = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Account Number</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient Account Number"
                  size="small"
                  value={user.accountNumber}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.accountNumber = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient First Name</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  inputProps={{ maxLength: 15 }}
                  placeholder="Patient First Name"
                  size="small"
                  value={user.firstName}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.firstName = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Last Name</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  inputProps={{ maxLength: 15 }}
                  placeholder="Patient Last Name"
                  size="small"
                  value={user.lastName}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.lastName = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Race</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>

                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => option.race}
                    id="controllable-states-demo"
                    options={Races}
                    renderInput={(paramsR) => (
                      <TextField {...paramsR} size="small" value={user.race} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ race: user.race }}
                    onChange={(event, newValue: Race | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.race = newValue.race;
                        setUser(tmp);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Ethinicity</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>

                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => option.ethinicity}
                    id="controllable-states-demo"
                    options={Ethinicitys}
                    renderInput={(paramsE) => (
                      <TextField {...paramsE} size="small" value={user.ethinicity} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ ethinicity: user.ethinicity }}
                    onChange={(event, newValue: Ethinicity | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.ethinicity = newValue.ethinicity;
                        setUser(tmp);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Date of Birth</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  inputProps={{
                    max: moment().format('YYYY-MM-DD')
                  }}
                  size="small"
                  type="date"
                  value={moment(user.birthDate).format('YYYY-MM-DD')}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.birthDate = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Sex</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <br />
                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    id="controllable-states-demo"
                    options={Genders}
                    renderInput={(paramsG) => (
                      <TextField {...paramsG} size="small" value={user.sex} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ label: labels, value: user.sex }}
                    onChange={(event, newValue: Countrys | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.sex = newValue.value;
                        setLabels(newValue.label);
                        setUser(tmp);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Address</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient Address"
                  rows={3}
                  size="small"
                  value={user.address}
                  variant="outlined"
                  multiline
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.address = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient City</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient City"
                  size="small"
                  value={user.city}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.city = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient State</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient State"
                  size="small"
                  value={user.state}
                  variant="outlined"
                  required
                  onChange={function (event) {
                    const tmp = { ...user };
                    tmp.state = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Zip Code</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient Zip Code"
                  size="small"
                  type="number"
                  value={user.zipCode}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.zipCode = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Passport Number</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Passport Number"
                  size="small"
                  value={user.passportNo}
                  variant="outlined"
                  required
                  onChange={(event) => {
                    const tmp = { ...user };
                    tmp.passportNo = event.target.value;
                    setUser(tmp);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Passport Country</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <FormControl className={classes.width} size="small" required>
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    id="controllable-states-demo"
                    options={Country}
                    renderInput={(paramsC) => (
                      <TextField {...paramsC} size="small" value={user.passportCountry} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ label, value: user.passportCountry }}
                    onChange={(event, newValue: Countrys | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.passportCountry = newValue.value;
                        setLabel(newValue.label);
                        setUser(tmp);
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Speciman Type</b>
                <br />
                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    id="controllable-states-demo"
                    options={specimantype}
                    renderInput={(paramsSt) => (
                      <TextField {...paramsSt} size="small" value={user.specimanType} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ label: specimans, value: user.specimanType }}
                    onChange={(event, newValue: Countrys | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.specimanType = newValue.value;
                        setSpeciman(newValue.label);
                        setUser(tmp);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Time of Collection</b>
                <TextField
                  className={classes.width}
                  placeholder="Time of Collection"
                  size="small"
                  // value={new Date(user.timeOfCollection).toLocaleString()}
                  value={moment(user.timeOfCollection).format('YYYY-MM-DD,hh:mm a')}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Location</b>
                <br />
                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => `${option.id} - ${option.name} 🡢 ${option.type}`}
                    id="controllable-states-demo"
                    options={locations}
                    renderInput={(paramsR) => (
                      <TextField {...paramsR} size="small" value={user.race} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    // value={{
                    //   id: user.locationId,
                    //   name: name,
                    //   type: type
                    // }}
                    onChange={(event, newValue: Data | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.locationId = newValue.id;
                        setUser(tmp);
                      }
                    }}
                  />
                </FormControl>
                {/* <FormControl className={classes.width} size="small">
                  <Select
                    value={user.locationId}
                    variant="outlined"
                    required
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      const tmp = { ...user };
                      tmp.locationId = event.target.value as string;
                      setUser(tmp);
                    }}
                  >
                    <MenuItem value="">Select Location</MenuItem>
                    {locations.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.id} - {e.name} 🡢 {e.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Tags</b>
                <TextField className={classes.width} placeholder="Tags" size="small" variant="outlined" />
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Comments</b>
                <TextField
                  className={classes.width}
                  placeholder="Comments"
                  rows={6}
                  size="small"
                  variant="outlined"
                  multiline
                />
              </Grid>
              <Grid className={classes.padding} container>
                <Box mr={2}>
                  <SaveButton type="submit" variant="contained">
                    Save
                  </SaveButton>
                </Box>
                <Box mr={2}>
                  <CloneButton color="primary" variant="contained" onClick={() => onGeneratePdf()}>
                    Print Labels
                  </CloneButton>
                </Box>

                <>
                  <Box mr={2}>
                    <DeleteButton variant="contained" onClick={handleClickOpen}>
                      Delete
                    </DeleteButton>
                  </Box>
                  <Dialog
                    aria-describedby="alert-dialog-description"
                    aria-labelledby="alert-dialog-title"
                    open={open}
                    onClose={handleCloseAlert}
                  >
                    <DialogTitle id="alert-dialog-title"> Delete alert</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the record
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button color="primary" onClick={handleCloseAlert}>
                        Cancel
                      </Button>
                      <Button color="secondary" autoFocus onClick={Delete}>
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              </Grid>
            </form>
          </div>
        ) : (
          <div>
            <form onSubmit={save}>
              <Grid className={classes.padding} xs={12} item>
                <h2 className={classes.label}>Sample 🡢</h2>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Lab Id</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Lab Id"
                  size="small"
                  value={labId}
                  variant="outlined"
                  required
                  onChange={(e) => {
                    setLabId(e.target.value);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>External Accession Id</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="External Accession Id"
                  size="small"
                  value={accessionId}
                  variant="outlined"
                  required
                  onChange={(e) => setAccessionId(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Source</b>
                <br />
                <FormControl className={classes.width} size="small">
                  <Select variant="outlined">
                    <MenuItem value={undefined}>Select Source</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Id Assigning Authority</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient Id Assigning Authority"
                  size="small"
                  variant="outlined"
                  required
                  onChange={(e) => setAssigningAuthority(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient External Id</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient External Id"
                  size="small"
                  variant="outlined"
                  required
                  onChange={(e) => setExternalId(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Account Number</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient Account Number"
                  size="small"
                  value={accountNumber}
                  variant="outlined"
                  required
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient First Name</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  inputProps={{ maxLength: 15 }}
                  placeholder="Patient First Name"
                  size="small"
                  variant="outlined"
                  required
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Last Name</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  inputProps={{ maxLength: 15 }}
                  placeholder="Patient Last Name"
                  size="small"
                  variant="outlined"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Race</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>

                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => option.race}
                    id="controllable-states-demo"
                    options={Races}
                    renderInput={(paramsRaces) => (
                      <TextField {...paramsRaces} size="small" value={user.race} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ race: user.race }}
                    onChange={(event, newValue: Race | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.race = newValue.race;
                        setRace(newValue.race);

                        setUser(tmp);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Ethinicity</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>

                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => option.ethinicity}
                    id="controllable-states-demo"
                    options={Ethinicitys}
                    renderInput={(paramsEth) => (
                      <TextField {...paramsEth} size="small" value={user.ethinicity} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ ethinicity: user.ethinicity }}
                    onChange={(event, newValue: Ethinicity | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.ethinicity = newValue.ethinicity;
                        setEthinicity(newValue.ethinicity);

                        setUser(tmp);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Date of Birth</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  inputProps={{
                    max: moment().format('YYYY-MM-DD')
                  }}
                  size="small"
                  type="date"
                  value={birthDate}
                  variant="outlined"
                  required
                  onChange={(e) => setBirthDate(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Sex</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <br />
                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    id="controllable-states-demo"
                    options={Genders}
                    renderInput={(paramsGen) => (
                      <TextField {...paramsGen} size="small" value={user.sex} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ label: labels, value: user.sex }}
                    onChange={(event, newValue: Countrys | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.sex = newValue.value;
                        setLabels(newValue.label);
                        setUser(tmp);
                        setSex(newValue.value);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Address</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient Address"
                  rows={3}
                  size="small"
                  value={address}
                  variant="outlined"
                  multiline
                  required
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient City</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient City"
                  size="small"
                  variant="outlined"
                  required
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient State</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient State"
                  size="small"
                  variant="outlined"
                  required
                  onChange={(e) => setState(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Patient Zip Code</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Patient Zip Code"
                  size="small"
                  type="number"
                  variant="outlined"
                  required
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Passport Number</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Passport Number"
                  size="small"
                  value={number}
                  variant="outlined"
                  required
                  onChange={(e) => setNumber(e.target.value)}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Passport Country</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <FormControl className={classes.width} size="small" required>
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    id="controllable-states-demo"
                    options={Country}
                    renderInput={(paramsCou) => <TextField {...paramsCou} size="small" variant="outlined" required />}
                    style={{ width: 100 + '%' }}
                    onChange={(event, newValue: Countrys | null) => {
                      if (newValue) setPassortCountry(newValue.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Speciman Type</b>
                <br />
                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => option.label}
                    id="controllable-states-demo"
                    options={specimantype}
                    renderInput={(paramsSpeType) => (
                      <TextField
                        {...paramsSpeType}
                        size="small"
                        value={user.specimanType}
                        variant="outlined"
                        required
                      />
                    )}
                    style={{ width: 100 + '%' }}
                    value={{ label: specimans, value: user.specimanType }}
                    onChange={(event, newValue: Countrys | null) => {
                      if (newValue) {
                        const tmp = { ...user };
                        tmp.specimanType = newValue.value;
                        setSpeciman(newValue.label);
                        setUser(tmp);
                        setSpecimanType(newValue.label);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Time of Collection</b>
                <b className="red-star" style={{ color: 'red' }}>
                  *
                </b>
                <TextField
                  className={classes.width}
                  placeholder="Time of Collection"
                  size="small"
                  type="datetime-local"
                  variant="outlined"
                  required
                  onChange={(e) => {
                    setTimeOfCollection(e.target.value);
                  }}
                />
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Location</b>
                <br />
                <FormControl className={classes.width} size="small">
                  <Autocomplete
                    getOptionLabel={(option) => `${option.id} - ${option.name} 🡢 ${option.type}`}
                    id="controllable-states-demo"
                    options={locations}
                    renderInput={(paramsR) => (
                      <TextField {...paramsR} size="small" value={user.race} variant="outlined" required />
                    )}
                    style={{ width: 100 + '%' }}
                    // value={{
                    //   id: user.locationId,
                    //   name: name,
                    //   type: type
                    // }}
                    onChange={(event, newValue: Data | null) => {
                      if (newValue) {
                        setLocation(newValue.id);
                      }
                    }}
                  />
                </FormControl>
                {/* <FormControl className={classes.width} size="small">
                  <Select
                    value={location}
                    variant="outlined"
                    required
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                      setLocation(event.target.value as string);
                    }}
                  >
                    <MenuItem value={undefined}>Select Location</MenuItem>
                    {locations.map(function (e) {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          {e.id} - {e.name} 🡢 {e.type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Tags</b>
                <TextField className={classes.width} placeholder="Tags" size="small" variant="outlined" />
              </Grid>

              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Comments</b>
                <TextField
                  className={classes.width}
                  placeholder="Comments"
                  rows={6}
                  size="small"
                  variant="outlined"
                  multiline
                />
              </Grid>
              <Grid className={classes.padding} container>
                <Box mr={2}>
                  <SaveButton type="submit" variant="contained">
                    Save
                  </SaveButton>
                </Box>
              </Grid>
            </form>
          </div>
        )}
      </Container>
    </Grid>
  );
}
