import React from 'react';
import { Button, Grid, TextField, Container, FormControl, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useParams, useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { Params, User, Toast } from './Models';
import { fetchData, submit, del } from './api';
import { useStyles, SaveButton, DeleteButton } from './Styles';

import './OrderDetails.css';

type Countrys = {
  label: string;
};

export default function CustomerLocation(): React.ReactElement {
  const params = useParams<Params>();
  const history = useHistory();
  const classes = useStyles();
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });
  const [user, setUser] = React.useState<User>({
    customerAbbreviation: '',
    name: '',
    title: '',
    address: '',
    phone: '',
    fax: '',
    defaultReport: '',
    defaultSampleLabel: '',
    poNumber: '',
    specialInstructions: '',
    comments: ''
  });
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const defaultLabel = [{ label: 'Country' }, { label: 'Passenger' }, { label: 'Discovery Health' }];

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });

    if (toast.redirect) {
      history.push('/center/customers');
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoader(true);
    const body = JSON.stringify({ ...user, id: params.id });
    submit(params, body)
      .then(async (response: Response) => {
        const message = await response.json();
        let redirect = false;
        let loaders = false;

        if (message.status === 200) {
          redirect = true;
          loaders = true;
        }
        setLoader(loaders);
        setToast({
          ...toast,
          open: true,
          severity: message.status === 200 ? 1 : 0,
          message: message.message,
          redirect
        });
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleDelete = () => {
    setOpen(false);
    setLoader(true);
    del(+params.id)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(true);
        setToast({
          ...toast,
          open: true,
          severity: data.status === 200 ? 1 : 0,
          message: data.message,
          redirect: data.status === 200 ? true : false
        });
      })
      .catch((err) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  React.useEffect(() => {
    if (params.id) {
      setLoader(true);
      fetchData(+params.id)
        .then(async (response: Response) => {
          const data = await response.json();
          setLoader(false);

          if (data.status === 200) {
            setUser(data.data);
          } else {
            setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
          }
        })
        .catch((err: Error) => {
          setLoader(false);
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    }
  }, []);

  const back = () => {
    history.push('/center/customers');
  };

  return (
    <Grid className="root" container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        className={classes.snackBar}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress />
      </Backdrop>
      <Container className={classes.white}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <ChevronLeftIcon
            style={{
              color: '#4BB543'
            }}
          />
          <span
            style={{
              color: '#4BB543',

              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={back}
          >
            Back to Customer List
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          {params.id ? (
            <Grid justify="flex-end" container>
              <SaveButton
                style={{ marginTop: '-20px', cursor: 'pointer' }}
                variant="contained"
                onClick={() => history.push('/center/samples/orderDetail')}
              >
                + New Order
              </SaveButton>
            </Grid>
          ) : (
            ''
          )}
          <Grid className={classes.padding} xs={12} item>
            <h2 className={classes.label}>Customers </h2>
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Customer Abbreviation</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              color="primary"
              placeholder="Abbreviation"
              size="small"
              value={user.customerAbbreviation}
              variant="outlined"
              fullWidth
              required
              onChange={(event) => {
                const tmp = { ...user };

                tmp.customerAbbreviation = event.target.value;

                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Name</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              color="primary"
              placeholder="Name"
              size="small"
              value={user.name}
              variant="outlined"
              fullWidth
              required
              onChange={(event) => {
                const tmp = { ...user };
                tmp.name = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Title</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              color="primary"
              placeholder="Title"
              size="small"
              value={user.title}
              variant="outlined"
              fullWidth
              required
              onChange={(event) => {
                const tmp = { ...user };
                tmp.title = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Address</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              color="primary"
              placeholder="Address"
              rows={4}
              size="small"
              value={user.address}
              variant="outlined"
              fullWidth
              multiline
              required
              onChange={(event) => {
                if (event.target.value.replace(/\s/g, '').length > 0) {
                  const tmp = { ...user };
                  tmp.address = event.target.value;
                  setUser(tmp);
                }
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Phone</b>

            <TextField
              className={classes.width}
              color="primary"
              placeholder="Phone"
              size="small"
              type="number"
              value={user.phone}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                const tmp = { ...user };
                tmp.phone = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Fax</b>

            <TextField
              className={classes.width}
              color="primary"
              placeholder="Fax"
              size="small"
              value={user.fax}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                const tmp = { ...user };
                tmp.fax = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Default Report</b>

            <TextField
              className={classes.width}
              color="primary"
              placeholder="Default Report"
              size="small"
              value={user.defaultReport}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                const tmp = { ...user };
                tmp.defaultReport = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}> Default Sample Label</b>
            <b className="red-star">*</b>
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.label}
                id="controllable-states-demo"
                options={defaultLabel}
                renderInput={(param) => (
                  <TextField {...param} size="small" value={user.defaultSampleLabel} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ label: user.defaultSampleLabel }}
                onChange={(event, newValue: Countrys | null) => {
                  if (newValue) {
                    const tmp = { ...user };
                    tmp.defaultSampleLabel = newValue.label;
                    setUser(tmp);
                  }
                }}
              />

              {/* <Select
                value={user.defaultSampleLabel}
                variant="outlined"
                required
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.defaultSampleLabel = event.target.value as string;
                  setUser(tmp);
                }}
              >
                <MenuItem value="Country">Country</MenuItem>
                <MenuItem value="Passenger">Passenger</MenuItem>
                <MenuItem value="Discovery Health">Discovery Health</MenuItem>
              </Select> */}
            </FormControl>
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>PO Number</b>

            <TextField
              className={classes.width}
              color="primary"
              placeholder="PO Number"
              size="small"
              type="number"
              value={user.poNumber}
              variant="outlined"
              fullWidth
              onChange={(event) => {
                const tmp = { ...user };
                tmp.poNumber = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Special Instructions</b>

            <TextField
              className={classes.width}
              color="primary"
              placeholder="Special Instructions"
              rows={6}
              size="small"
              value={user.specialInstructions}
              variant="outlined"
              fullWidth
              multiline
              onChange={(event) => {
                const tmp = { ...user };
                tmp.specialInstructions = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Comments</b>

            <TextField
              className={classes.width}
              color="primary"
              placeholder="Comments"
              rows={6}
              size="small"
              value={user.comments}
              variant="outlined"
              fullWidth
              multiline
              onChange={(event) => {
                const tmp = { ...user };
                tmp.comments = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} container>
            <Box mr={2}>
              <SaveButton type="submit" variant="contained">
                Save
              </SaveButton>
            </Box>
            {params.id && (
              <>
                <Box mr={2}>
                  <DeleteButton variant="contained" onClick={handleClickOpen}>
                    Delete
                  </DeleteButton>
                </Box>
                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={open}
                  onClose={handleCloseAlert}
                >
                  <DialogTitle id="alert-dialog-title"> Delete alert</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the record
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={handleCloseAlert}>
                      Cancel
                    </Button>
                    <Button color="secondary" autoFocus onClick={handleDelete}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
        </form>
      </Container>
    </Grid>
  );
}
