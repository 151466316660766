import React from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, TextField, Container, Box, Button } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useStyles, SaveButton, DeleteButton, CloneButton } from './Styles';
import { Batch, Toast, Params } from './Models';
import { fetchData, submit, del, insertNewSample, uploadCsv } from './api';

import './BatchDetails.css';

export default function BatchDetails(): React.ReactElement {
  const params = useParams<Params>();
  const history = useHistory();
  const classes = useStyles();
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });
  const [batch, setBatch] = React.useState<Batch>({
    id: '',
    name: '',
    description: '',
    sampleCount: '',
    createdAt: ''
  });
  const [insertSample, setInsertSample] = React.useState<{
    open: boolean;
    id: string;
    sampleId: string;
  }>({
    open: false,
    id: '',
    sampleId: ''
  });

  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = () => {
    setToast({ ...toast, open: false });

    if (toast.redirect) {
      history.push('/center/batchs');
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoader(true);
    const tmp: {
      id?: string;
      name: string;
      description: string;
      sampleCount?: string;
      createdAt?: string;
    } = { ...batch };

    delete tmp.sampleCount;
    delete tmp.createdAt;
    delete tmp.id;

    const body = JSON.stringify({
      ...tmp,
      id: params.id
    });

    submit(params, body)
      .then(async (response: Response) => {
        const message = await response.json();
        let redirect = false;
        let loaders = false;

        if (message.status === 200) {
          redirect = true;
          loaders = true;
        }
        setLoader(loaders);
        setToast({
          ...toast,
          open: true,
          severity: message.status === 200 ? 1 : 0,
          message: message.message,
          redirect
        });
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleDelete = () => {
    setOpen(false);
    setLoader(true);
    del(+params.id)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);
        setToast({ ...toast, open: true, severity: 1, message: data.message, redirect: true });
      })
      .catch((err) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleInsertSample = () => {
    if (insertSample.sampleId.length) {
      setInsertSample({ ...insertSample, open: false });
      setLoader(true);
      insertNewSample(insertSample.id, insertSample.sampleId)
        .then(async function (response: Response) {
          const data = await response.json();
          setLoader(false);
          setToast({ ...toast, open: true, severity: 1, message: data.message, redirect: true });
        })
        .catch((err) => {
          setLoader(false);
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    }
  };

  React.useEffect(() => {
    if (params.id) {
      setLoader(true);
      fetchData(+params.id)
        .then(async (response: Response) => {
          const data = await response.json();
          setLoader(false);

          if (data.status === 200) {
            setBatch(data.data);
          } else {
            setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
          }
        })
        .catch((err: Error) => {
          setLoader(false);
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    }
  }, []);

  const upload = (fileName: string) => {
    if (params.id) {
      uploadCsv(fileName)
        .then(async function (response: Response) {
          const data = await response.json();
          setLoader(false);
          setToast({ ...toast, open: true, severity: 1, message: data.message, redirect: true });
        })
        .catch((err: Error) => {
          setLoader(false);
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    }
  };

  const back = () => {
    history.push('/center/batchs');
  };

  return (
    <Grid className="root" container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress />
      </Backdrop>
      <Container className={classes.white}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <ChevronLeftIcon
            style={{
              color: '#4BB543'
            }}
          />
          <span
            style={{
              color: '#4BB543',

              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={back}
          >
            Back to Batch List
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          <Grid className={classes.padding} xs={12} item>
            <h2 className={classes.label}>Batch </h2>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Name</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              placeholder="Name"
              size="small"
              value={batch.name}
              variant="outlined"
              required
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setBatch({ ...batch, name: event.target.value as string });
              }}
            />
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Description</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              placeholder="Description"
              size="small"
              value={batch.description}
              variant="outlined"
              required
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setBatch({ ...batch, description: event.target.value as string });
              }}
            />
          </Grid>

          {params.id && (
            <>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Samples Count :</b>
                {batch.sampleCount}
              </Grid>
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Created At :</b>

                {moment(batch.createdAt).format('DD-MM-YYYY,h:mm a')}
              </Grid>
            </>
          )}

          <Grid className={classes.padding} container>
            <Box mr={2}>
              <SaveButton type="submit" variant="contained">
                Save
              </SaveButton>
            </Box>

            {params.id && (
              <>
                <Box mr={2}>
                  <CloneButton
                    variant="contained"
                    onClick={() => {
                      setInsertSample({
                        open: true,
                        id: params.id,
                        sampleId: ''
                      });
                    }}
                  >
                    Insert Samples
                  </CloneButton>
                </Box>

                <Box mr={2}>
                  <DeleteButton variant="contained" onClick={handleClickOpen}>
                    Delete
                  </DeleteButton>
                </Box>
                <Box mr={2}>
                  <Button component="label" variant="contained">
                    Upload File
                    <input
                      type="file"
                      hidden
                      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                      onChange={({ target }: any) => {
                        upload(target.files[0].name);
                      }}
                    />
                  </Button>
                </Box>

                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={open}
                  onClose={handleCloseAlert}
                >
                  <DialogTitle id="alert-dialog-title"> Delete alert</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the record
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={handleCloseAlert}>
                      Cancel
                    </Button>
                    <Button color="secondary" autoFocus onClick={handleDelete}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={insertSample.open}
                  onClose={() => {
                    setInsertSample({
                      open: false,
                      id: '',
                      sampleId: ''
                    });
                  }}
                >
                  <DialogTitle id="alert-dialog-title">Insert sample in the current batch</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <Grid className={classes.padding} xs={12} item>
                        <b className={classes.label}>Add Sample</b>
                        <b className="red-star">*</b>
                        <TextField
                          className={classes.width}
                          placeholder="Enter Sample Id"
                          size="small"
                          value={insertSample.sampleId}
                          variant="outlined"
                          required
                          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setInsertSample({ ...insertSample, sampleId: event.target.value as string });
                          }}
                        />
                      </Grid>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={function () {
                        setInsertSample({
                          open: false,
                          id: '',
                          sampleId: ''
                        });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button color="secondary" onClick={handleInsertSample}>
                      + Add
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
        </form>
      </Container>
    </Grid>
  );
}
