import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import './App.scss';
import CenterLoginLayout from './layouts/CenterLoginLayout';
import LoginPage from './pages/LoginPage';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import JapReport from './pages/Report/Japanese';

function App(): React.ReactElement {
  return (
    <Router>
      <Route path="/" exact>
        <Redirect to="/login" exact />
      </Route>
      <Route component={LoginPage} path="/login" exact />
      <Route component={ForgetPassword} path="/forgetPassword" exact />
      <Route component={JapReport} path="/report/japanese" exact />
      <Route component={CenterLoginLayout} path="/center" />
      <Route component={ResetPassword} path="/resetPassword" exact />
      <Route component={ResetPassword} path="/resetPassword/:mail/:code" />
    </Router>
  );
}

export default App;
