import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Grid, TextField, Container, FormControl, MenuItem, Select, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { jsPDF } from 'jspdf';
import { renderToString } from 'react-dom/server';

import GenericReport from '../../Report/GenericReport';
import IntReport from '../../Report/IntReport';
import HawaiiReport from '../../Report/HawaiiReport';
import { config } from '../../../config';

import { useStyles, SaveButton, DeleteButton, CloneButton, Report } from './Styles';
import { Params, User, Toast, Users, Assay } from './Models';
import { fetchData, submit, del, userDropdown, assayDropdown, testResults } from './api';

import './TestDetails.css';

type Countrys = {
  label: string;
  value: string;
};

type Reports = {
  type: string;
  name: string;
};

export default function TestDetails(): React.ReactElement {
  const params = useParams<Params>();
  const history = useHistory();
  const classes = useStyles();
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });
  const [user, setUser] = React.useState<User>({
    sampleId: params.id,
    testStatus: '',
    technician: '',
    instrumentId: '',
    assayId: '',
    testLocation: '',
    kitLot: '',
    startDate: '',
    completedDate: '',
    tags: '',
    results: '',
    comments: '',
    reviewedBy: '',
    reviewedDate: ''
  });
  const [userList, setUserList] = React.useState<Users[]>([
    // {
    //   id: '',
    //   userName: ''
    // }
  ]);

  const [assayId, setAssayId] = React.useState<Assay[]>([
    // {
    //   id: '',
    //   title: ''
    // }
  ]);

  const reportType = [
    {
      type: 'HAW',
      name: 'Hawaii'
    },
    {
      type: 'INT',
      name: 'International'
    },
    {
      type: 'GEN',
      name: 'Generic'
    },
    {
      type: 'JAP',
      name: 'Japanese'
    }
  ];
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [sampleId, setSampleId] = React.useState('');
  const [orderId, setOrderId] = React.useState(1);
  const [reportLoader, setReportLoader] = React.useState(false);

  const [labels, setLabels] = React.useState('');
  const [userNames, setUserNames] = React.useState('');
  const [reviewedBy, setReviewedBy] = React.useState('');
  const [assayIds, setAssayIds] = React.useState('');
  const [res, setRes] = React.useState('');
  const [reportTypes, setReportType] = React.useState('');
  const [createdAt, setCreatedAt] = React.useState('');

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setlastName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [sex, setSex] = React.useState('');
  const [race, setRace] = React.useState('');
  const [ethnicity, setEthnicity] = React.useState('');
  const [sampleType, setSampleType] = React.useState('');
  let defaultLabel: Countrys[];

  if (params.sampleId) {
    defaultLabel = [
      { label: 'Not Started', value: 'NOT STARTED' },
      { label: 'In Progress', value: 'INPROGRESS' },
      { label: 'Needs Review', value: 'INNEEDS REVIEWT' },
      { label: 'Created', value: 'CREATED' }
    ];
  } else {
    defaultLabel = [
      { label: 'Not Started', value: 'NOT STARTED' },
      { label: 'In Progress', value: 'INPROGRESS' },
      { label: 'Needs Review', value: 'INNEEDS REVIEWT' },
      { label: 'Created', value: 'CREATED' },
      { label: 'Completed', value: 'COMPLETED' },
      { label: 'Cancelled', value: 'CANCELLED' }
    ];
  }

  const Results = [
    { label: 'Detected', value: 'DETECTED' },
    { label: 'Not Detected', value: 'NOT-DETECTED' }
  ];

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });

    if (toast.redirect) {
      history.push('/center/testing');
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoader(true);

    const body = JSON.stringify({ ...user, id: params.id, sampleId: params.sampleId ? params.sampleId : sampleId });

    submit(params, body)
      .then(async (response: Response) => {
        const message = await response.json();
        let redirect = false;
        let loaders = false;

        if (message.status === 200) {
          redirect = true;
          loaders = true;
        }
        setLoader(loaders);
        setToast({
          ...toast,
          open: true,
          severity: message.status === 200 ? 1 : 0,
          message: message.message,
          redirect
        });
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleDelete = () => {
    setOpen(false);
    setLoader(true);
    del(+params.id)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);
        setToast({ ...toast, open: true, severity: 1, message: data.message, redirect: true });
      })
      .catch((err) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleTestRelease = () => {
    setLoader(true);
    testResults(+params.id, orderId)
      .then(async function (response: Response) {
        const data = await response.json();
        setLoader(false);
        setToast({ ...toast, open: true, severity: 1, message: data.message, redirect: true });
      })
      .catch((err) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  React.useEffect(() => {
    setLoader(true);

    if (params.id) {
      setLoader(true);
      fetchData(+params.id)
        .then(async (response: Response) => {
          const data = await response.json();

          if (data.status === 200) {
            setUser(data.data);

            setCreatedAt(data.data.createdAt);
            setSampleId(data.data.sampleId);
            setOrderId(data.data.orderId);

            if (data.data.sampleId) {
              fetch(config.baseUrl + config.apiURL.sampleDetails, {
                method: 'post',
                headers: {
                  authorization: window.localStorage.limsKey,
                  'Content-Type': config.contentType
                },
                body: JSON.stringify({
                  id: data.data.sampleId
                })
              })
                .then((resp) => resp.json())
                .then((datas) => {
                  if (datas.data) {
                    setFirstName(datas.data.firstName);
                    setlastName(datas.data.lastName);
                    setDob(datas.data.birthDate);
                    setAddress(datas.data.address);
                    setSex(datas.data.sex);
                    setRace(datas.data.race);
                    setEthnicity(datas.data.ethnicity);
                    setSampleType(datas.data.specimanType);
                  } else {
                    setToast({ ...toast, open: true, severity: 0, message: datas.message, redirect: false });
                  }
                })
                .catch((err: Error) => {
                  setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
                });
            }

            const labelG = defaultLabel.find((e) => e.value === data.data.testStatus) as Countrys;

            if (labelG) setLabels(labelG.label);
            const labelR = Results.find((e) => e.value === data.data.results) as Countrys;

            if (labelR) setRes(labelR.label);

            setLoader(false);
          } else {
            setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
          }
        })
        .catch((err: Error) => {
          setLoader(false);
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    }
    userDropdown()
      .then(async (response: Response) => {
        const data = await response.json();

        if (data.status === 200) {
          setUserList(data.data);
        } else {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });

    assayDropdown()
      .then(async (response: Response) => {
        const data = await response.json();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newdata: any = [];
        const newdata1: Assay[] = [];

        if (data.status === 200) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data.data.forEach((element: any) => {
            if (!(newdata.includes(element.title) && newdata.includes(element.method))) {
              newdata.push(element.title);
              newdata.push(element.method);
              newdata1.push(element);
            }
          });
          setAssayId(newdata1);
          setLoader(false);
        } else {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  }, []);

  React.useEffect(() => {
    if (userList.length && user.technician.length && !userNames.length) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const names = userList.find((e: any) => e.id === +user.technician) as Users;

      if (names) setUserNames(names.userName);
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const review = userList.find((e: any) => e.id === +user.reviewedBy) as Users;

      if (review) setReviewedBy(review.userName);
    }

    if (assayId.length && user.assayId && !assayIds.length) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const assay = assayId.find((e: any) => +e.id === +user.assayId) as Assay;

      if (assay) setAssayIds(assay.title);
    }
  }, [userList, assayId, user]);

  const back = () => {
    if (!params.sampleId) history.push('/center/testing');
    else history.push(`/center/samples/sampleDetail/${params.sampleId}`);
  };

  const onGenerateReportPdf = () => {
    let string = '';

    if (reportTypes !== 'JAP') {
      if (reportTypes === 'GEN') {
        //pass ID(cus id)as string,name{pat name} and testName(name of the test)
        //change static id
        // eslint-disable-next-line
        string = renderToString(
          <GenericReport
            address={address}
            createdAt={moment(createdAt).format('YYYY-MM-DD')}
            dob={moment(dob).format('YYYY-MM-DD')}
            ethnicity={ethnicity}
            name={firstName}
            orderId={orderId}
            race={race}
            result={user.results}
            reviewedDate={moment(user.reviewedDate).format('YYYY-MM-DD')}
            sampleId={user.sampleId}
            sampleType={sampleType}
            sex={sex}
          />
        );
      } else if (reportTypes === 'HAW') {
        // eslint-disable-next-line
        string = renderToString(
          <HawaiiReport
            address={address}
            createdAt={moment(createdAt).format('YYYY-MM-DD')}
            dob={moment(dob).format('MM-DD-YYYY')}
            ethnicity={ethnicity}
            lastName={lastName}
            name={firstName}
            orderId={orderId}
            race={race}
            result={user.results}
            reviewedDate={moment(user.reviewedDate).format('YYYY-MM-DD')}
            sampleId={user.sampleId}
            sampleType={sampleType}
            sex={sex}
          />
        );
      } else if (reportTypes === 'INT') {
        // eslint-disable-next-line
        string = renderToString(
          <IntReport
            address={address}
            createdAt={moment(createdAt).format('YYYY-MM-DD')}
            dob={moment(dob).format('YYYY-MM-DD')}
            ethnicity={ethnicity}
            name={firstName}
            orderId={orderId}
            race={race}
            result={user.results}
            reviewedDate={moment(user.reviewedDate).format('YYYY-MM-DD')}
            sampleId={user.sampleId}
            sampleType={sampleType}
            sex={sex}
          />
        );
      }

      const pdf = new jsPDF('portrait', 'pt', 'a4');

      pdf.html(string, {
        callback(doc) {
          doc.save();
          setReportLoader(false);
        },
        x: 0,
        y: 0
      });
    } else {
      window.location.href = '/report/japanese';
    }
  };

  return (
    <Grid className="root" container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress />
      </Backdrop>
      <Container className={classes.white}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <ChevronLeft style={{ color: '#4BB543' }} />
          <span
            style={{
              color: '#4BB543',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={back}
          >
            {params.sampleId ? 'Back to Sample Details' : 'Back to Test List'}
          </span>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid className={classes.padding} xs={12} item>
            <h2 className={classes.label}>Test</h2>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Test Status</b>
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.label}
                id="controllable-states-demo"
                options={defaultLabel}
                renderInput={(paramsDl) => (
                  <TextField {...paramsDl} size="small" value={user.testStatus} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ label: labels, value: user.testStatus }}
                onChange={(event, newValue: Countrys | null) => {
                  if (newValue) {
                    const tmp = { ...user };
                    tmp.testStatus = newValue.value;
                    setLabels(newValue.label);
                    setUser(tmp);
                  }
                }}
              />
            </FormControl>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Technician</b>
            <b className="red-star">*</b>
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.userName}
                id="controllable-states-demo"
                options={userList}
                renderInput={(paramsUl) => (
                  <TextField {...paramsUl} size="small" value={user.technician} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ id: user.technician, userName: userNames }}
                onChange={(event, newValue: Users | null) => {
                  if (newValue) {
                    const tmp = { ...user };
                    tmp.technician = newValue.id;
                    setUserNames(newValue.userName);
                    setUser(tmp);
                  }
                }}
              />
            </FormControl>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Instrument Id</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              placeholder="InstrumentId"
              size="small"
              value={user.instrumentId}
              variant="outlined"
              required
              onChange={(event) => {
                const tmp = { ...user };
                tmp.instrumentId = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Assay Id</b>
            <b className="red-star">*</b>
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.title}
                id="controllable-states-demo"
                options={assayId}
                renderInput={(paramsAi) => (
                  <TextField {...paramsAi} size="small" value={user.assayId} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ id: user.assayId, title: assayIds }}
                onChange={(event, newValue: Assay | null) => {
                  if (newValue) {
                    const tmp = { ...user };
                    tmp.assayId = newValue.id;
                    setAssayIds(newValue.title);
                    setUser(tmp);
                  }
                }}
              />
            </FormControl>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Test Location</b>
            <b className="red-star">*</b>
            <br />
            <FormControl className={classes.width} size="small">
              <Select
                value={user.testLocation}
                variant="outlined"
                required
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.testLocation = event.target.value as string;
                  setUser(tmp);
                }}
              >
                <MenuItem value="lab1">Lab 1</MenuItem>
                <MenuItem value="lab2">Lab 2</MenuItem>
                <MenuItem value="lab3">Lab 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Kit Lot #</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              placeholder="Kit Lot #"
              size="small"
              value={user.kitLot}
              variant="outlined"
              required
              onChange={(event) => {
                const tmp = { ...user };
                tmp.kitLot = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Start Date</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              size="small"
              type="datetime-local"
              value={moment(user.startDate).format('YYYY-MM-DDThh:mm')}
              // value={moment(user.startDate).format('YYYY-MM-DD')}
              variant="outlined"
              required
              onChange={(event) => {
                const tmp = { ...user };
                tmp.startDate = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Complete Date</b>
            <TextField
              className={classes.width}
              inputProps={{
                min: moment(user.startDate).format('YYYY-MM-DDThh:mm')
              }}
              size="small"
              type="datetime-local"
              value={moment(user.completedDate).format('YYYY-MM-DDThh:mm')}
              // value={moment(user.completedDate).format('YYYY-MM-DD')}
              variant="outlined"
              onChange={(event) => {
                const tmp = { ...user };
                tmp.completedDate = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Tags</b>
            <TextField
              className={classes.width}
              placeholder="Tags"
              size="small"
              value={user.tags}
              variant="outlined"
              onChange={(event) => {
                const tmp = { ...user };
                tmp.tags = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          {!params.sampleId ? (
            <Grid className={classes.padding} xs={12} item>
              <b className={classes.label}>Results</b>
              <b className="red-star">*</b>
              <FormControl className={classes.width} size="small">
                <Autocomplete
                  getOptionLabel={(option) => option.label}
                  id="controllable-states-demo"
                  options={Results}
                  renderInput={(paramsR) => (
                    <TextField {...paramsR} size="small" value={user.results} variant="outlined" required />
                  )}
                  style={{ width: 100 + '%' }}
                  value={{ label: res, value: user.results }}
                  onChange={(event, newValue: Countrys | null) => {
                    if (newValue) {
                      const tmp = { ...user };
                      tmp.results = newValue.value;
                      setRes(newValue.label);
                      setUser(tmp);
                    }
                  }}
                />
              </FormControl>
            </Grid>
          ) : (
            ''
          )}

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Comments</b>
            <TextField
              className={classes.width}
              placeholder="Comments"
              rows={6}
              size="small"
              value={user.comments}
              variant="outlined"
              multiline
              onChange={(event) => {
                const tmp = { ...user };
                tmp.comments = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Reviewed By</b>
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.userName}
                id="controllable-states-demo"
                options={userList}
                renderInput={(paramsU) => (
                  <TextField {...paramsU} size="small" value={user.reviewedBy} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ id: user.reviewedBy, userName: reviewedBy }}
                onChange={(event, newValue: Users | null) => {
                  if (newValue) {
                    const tmp = { ...user };
                    tmp.reviewedBy = newValue.id;
                    setReviewedBy(newValue.userName);
                    setUser(tmp);
                  }
                }}
              />

              {/* <Select
                value={user.reviewedBy}
                variant="outlined"
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.reviewedBy = event.target.value as string;
                  setUser(tmp);
                }}
              >
                {userList.map((rev) => {
                  return (
                    <MenuItem key={rev.id} value={rev.id}>
                      {rev.id}. {rev.userName}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Reviewed Date</b>
            <TextField
              className={classes.width}
              placeholder="Reviewed Date"
              size="small"
              type="date"
              value={moment(user.reviewedDate).format('YYYY-MM-DD')}
              variant="outlined"
              onChange={(event) => {
                const tmp = { ...user };
                tmp.reviewedDate = event.target.value;
                setUser(tmp);
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Report Type</b>
            <FormControl className={classes.width} size="small">
              <Autocomplete
                getOptionLabel={(option) => option.name}
                id="controllable-states-demo"
                options={reportType}
                renderInput={(paramsRt) => <TextField {...paramsRt} size="small" variant="outlined" />}
                style={{ width: 100 + '%' }}
                onChange={(event, newValue: Reports | null) => {
                  if (newValue) {
                    setReportType(newValue.type);
                  }
                }}
              />

              {/* <Select
                value={user.reviewedBy}
                variant="outlined"
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  const tmp = { ...user };
                  tmp.reviewedBy = event.target.value as string;
                  setUser(tmp);
                }}
              >
                {userList.map((rev) => {
                  return (
                    <MenuItem key={rev.id} value={rev.id}>
                      {rev.id}. {rev.userName}
                    </MenuItem>
                  );
                })}
              </Select> */}
            </FormControl>
          </Grid>
          <Grid className={classes.padding} container>
            <Box mr={2}>
              <SaveButton type="submit" variant="contained">
                Save
              </SaveButton>
            </Box>
            {params.id && (
              <>
                <Box mr={2}>
                  <CloneButton color="primary" variant="contained" onClick={handleTestRelease}>
                    Release Results
                  </CloneButton>
                </Box>
                <Box mr={2}>
                  <Report
                    color="primary"
                    disabled={reportTypes.length ? false : true}
                    variant="contained"
                    onClick={() => {
                      setReportLoader(true);
                      onGenerateReportPdf();
                    }}
                  >
                    {reportLoader ? <CircularProgress color="inherit" size={25} /> : 'Download Report'}
                  </Report>
                </Box>
                <Box mr={2}>
                  <DeleteButton variant="contained" onClick={handleClickOpen}>
                    Delete
                  </DeleteButton>
                </Box>
                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={open}
                  onClose={handleCloseAlert}
                >
                  <DialogTitle id="alert-dialog-title"> Delete alert</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the record
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={handleCloseAlert}>
                      Cancel
                    </Button>
                    <Button color="secondary" autoFocus onClick={handleDelete}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
        </form>
      </Container>
    </Grid>
  );
}
