import { Dialog, DialogTitle, Grid, Box, Button } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

import { DialogProps, ShowFields } from './Models';
import { useStyles1 } from './styles';

export default function StyledDialog(props: DialogProps): React.ReactElement {
  const classes = useStyles1();
  const { open, onClose } = props;

  const [id, setId] = React.useState(props.selectedValue.id);

  const [title, setTitle] = React.useState(props.selectedValue.title);
  const [method, setMethod] = React.useState(props.selectedValue.method);
  const [category, setCategory] = React.useState(props.selectedValue.category);
  const [defaultTechnician, setDefaultTechnician] = React.useState(props.selectedValue.defaultTechnician);
  const [team, setTeam] = React.useState(props.selectedValue.team);
  const [tags, setTags] = React.useState(props.selectedValue.tags);
  const [isActive, setIsActive] = React.useState(props.selectedValue.isActive);
  const [showInCustomerPortal, setShowInCustomerPortal] = React.useState(props.selectedValue.showInCustomerPortal);

  const handleClose = (prop: boolean, arg?: ShowFields) => {
    onClose(arg);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="xs"
      open={open}
      fullWidth
      onClose={() => {
        handleClose(true, {
          id,
          // assayId,
          title,
          method,
          category,
          defaultTechnician,
          team,
          tags,
          isActive,
          showInCustomerPortal
        });
      }}
    >
      <Grid className={classes.padding} justify="center" container>
        <DialogTitle className={classes.widthparent} id="simple-dialog-title">
          View
        </DialogTitle>

        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={id ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={id}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setId(!id);
                  }}
                />
              </Grid>
            </Grid>

            <Grid xs={12} item>
              <Grid className={title ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Title</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={title}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setTitle(!title);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={method ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Method </b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={method}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setMethod(!method);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={category ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Category</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={category}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setCategory(!category);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid
                className={defaultTechnician ? classes.cells : classes.hiddenCells}
                justify="space-between"
                container
              >
                <Grid className={classes.heading} item>
                  <b>Default Technician</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={defaultTechnician}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setDefaultTechnician(!defaultTechnician);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={team ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Default Report</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={team}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setTeam(!team);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={tags ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Tags </b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={tags}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setTags(!tags);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid className={isActive ? classes.cells : classes.hiddenCells} justify="space-between" container>
                <Grid className={classes.heading} item>
                  <b>Is Active</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={isActive}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setIsActive(!isActive);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Grid
                className={showInCustomerPortal ? classes.cells : classes.hiddenCells}
                justify="space-between"
                container
              >
                <Grid className={classes.heading} item>
                  <b>Show In Customer Portal</b>
                </Grid>
                <Grid item>
                  <Switch
                    checked={showInCustomerPortal}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    name="checkedB"
                    onChange={() => {
                      setShowInCustomerPortal(!showInCustomerPortal);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box>
              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={function () {
                  handleClose(true, {
                    id,
                    // assayId,
                    title,
                    method,
                    category,
                    defaultTechnician,
                    team,
                    tags,
                    isActive,
                    showInCustomerPortal
                  });
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
