import { Dialog, DialogTitle, Grid, Box, Button } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

import { DialogProps, ShowFields } from './Models';
import { useStyles1 } from './styles';

export default function StyledDialog(props: DialogProps): React.ReactElement {
  const classes = useStyles1();
  const { open, onClose } = props;
  const [description, setDescription] = React.useState(props.selectedValue.description);
  const [sampleCount, setSampleCount] = React.useState(props.selectedValue.sampleCount);
  const [createdAt, setCreatedAt] = React.useState(props.selectedValue.createdAt);

  const [id, setId] = React.useState(props.selectedValue.id);
  const [name, setName] = React.useState(props.selectedValue.name);

  const handleClose = (prop: boolean, arg?: ShowFields) => {
    onClose(arg);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="xs"
      open={open}
      fullWidth
      onClose={() => {
        handleClose(true, {
          id,
          name,
          description,
          sampleCount,
          createdAt
        });
      }}
    >
      <Grid className={classes.padding} justify="center" container>
        <DialogTitle className={classes.widthparent} id="simple-dialog-title">
          View
        </DialogTitle>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={createdAt ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Created At</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={createdAt}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setCreatedAt(!createdAt);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={sampleCount ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>SampleCount</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={sampleCount}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setSampleCount(!sampleCount);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={description ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Description</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={description}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setDescription(!description);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={id ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Id</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={id}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setId(!id);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={name ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Name</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={name}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setName(!name);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid xs={10} item>
          <Box m={1}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={function () {
                handleClose(true, {
                  id,
                  name,
                  description,
                  sampleCount,
                  createdAt
                });
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
