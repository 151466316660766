import { config } from '../../../config';

import { tabs } from './data';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addCustomeFields = async (
  id: number,
  customField: string,
  type: string,
  defaultValue: string,
  isRequried: string
) => {
  return await fetch(config.baseUrl + config.apiURL.customAddCustomField, {
    method: 'POST',
    body: JSON.stringify({
      moduleName: tabs[id].moduleName,
      customField,
      type,
      defaultValue,
      isRequried
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCustomFields = async (id: number) => {
  return await fetch(config.baseUrl + config.apiURL.customGetModuleCustomFields, {
    method: 'POST',
    body: JSON.stringify({
      moduleName: tabs[id].moduleName
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
