import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Props } from './Models';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  border: {
    border: '2px solid rgb(1,1,1,0.3)',
    borderRadius: '5px',
    padding: '10px',
    width: 'max-content'
  }
});

export default function SampleDetails(props: Props): React.ReactElement {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell padding="default">SampleId</TableCell>
            <TableCell padding="default">FirstName</TableCell>
            <TableCell padding="default">LastName</TableCell>
            <TableCell padding="default">Address</TableCell>
            <TableCell padding="default">Sex</TableCell>
            <TableCell padding="default">State</TableCell>
            <TableCell padding="default">ZipCode</TableCell>
            <TableCell padding="default">City</TableCell>
            <TableCell padding="default">Country</TableCell>
            <TableCell padding="default">BirthDate</TableCell>
            <TableCell padding="default">Assigning Authority</TableCell>
            <TableCell padding="default">AccessionId</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="left" padding="default">
                <Link className={classes.border} to={'/center/samples/sampleDetail/' + row.id}>
                  {row.id}
                </Link>
              </TableCell>
              <TableCell align="left" padding="default">
                {row.firstName}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.lastName}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.address}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.sex}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.state}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.zipCode}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.city}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.country}
              </TableCell>
              <TableCell align="left" padding="default">
                {moment(row.birthDate).format('DD-MM-YYYY')}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.assigningAuthority}
              </TableCell>
              <TableCell align="left" padding="default">
                {row.accessionId}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
