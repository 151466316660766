import axios from 'axios';

import { config } from '../../config';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDashBoardLabStats = async () => {
  return await axios({
    url: config.baseUrl + config.apiURL.dashboardLabStats,
    method: 'post',
    headers: {
      'Content-Type': config.contentType,
      Authorization: window.localStorage.limsKey
    }
  }).then((res) => {
    if (res.data) {
      return res;
    }

    return res;
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDashBoardMyLabStats = async () => {
  return await axios({
    url: config.baseUrl + config.apiURL.dashboardMyStats,
    method: 'post',
    data: {
      userId: window.localStorage.userId
    },
    headers: {
      'Content-Type': config.contentType,
      Authorization: window.localStorage.limsKey
    }
  }).then((res) => {
    if (res.data) {
      return res;
    }

    return res;
  });
};
