import { Dialog, DialogTitle, Grid, Box, Button } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

import { DialogProps, ShowFields } from './Models';
import { useStyles1 } from './styles';

export default function StyledDialog(props: DialogProps): React.ReactElement {
  const classes = useStyles1();
  const { open, onClose } = props;
  const [name, setName] = React.useState(props.selectedValue.name);
  const [title, setTitle] = React.useState(props.selectedValue.title);
  const [address, setAddress] = React.useState(props.selectedValue.address);
  const [id] = React.useState(props.selectedValue.id);
  const [customerAccountId] = React.useState(props.selectedValue.customerAccountId);
  const [customerAbbreviation, setCustomerAbbreviation] = React.useState(props.selectedValue.customerAbbreviation);
  const [phone, setPhone] = React.useState(props.selectedValue.phone);
  const [fax, setFax] = React.useState(props.selectedValue.fax);
  const [defaultReport, setDefaultReport] = React.useState(props.selectedValue.defaultReport);
  const [defaultSampleLabel, setDefaultSampleLabel] = React.useState(props.selectedValue.defaultSampleLabel);
  const [poNumber, setPoNumber] = React.useState(props.selectedValue.poNumber);
  const [specialInstructions, setSpecialInstructions] = React.useState(props.selectedValue.specialInstructions);
  const [comments, setComments] = React.useState(props.selectedValue.comments);
  const [deleteStatus] = React.useState(props.selectedValue.deleteStatus);

  const handleClose = (prop: boolean, arg?: ShowFields) => {
    onClose(arg);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="xs"
      open={open}
      fullWidth
      onClose={() => {
        handleClose(true, {
          id,
          customerAccountId,
          name,
          title,
          address,
          customerAbbreviation,
          phone,
          fax,
          defaultReport,
          defaultSampleLabel,
          poNumber,
          specialInstructions,
          comments,
          deleteStatus
        });
      }}
    >
      <Grid className={classes.padding} justify="center" container>
        <DialogTitle className={classes.widthparent} id="simple-dialog-title">
          View
        </DialogTitle>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={name ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Name</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={name}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setName(!name);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={title ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Title</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={title}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setTitle(!title);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={address ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Address</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={address}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setAddress(!address);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid
              className={customerAbbreviation ? classes.cells : classes.hiddenCells}
              justify="space-between"
              container
            >
              <Grid className={classes.heading} item>
                <b>Customer Abbreviation</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={customerAbbreviation}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setCustomerAbbreviation(!customerAbbreviation);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={phone ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Phone</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={phone}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setPhone(!phone);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={fax ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Fax</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={fax}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setFax(!fax);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={defaultReport ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Default Report</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={defaultReport}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setDefaultReport(!defaultReport);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid
              className={defaultSampleLabel ? classes.cells : classes.hiddenCells}
              justify="space-between"
              container
            >
              <Grid className={classes.heading} item>
                <b>Default Sample Label</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={defaultSampleLabel}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setDefaultSampleLabel(!defaultSampleLabel);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={poNumber ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>PO Number</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={poNumber}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setPoNumber(!poNumber);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid
              className={specialInstructions ? classes.cells : classes.hiddenCells}
              justify="space-between"
              container
            >
              <Grid className={classes.heading} item>
                <b>Special Instructions</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={specialInstructions}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setSpecialInstructions(!specialInstructions);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={10} item>
          <Box ml={1} mr={1}>
            <Grid className={comments ? classes.cells : classes.hiddenCells} justify="space-between" container>
              <Grid className={classes.heading} item>
                <b>Comments</b>
              </Grid>
              <Grid item>
                <Switch
                  checked={comments}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name="checkedB"
                  onChange={() => {
                    setComments(!comments);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={function () {
                handleClose(true, {
                  id,
                  customerAccountId,
                  name,
                  title,
                  address,
                  customerAbbreviation,
                  phone,
                  fax,
                  defaultReport,
                  defaultSampleLabel,
                  poNumber,
                  specialInstructions,
                  comments,
                  deleteStatus
                });
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
}
