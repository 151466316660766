import React from 'react';
import './styles.css';
import clsx from 'clsx';
import {
  Paper,
  Grid,
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Checkbox,
  Tooltip,
  Snackbar,
  Button
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';

import { fetchData, multipleDeletes, submit } from './api';
import { Data, EnhancedTableProps, HeadCell, EnhancedTableToolbarProps, Order, Toast, NewMethod } from './Models';
import {
  useStyles,
  SaveButton,
  useToolbarStyles,
  useStylesTable,
  StyledTablePagination,
  StyledPagination
} from './Styles';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
  { id: 'name', numeric: true, disablePadding: false, label: 'Name' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' }
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={rowCount > 0 && numSelected === rowCount}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            inputProps={{ 'aria-label': 'select all desserts' }}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, setDelToast } = props;
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMultipleDeletes = () => {
    setLoader(true);
    multipleDeletes(selected)
      .then(async (response: Response) => {
        setOpen(false);
        setLoader(false);
        const data = await response.json();

        if (data.status === 200) {
          setDelToast(data.message, 1);
        } else {
          setDelToast(data.message, 0);
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setDelToast(err.message, 0);
      });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 && (
        <Typography className={classes.title} color="inherit" component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          {loader ? (
            <CircularProgress />
          ) : (
            <>
              <IconButton aria-label="delete" onClick={handleClickOpen}>
                <DeleteIcon />
              </IconButton>
              <Dialog
                aria-describedby="alert-dialog-description"
                aria-labelledby="alert-dialog-title"
                open={open}
                onClose={handleClose}
              >
                <DialogTitle id="alert-dialog-title">Delete all</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete the selected {numSelected} rows
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button color="primary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button color="secondary" autoFocus onClick={handleMultipleDeletes}>
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Tooltip>
      )}
    </Toolbar>
  );
};

const CollectionMethods: React.FC = () => {
  const classesCustom = useStyles();
  const classes = useStylesTable();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [rows, setRows] = React.useState<Data[]>([]);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: ''
  });
  const [count, setCount] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const [loaderAdd, setLoaderAdd] = React.useState(false);

  const [successDelete, setSuccessDelete] = React.useState(false);
  const [newMethod, setNewMethod] = React.useState<NewMethod>({
    name: '',
    description: ''
  });
  const [editMethod, setEditMethod] = React.useState<{ id: string; name: string; description: string; open: boolean }>({
    name: '',
    description: '',
    id: '',
    open: false
  });
  const [successSubmit, setSuccessSubmit] = React.useState(false);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose2 = () => {
    setToast({ ...toast, open: false });
  };

  React.useEffect(() => {
    setLoader(true);
    fetchData(rowsPerPage, page + 1)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);

        if (data.status === 200) {
          setCount(data.data.count);
          setRows(data.data.list);

          if (data.data.list.length === 0) {
            setToast({ open: true, severity: 0, message: 'No Data Found' });
          }
        } else {
          setToast({ open: true, severity: 0, message: data.message });
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ open: true, severity: 0, message: err.message });
      });
  }, [page, rowsPerPage, successDelete, successSubmit]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleEditMethod = (id: string, name: string, description: string) => {
    setEditMethod({
      id,
      name,
      description,
      open: true
    });
  };

  const handleCloseEdit = () => {
    setEditMethod({
      id: '',
      name: '',
      description: '',
      open: false
    });
  };

  const handleSubmit = async (modify: boolean) => {
    setLoaderAdd(true);
    const body = modify ? { ...editMethod } : { ...newMethod };
    submit({ ...body })
      .then(async (response: Response) => {
        const message = await response.json();
        setSuccessSubmit(!successSubmit);
        setLoaderAdd(false);
        handleCloseEdit();
        setNewMethod({
          name: '',
          description: ''
        });
        setToast({
          ...toast,
          open: true,
          severity: message.status === 200 ? 1 : 0,
          message: message.message
        });
      })
      .catch((err: Error) => {
        handleCloseEdit();
        setNewMethod({
          name: '',
          description: ''
        });
        setLoaderAdd(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message });
      });
  };

  return (
    <div>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={toast.open} onClose={handleClose2}>
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose2}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Grid justify="center" container>
        <Grid xs={12} item>
          <Box p={2}>
            <Paper elevation={5}>
              <Box p={3}>
                <Grid className={classesCustom.padding} xs={12} item>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    New Collection Methods
                  </Typography>
                  <Divider />
                  <Box pt={2}>
                    <form
                      onSubmit={(event: React.SyntheticEvent) => {
                        event.preventDefault();
                        handleSubmit(false);
                      }}
                    >
                      <Grid className={classesCustom.padding} xs={12} item>
                        <b className={classesCustom.label}> Name</b>
                        <b className="red-star">*</b>
                        <TextField
                          className={classesCustom.width}
                          color="primary"
                          disabled={loaderAdd}
                          placeholder="Name"
                          size="small"
                          value={newMethod.name}
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(event) => {
                            if (event.target.value.replace(/\s/g, '').length > 0) {
                              const tmp = { ...newMethod };
                              tmp.name = event.target.value;
                              setNewMethod(tmp);
                            }
                          }}
                        />
                      </Grid>
                      <Grid className={classesCustom.padding} xs={12} item>
                        <b className={classesCustom.label}>Description</b>
                        <b className="red-star">*</b>
                        <TextField
                          className={classesCustom.width}
                          color="primary"
                          disabled={loaderAdd}
                          placeholder="Description"
                          size="small"
                          value={newMethod.description}
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(event) => {
                            if (event.target.value.replace(/\s/g, '').length > 0) {
                              const tmp = { ...newMethod };

                              tmp.description = event.target.value;

                              setNewMethod(tmp);
                            }
                          }}
                        />
                      </Grid>
                      <Grid className={classesCustom.padding} container>
                        <Box mr={2}>
                          <SaveButton type="submit" variant="contained">
                            {loaderAdd ? <CircularProgress color="inherit" size={25} /> : '+ Add'}
                          </SaveButton>
                        </Box>
                      </Grid>
                    </form>
                  </Box>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Box p={2}>
            <Paper elevation={5}>
              <Box p={3}>
                <Typography color="textSecondary" variant="h5" gutterBottom>
                  Collection Methods
                </Typography>
                <Divider />
                <Box pt={2}>
                  <div className={classes.root}>
                    <Paper className={classes.paper} elevation={0}>
                      <EnhancedTableToolbar
                        numSelected={selected.length}
                        selected={selected}
                        setDelToast={(message: string, severity: number) => {
                          setToast({ open: true, message, severity });

                          if (severity) {
                            setSuccessDelete(!successDelete);
                            setSelected([]);
                          }
                        }}
                      />
                      <Grid justify="flex-end" container>
                        <Grid item>
                          <StyledTablePagination
                            ActionsComponent={() => {
                              return (
                                <Grid direction="row" container>
                                  <Grid item>
                                    <StyledPagination
                                      boundaryCount={1}
                                      className={classesCustom.widthparent}
                                      color="primary"
                                      count={Math.ceil(count / rowsPerPage)}
                                      page={page + 1}
                                      shape="rounded"
                                      siblingCount={0}
                                      variant="outlined"
                                      showFirstButton
                                      showLastButton
                                      onChange={(e, p) => {
                                        handleChangePage(e, p - 1);
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            }}
                            className={classesCustom.widthparent}
                            count={count}
                            labelRowsPerPage=""
                            page={page}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 50, 100]}
                            // onChangePage={handleChangePage}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                      </Grid>

                      <TableContainer>
                        <Table
                          aria-label="enhanced table"
                          aria-labelledby="tableTitle"
                          className={classes.table}
                          size={dense ? 'small' : 'medium'}
                        >
                          <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            rowCount={rows.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                          />
                          <TableBody>
                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                              const isItemSelected = isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${index}`;

                              return (
                                <TableRow
                                  key={row.id}
                                  aria-checked={isItemSelected}
                                  role="checkbox"
                                  tabIndex={-1}
                                  hover
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}
                                      color="primary"
                                      inputProps={{ 'aria-labelledby': labelId }}
                                      onClick={(event) => handleClick(event, row.id)}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className={classesCustom.grey}>
                                    {row.id}
                                  </TableCell>

                                  <TableCell align="left">
                                    <Button
                                      className={clsx('list', classesCustom.list)}
                                      onClick={() => {
                                        handleEditMethod(row.id, row.name, row.description);
                                      }}
                                    >
                                      <Box>
                                        <Grid justify="space-between" container>
                                          <Grid item>{row.name}</Grid>
                                        </Grid>
                                      </Box>
                                    </Button>
                                  </TableCell>
                                  <TableCell align="left" className={classesCustom.grey}>
                                    {row.description}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </Box>
              </Box>
            </Paper>
            {!loader ? (
              <></>
            ) : (
              <Box mt={2}>
                <Grid xs={11}>
                  <Grid justify="center" container>
                    <Grid item>
                      <br />
                      <CircularProgress />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={editMethod.open}
        onClose={handleCloseEdit}
      >
        <form
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
            handleSubmit(true);
          }}
        >
          <DialogTitle id="alert-dialog-title">Modify Batch</DialogTitle>
          <DialogContent>
            <Grid className={classesCustom.padding} xs={12} item>
              <b className={classesCustom.label}>Name</b>
              <b className="red-star">*</b>
              <TextField
                className={classesCustom.width}
                color="primary"
                disabled={loaderAdd}
                placeholder="New Name"
                size="small"
                value={editMethod.name}
                variant="outlined"
                fullWidth
                required
                onChange={(event) => {
                  const tmp = { ...editMethod };

                  tmp.name = event.target.value;

                  setEditMethod(tmp);
                }}
              />
            </Grid>
            <Grid className={classesCustom.padding} xs={12} item>
              <b className={classesCustom.label}>Description</b>
              <b className="red-star">*</b>
              <TextField
                className={classesCustom.width}
                color="primary"
                disabled={loaderAdd}
                placeholder="New Description"
                size="small"
                value={editMethod.description}
                variant="outlined"
                fullWidth
                required
                onChange={(event) => {
                  const tmp = { ...editMethod };

                  tmp.description = event.target.value;

                  setEditMethod(tmp);
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseEdit}>
              Cancel
            </Button>
            <Button color="secondary" type="submit">
              {loaderAdd ? <CircularProgress color="inherit" size={25} /> : 'Modify'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CollectionMethods;
