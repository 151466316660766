import React from 'react';
import clsx from 'clsx';
import { Grid, TextField, Container, FormControl, Box, Button, Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useParams, useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import moment from 'moment';

import { useStyles, SaveButton, DeleteButton } from './Styles';
import { Order, Params, Toast, Customers, Samples, Tests } from './Models';
import { fetchData, submit, del, customerDropdown, cancel, rescheduleOrder } from './api';
import SampleDetails from './SampleDetails';
import TestsLists from './TestsLists';

import './OrderDetails.css';

type Countrys = {
  label: string;
  value: string;
};

export default function OrderDetails(): React.ReactElement {
  const params = useParams<Params>();
  const history = useHistory();
  const classes = useStyles();
  const [customers, setCustomers] = React.useState<Customers[]>([
    {
      id: '',
      name: '',
      address: '',
      fax: '',
      specialInstructions: '',
      customerAccountId: ''
    }
  ]);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });
  const [order, setOrder] = React.useState<Order>({
    customerAccountId: '',
    email: '',
    appointmentDate: '',
    dateReceived: '',
    XExternalOrderNumber: '',
    XSpecimenCount: '',
    specialInstructions: '',
    priority: '',
    travelType: '',
    destination: '',
    isCancelled: ''
  });
  const [samples, setSamples] = React.useState<Samples[]>([]);
  const [tests, setTests] = React.useState<Tests[]>([]);
  const [toggleO, setToggleO] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [cancelOrder, setCancelOrder] = React.useState(false);
  const [reschedule, setReschedule] = React.useState(false);
  const [newAppointment, setNewAppointment] = React.useState<string | undefined>(undefined);

  const defaultLabel = [
    { label: 'Domestic', value: 'DOM' },
    { label: 'International', value: 'INT' }
  ];
  const [labels, setLabels] = React.useState('');
  const [id, setId] = React.useState('');
  const [name, setName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [specialInstructions, setSpecialInstructions] = React.useState('');
  const [fax, setFax] = React.useState('');

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });

    if (toast.redirect) {
      history.push('/center/orders');
    }
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoader(true);
    const body = { ...order, id: params.id };
    delete body.samples;
    delete body.tests;

    submit(params, JSON.stringify(body))
      .then(async (response: Response) => {
        const message = await response.json();
        let redirect = false;
        let loaders = false;

        if (message.status === 200) {
          redirect = true;
          loaders = true;
        }
        setLoader(loaders);
        setToast({
          ...toast,
          open: true,
          severity: message.status === 200 ? 1 : 0,
          message: message.message,
          redirect
        });
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleDelete = () => {
    setOpen(false);
    setLoader(true);
    del(+params.id)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(true);
        setToast({
          ...toast,
          open: true,
          severity: data.status === 200 ? 1 : 0,
          message: data.message,
          redirect: data.status === 200 ? true : false
        });
      })
      .catch((err) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setLoader(true);
    cancel(+params.id)
      .then(async function (response: Response) {
        const data = await response.json();
        setLoader(true);
        setToast({
          ...toast,
          open: true,
          severity: data.status === 200 ? 1 : 0,
          message: data.message,
          redirect: data.status === 200 ? true : false
        });
      })
      .catch((err) => {
        setLoader(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const handleReschedule = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (newAppointment) {
      setReschedule(false);
      setOpen(false);
      setLoader(true);
      rescheduleOrder(+params.id, newAppointment)
        .then(async function (response: Response) {
          const data = await response.json();
          setLoader(true);
          setToast({
            ...toast,
            open: true,
            severity: data.status === 200 ? 1 : 0,
            message: data.message,
            redirect: data.status === 200 ? true : false
          });
        })
        .catch((err) => {
          setLoader(false);
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    }
  };

  React.useEffect(() => {
    setLoader(true);
    customerDropdown()
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);

        if (data.status === 200) {
          setCustomers(data.data);
        } else {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
        }
      })
      .catch((err: Error) => {
        setLoader(false);

        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });

    if (params.id && customers) {
      fetchData(+params.id)
        .then(async (response: Response) => {
          const data = await response.json();

          if (data.status === 200) {
            const cId = data.data.customerAccountId;
            customerDropdown()
              .then(async (responses: Response) => {
                const datas = await responses.json();

                if (datas.status === 200) {
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  const names = datas.data.find((e: any) => e.customerAccountId === cId) as Customers;
                  setName(names.name);
                } else {
                  setToast({ ...toast, open: true, severity: 0, message: datas.message, redirect: false });
                }
              })
              .catch((err: Error) => {
                setLoader(false);

                setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
              });
            setOrder(data.data);

            setSamples(data.data.samples);
            setTests(data.data.tests);

            const labelId = defaultLabel.find((e) => e.value === data.data.travelType) as Countrys;
            setLabels(labelId.label);
          } else {
            setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
          }
        })
        .catch(function (err: Error) {
          setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
        });
    }
  }, []);

  const back = () => {
    history.push('/center/orders');
  };

  return (
    <Grid className="root" container>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        className={classes.snackBar}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress />
      </Backdrop>
      <Container className={classes.white}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <ChevronLeftIcon
            style={{
              color: '#4BB543'
            }}
          />
          <span
            style={{
              color: '#4BB543',

              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onClick={back}
          >
            Back to Order List
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          {params.id && (
            <>
              {!+order.isCancelled ? (
                <>
                  <Grid justify="flex-end" container>
                    <Grid item>
                      <SaveButton
                        style={{ marginTop: '-40px', cursor: 'pointer' }}
                        variant="contained"
                        onClick={() =>
                          history.push('/center/samples/sampleDetail/orderId/' + params.id + '/' + order.destination)
                        }
                      >
                        + New Sample
                      </SaveButton>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid justify="flex-end" container>
                  <Grid item>
                    <SaveButton
                      style={{ marginTop: '-40px', cursor: 'pointer' }}
                      variant="contained"
                      disabled
                      onClick={() =>
                        history.push('/center/samples/sampleDetail/orderId/' + params.id + '/' + order.destination)
                      }
                    >
                      + New Sample
                    </SaveButton>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <Grid className={classes.padding} xs={12} item>
            <h2 className={classes.label}>Order </h2>
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Customer</b>
            <b className="red-star">*</b>
            <br />
            <FormControl className={classes.width} size="small" required>
              <Autocomplete
                getOptionLabel={(option) => option.name}
                id="controllable-states-demo"
                options={customers}
                renderInput={(param) => (
                  <TextField {...param} size="small" value={order.customerAccountId} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{
                  id,
                  name,
                  address,
                  fax,
                  specialInstructions,
                  customerAccountId: order.customerAccountId
                }}
                onChange={(event, newValue: Customers | null) => {
                  if (newValue) {
                    const tmp = { ...order };
                    tmp.customerAccountId = newValue.customerAccountId;
                    setId(newValue.id);
                    setName(newValue.name);
                    setAddress(newValue.address);
                    setFax(newValue.fax);
                    setSpecialInstructions(newValue.specialInstructions);
                    setOrder(tmp);
                  }
                }}
              />
            </FormControl>
          </Grid>
          {params.id && (
            <Grid className={classes.padding} xs={12} item>
              <b className={classes.label}>Date Received</b>
              <TextField
                className={classes.width}
                size="small"
                type={params.id ? 'text' : 'datetime-local'}
                value={moment(order.dateReceived).format('DD-MM-YYYY')}
                variant="outlined"
                disabled
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setOrder({ ...order, dateReceived: event.target.value as string });
                }}
              />
            </Grid>
          )}
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>External Order Number</b>
            <TextField
              className={classes.width}
              placeholder="External Order Number"
              size="small"
              value={order.XExternalOrderNumber}
              variant="outlined"
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setOrder({ ...order, XExternalOrderNumber: event.target.value as string });
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Specimen Count</b>
            <TextField
              className={classes.width}
              placeholder="Specimen Count"
              size="small"
              type="number"
              value={order.XSpecimenCount}
              variant="outlined"
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                if (+event.target.value >= 0 && event.target.value !== '-') {
                  setOrder({ ...order, XSpecimenCount: event.target.value as string });
                }
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Email To</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              size="small"
              type="email"
              value={order.email}
              variant="outlined"
              required
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setOrder({ ...order, email: event.target.value as string });
              }}
            />
          </Grid>

          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Appointment Date</b>
            <br />
            {/* <TextField
              className={classes.width}
              disabled={params.id ? true : false}
              size="small"
              type={params.id ? 'text' : 'datetime-local'}
              value={params.id ? moment(order.appointmentDate).format('DD-MM-YYYY') : order.appointmentDate}
              variant="outlined"
              fullWidth
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setOrder({ ...order, appointmentDate: event.target.value as string });
              }}
            /> */}
            <input
              className="picker"
              disabled={params.id ? true : false}
              type={params.id ? 'text' : 'datetime-local'}
              value={params.id ? moment(order.appointmentDate).format('DD-MM-YYYY') : order.appointmentDate}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setOrder({ ...order, appointmentDate: event.target.value as string });
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Special Instructions</b>
            <TextField
              className={classes.width}
              placeholder="Special Instructions"
              rows={6}
              size="small"
              value={order.specialInstructions}
              variant="outlined"
              multiline
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setOrder({ ...order, specialInstructions: event.target.value as string });
              }}
            />
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <Button
              className={clsx(classes.width, classes.btn)}
              onClick={() => {
                setToggleO(!toggleO);
              }}
            >
              <b className={classes.label}>ADDITIONAL ORDER INFO</b>
            </Button>
          </Grid>
          {toggleO && (
            <div className="dropdown">
              <Grid className={classes.padding} xs={12} item>
                <b className={classes.label}>Priority</b>
                <TextField
                  className={classes.width}
                  placeholder="Priority"
                  size="small"
                  value={order.priority}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setOrder({ ...order, priority: event.target.value as string });
                  }}
                />
              </Grid>
            </div>
          )}
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Travel Type</b>
            <b className="red-star">*</b>
            <FormControl className={classes.width} size="small" required>
              <Autocomplete
                getOptionLabel={(option) => option.label}
                id="controllable-states-demo"
                options={defaultLabel}
                renderInput={(paramses) => (
                  <TextField {...paramses} size="small" value={order.travelType} variant="outlined" required />
                )}
                style={{ width: 100 + '%' }}
                value={{ label: labels, value: order.travelType }}
                onChange={(event, newValue: Countrys | null) => {
                  if (newValue) {
                    const tmp = { ...order };
                    tmp.travelType = newValue.value;
                    setLabels(newValue.label);
                    setOrder(tmp);
                  }
                }}
              />
              {/* <Select
                value={order.travelType}
                variant="outlined"
                required
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setOrder({ ...order, travelType: event.target.value as string });
                }}
              >
                <MenuItem value={undefined}>Travel Type</MenuItem>
                <MenuItem value="DOM">Domestic</MenuItem>
                <MenuItem value="INT">International</MenuItem>
              </Select> */}
            </FormControl>
          </Grid>
          <Grid className={classes.padding} xs={12} item>
            <b className={classes.label}>Travel Destination</b>
            <b className="red-star">*</b>
            <TextField
              className={classes.width}
              placeholder="Travel Destination"
              size="small"
              value={order.destination}
              variant="outlined"
              required
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                if (
                  event.target.value !== '1' &&
                  event.target.value !== '2' &&
                  event.target.value !== '3' &&
                  event.target.value !== '4' &&
                  event.target.value !== '5' &&
                  event.target.value !== '6' &&
                  event.target.value !== '7' &&
                  event.target.value !== '8' &&
                  event.target.value !== '9' &&
                  event.target.value !== '0'
                )
                  setOrder({ ...order, destination: event.target.value as string });
              }}
            />
          </Grid>
          <Grid className={classes.padding} container>
            <Box mr={2}>
              <SaveButton type="submit" variant="contained">
                Submit
              </SaveButton>
            </Box>

            {params.id && (
              <>
                {!+order.isCancelled ? (
                  <>
                    <Box mr={2}>
                      <Button
                        className={classes.capitalize}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setReschedule(true);
                        }}
                      >
                        Reschedule
                      </Button>
                    </Box>
                    <Box mr={2}>
                      <Button
                        className={classes.cancel}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          setCancelOrder(true);
                          handleClickOpen();
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Box mr={2}>
                    <Button className={classes.cancel} color="secondary" variant="contained" disabled>
                      Cancelled
                    </Button>
                  </Box>
                )}
                <Box mr={2}>
                  <DeleteButton
                    variant="contained"
                    onClick={() => {
                      setCancelOrder(false);
                      handleClickOpen();
                    }}
                  >
                    Delete
                  </DeleteButton>
                </Box>
                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={reschedule}
                  onClose={() => {
                    setReschedule(false);
                  }}
                >
                  <form onSubmit={handleReschedule}>
                    <DialogTitle id="alert-dialog-title"> Reschedule Order</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <Grid className={classes.padding} xs={12} item>
                          <b className={classes.label}>Set New Appointment Date</b>
                          <TextField
                            className={classes.width}
                            size="small"
                            type="datetime-local"
                            value={newAppointment}
                            variant="outlined"
                            required
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                              setNewAppointment(event.target.value as string);
                            }}
                          />
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={() => {
                          setReschedule(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button color="secondary" type="submit" autoFocus>
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>

                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={open}
                  onClose={handleCloseAlert}
                >
                  <DialogTitle id="alert-dialog-title"> {cancelOrder ? 'Cancel alert' : 'Delete alert'}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to {cancelOrder ? 'cancel' : 'delete'} the order
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={handleCloseAlert}>
                      No
                    </Button>
                    <Button color="secondary" autoFocus onClick={cancelOrder ? handleCancel : handleDelete}>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Grid>
        </form>
        {params.id ? (
          <Grid className={classes.padding} container>
            <Box mb={2} mt={5}>
              <h2 className={classes.label}>Samples</h2>
            </Box>
            <Grid xs={12} item>
              <SampleDetails data={samples} />
            </Grid>
            <Box mb={2} mt={5}>
              <h2 className={classes.label}>Tests</h2>
            </Box>
            <Grid xs={12} item>
              <TestsLists data={tests} />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </Container>
    </Grid>
  );
}
