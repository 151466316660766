import React from "react";

import SampleListing from "./SampleListing";

const SamplePage: React.FC = () => {
  return (
    <div>
      <SampleListing />
    </div>
  );
};

export default SamplePage;
