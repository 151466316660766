import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { useStyles } from './Styles';
import { TabPanelProps } from './Models';
import { tabs } from './data';


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            aria-labelledby={`scrollable-auto-tab-${index}`}
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function a11yProps(index: any) {

    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}



export default function ScrollableTabsButtonAuto(): React.ReactElement {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar color="default" position="static">
                <Tabs
                    aria-label="scrollable auto tabs example"
                    indicatorColor="primary"
                    scrollButtons="auto"
                    textColor="primary"
                    value={value}
                    variant="scrollable"
                    onChange={handleChange}
                >
                    {
                        tabs.map((t) => {
                            return <Tab key={t.id} label={t.label} {...a11yProps(t.id)} />
                        })
                    }
                </Tabs>
            </AppBar>
            {
                tabs.map((t) => {

                    return (
                        <TabPanel key={t.id} index={t.id} value={value} >
                            {t.component}
                        </TabPanel>
                    )
                })
            }
        </div>
    );
}
