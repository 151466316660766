import { Dialog, DialogTitle, Grid, Box, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { FilterProps, Export } from './Models';
import { useStyles1, SaveButton, DeleteButton, CloneButton } from './styles';

export default function FilterDialog(props: FilterProps): React.ReactElement {
  const classes = useStyles1();
  const { filterOpen, filterValues, onFilterClose } = props;

  const [filters, setFilters] = React.useState<Export>({
    id: filterValues.id,
    externalId: filterValues.externalId,
    labId: filterValues.labId,
    order: filterValues.order,
    accessionId: filterValues.accessionId,
    address: filterValues.address,
    assigningAuthority: filterValues.assigningAuthority,
    birthDate: filterValues.birthDate,
    firstName: filterValues.firstName,
    lastName: filterValues.lastName,
    sex: filterValues.sex,
    city: filterValues.city,
    state: filterValues.state,
    zipCode: filterValues.zipCode,
    specimanType: filterValues.specimanType
  });

  const [state, setState] = React.useState(false);

  const [able, setAble] = React.useState(false);

  let count = 1;

  const handleClose = (prop: boolean) => {
    if (prop) {
      onFilterClose({ ...filters });
    }
    onFilterClose();
  };

  const handleCloses = (propses: boolean) => {
    if (propses) {
      onFilterClose({
        id: '',
        externalId: '',
        labId: '',
        order: '',
        accessionId: '',
        address: '',
        assigningAuthority: '',
        birthDate: '',
        firstName: '',
        lastName: '',
        sex: '',
        city: '',
        state: '',
        zipCode: '',
        specimanType: ''
      });
    }
    onFilterClose();
  };

  const close = () => {
    handleClose(true);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
      open={filterOpen}
      fullWidth
      onClose={() => {
        handleClose(false);
      }}
    >
      <Box p={4}>
        <div className={classes.widthparents}>
          <DialogTitle className={classes.widthparent} id="simple-dialog-title">
            Filters
          </DialogTitle>
          <div className={classes.close} onClick={() => handleClose(false)}>
            <CloseIcon />
          </div>
        </div>

        <form
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
            handleClose(true);
          }}
        >
          <Grid container>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Sample Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Sample Id"
                size="small"
                value={filters.id}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.id = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>External Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="External Id"
                size="small"
                value={filters.externalId}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.externalId = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Lab Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Lab Id"
                size="small"
                value={filters.labId}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.labId = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Order</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Order"
                size="small"
                value={filters.order}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.order = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Accession Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Accession Id"
                size="small"
                value={filters.accessionId}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.accessionId = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Address</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Address"
                size="small"
                value={filters.address}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.address = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Assigning Authority</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Assigning Authority"
                size="small"
                value={filters.assigningAuthority}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.assigningAuthority = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Birth Date</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Birth Date"
                size="small"
                type="date"
                value={filters.birthDate}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.birthDate = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>First Name</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="First Name"
                size="small"
                value={filters.firstName}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.firstName = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Last Name</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Last Name"
                size="small"
                value={filters.lastName}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.lastName = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Sex</b>

              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.sex}
                  variant="outlined"
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  onChange={(event) => {
                    const tmp = { ...filters };
                    tmp.sex = event.target.value as string;
                    setFilters(tmp);
                    setAble(true);
                  }}
                >
                  <MenuItem value="undefined">Patient Sex</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Prefer Not to State">Prefer Not to State</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>City</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="City"
                size="small"
                value={filters.city}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.city = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>State</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="State"
                size="small"
                value={filters.state}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.state = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>ZipCode</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="ZipCode"
                size="small"
                value={filters.zipCode}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.zipCode = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Speciman Type</b>

              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.specimanType}
                  variant="outlined"
                  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                  onChange={(event) => {
                    const tmp = { ...filters };
                    tmp.specimanType = event.target.value as string;
                    setFilters(tmp);
                    setAble(true);
                  }}
                >
                  <MenuItem value="Mid-turbinate">Mid-turbinate</MenuItem>
                  <MenuItem value="Nasopharyngel">Nasopharyngel</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid className={classes.padding} container>
              <Box mr={2}>
                <SaveButton type="submit" variant="contained">
                  Apply Filters
                </SaveButton>
              </Box>
              <Box mr={2}>
                <DeleteButton
                  onClick={() => {
                    setFilters({
                      id: '',
                      externalId: '',
                      labId: '',
                      order: '',
                      accessionId: '',
                      address: '',
                      assigningAuthority: '',
                      birthDate: '',
                      firstName: '',
                      lastName: '',
                      sex: '',
                      city: '',
                      state: '',
                      zipCode: '',
                      specimanType: ''
                    });
                    setState(able);
                  }}
                >
                  Clear Filter
                </DeleteButton>
              </Box>
              <Box mr={2}>
                <CloneButton
                  onClick={() => {
                    setFilters({
                      id: '',
                      externalId: '',
                      labId: '',
                      order: '',
                      accessionId: '',
                      address: '',
                      assigningAuthority: '',
                      birthDate: '',
                      firstName: '',
                      lastName: '',
                      sex: '',
                      city: '',
                      state: '',
                      zipCode: '',
                      specimanType: ''
                    });
                    setState(able);
                    handleCloses(true);

                    if (count !== 1) {
                      if (!state) {
                        close();
                      }
                      count++;
                    }
                  }}
                >
                  Reset Filter
                </CloneButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}
