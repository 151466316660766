import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { TextField } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import image from '../../assets/images/image.jpeg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import './styles.scss';
import { config } from '../../config';

import { Toast } from './Model';

const LoginPage: React.FC = () => {
  const [loginRole, setLoginRole] = useState(true);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [load, setLoad] = useState(false);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });
  const token: string = jwt.sign({ sub: config.clent.id }, config.clent.secretKey, {
    expiresIn: config.clent.expiresIn
  });

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const toggleRole = (isCenterLogin: boolean) => {
    setLoginRole(isCenterLogin);
  };

  const history = useHistory();

  React.useEffect(() => {
    const id = localStorage.getItem('userId');

    if (id) {
      history.push('/center/dashboard');
    }
  }, []);

  const login = (accessTokens: string) => {
    axios({
      method: 'post',
      url: config.baseUrl + config.apiURL.userLogin,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        authorization: accessTokens
      },
      data: {
        userName,
        password,
        isCenter: loginRole ? 1 : 0,
        isDoctor: loginRole ? 0 : 1
      }
    })
      .then((response) => {
        if (response.data.status === 200) {
          localStorage.setItem('userId', response.data.data.id);
          localStorage.setItem('limsKey', accessTokens);

          setToast({
            ...toast,
            open: true,
            severity: response.data.status === 200 ? 1 : 0,
            message: response.data.message,
            redirect: true
          });
        } else {
          setToast({
            ...toast,
            open: true,
            severity: response.data.status === 200 ? 1 : 0,
            message: response.data.message,
            redirect: false
          });
        }
        setLoad(false);
      })
      .catch((err) => alert(err.message));
  };

  const accessToken = (tokens: string) => {
    axios({
      method: 'post',
      url: config.baseUrl + config.apiURL.userGetAccessToken,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      data: {
        assertion: tokens
      }
    })
      .then((response) => {
        if (response.data.status === 200) {
          login(response.data.data.token);
        }
      })
      .catch((err) => alert(err.message));
  };

  const loginUser = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoad(true);

    accessToken(token);
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });

    if (toast.redirect) {
      history.push('/center/dashboard');
    }
  };

  return (
    <div className="loginPage">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>

      <div className="login-wrapper">
        <Backdrop
          className="a"
          open={load}
          style={{
            zIndex: 3000,
            color: '#fff'
          }}
        >
          <CircularProgress />
        </Backdrop>
        <Header />
        <div className="row login-container">
          <div className="left-container">
            <div>
              <h2 className="login-header">Democratising Healthcare Diagnostics</h2>
              <h3 className="login-sub-header">Login to Smart | Simple | Secure LIMS</h3>
            </div>
            <div className="pad-top-20">
              <button className={loginRole ? 'btn-primary' : 'login-role-inactive'} onClick={() => toggleRole(true)}>
                Center Login
              </button>
              <button className={!loginRole ? 'btn-primary' : 'login-role-inactive'} onClick={() => toggleRole(false)}>
                Doctor Login
              </button>
            </div>

            <form className="pad-top-20" onSubmit={loginUser}>
              <TextField
                className="input"
                color="primary"
                placeholder="Username"
                size="small"
                type="text"
                variant="outlined"
                required
                onChange={(e) => setUserName(e.target.value)}
              />
              <TextField
                className="input "
                color="primary"
                placeholder="Password"
                size="small"
                style={{ marginTop: '10px' }}
                type="password"
                variant="outlined"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <br />
              <button className="button-success mar-top-10" type="submit">
                {load ? 'Signing...' : 'Sign In'}
              </button>
              <br />
              <br />
              <Link className="forgot-password-link" to="/forgetPassword">
                Forgot Password?
              </Link>
            </form>
          </div>
          <div className="right-container">
            <img alt="promotional" draggable={false} src={image} />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LoginPage;
