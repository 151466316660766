import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import IconDashboard from '@material-ui/icons/Dashboard';
import Assignment from '@material-ui/icons/Assignment';
import ListAlt from '@material-ui/icons/ListAlt';
import DehazeIcon from '@material-ui/icons/Dehaze';
import LocationOn from '@material-ui/icons/LocationOn';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BookmarksIcon from '@material-ui/icons/Bookmarks';

import AppMenuItem from './AppMenuItem';

const appMenuItems = [
  {
    name: 'Dashboard',
    link: '/center/dashboard',
    Icon: IconDashboard
  },
  {
    name: 'Orders',
    link: '/center/orders',
    Icon: ListAlt
  },
  {
    name: 'Samples',
    link: '/center/samples',
    Icon: DehazeIcon
  },
  {
    name: 'Testing',
    link: '/center/testing',
    Icon: Assignment
  },
  {
    name: 'Customers',
    link: '/center/customers',
    Icon: LocationOn
  },
  {
    name: 'Assay',
    link: '/center/assay',
    Icon: AssessmentIcon
  },

  {
    name: 'Batches',
    link: '/center/batchs',
    Icon: BookmarksIcon
  }
];

const drawerWidth = 240;

const useStyles = makeStyles(() =>
  createStyles({
    appMenu: {
      marginTop: '0px',
      marginRight: '0px',
      width: '100%',
      background: '#5D9CEC',
      color: 'white'
    },
    navList: {
      width: drawerWidth
    },
    menuItem: {
      width: drawerWidth
    },
    menuItemIcon: {
      color: '#97c05c'
    }
  })
);

const AppMenu: React.FC = () => {
  const classes = useStyles();

  return (
    <List className={classes.appMenu} component="nav" disablePadding>
      {appMenuItems.map((item) => (
        <AppMenuItem {...item} key={item.name} />
      ))}
    </List>
  );
};

export default AppMenu;
