import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  Button,
  Container,
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Popover
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import AppsIcon from '@material-ui/icons/Apps';
import { FaDownload, FaFilter } from 'react-icons/fa';
import moment from 'moment';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

import ReactExport from './Export.d';
import { getComparator, stableSort } from './utilities';
import {
  useStyles1,
  useToolbarStyles,
  useStyles,
  StyledCell,
  StyledTablePagination,
  StyledPagination,
  StyledButton
} from './styles';
import {
  Data,
  EnhancedTableProps,
  HeadCell,
  EnhancedTableToolbarProps,
  ShowFields,
  Export,
  Toast,
  Filter,
  Body
} from './Models';
import StyledDialog from './StyledDialog';
import FilterDialog from './FilterDialog';
import { fetchData, multipleDeletes } from './api';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type Order = 'asc' | 'desc';

const headCells: HeadCell[] = [
  { id: 'id', numeric: false, disablePadding: false, label: 'Order Id' },
  { id: 'confirmationId', numeric: false, disablePadding: false, label: 'Confirmation Id' },
  { id: 'customerAccountName', numeric: false, disablePadding: false, label: 'Customer Account' },
  { id: 'dateReceived', numeric: false, disablePadding: false, label: 'Date Received' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'appointmentDate', numeric: false, disablePadding: false, label: 'Appointment Date' },
  { id: 'testCount', numeric: false, disablePadding: false, label: 'Test Count' },
  { id: 'samplesCount', numeric: false, disablePadding: false, label: 'Samples Count' },
  { id: 'rescheduledDate', numeric: false, disablePadding: false, label: 'RescheduledDate' },
  { id: 'cancelledDate', numeric: false, disablePadding: false, label: 'Cancelled Date' },
  { id: 'isCancelled', numeric: false, disablePadding: false, label: 'Is Cancelled' },
  { id: 'destination', numeric: false, disablePadding: false, label: 'Destination' },
  { id: 'travelType', numeric: false, disablePadding: false, label: 'Travel Type' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' }
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const classes1 = useStyles1();
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, fields } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledCell padding="checkbox">
          <Box pl={0} pr={10}>
            <Checkbox
              checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              indeterminate={numSelected > 0 && rowCount > numSelected}
              inputProps={{ 'aria-label': 'select all desserts' }}
              onChange={onSelectAllClick}
            />
          </Box>
        </StyledCell>
        {headCells.map(
          (headCell) =>
            fields[headCell.id] && (
              <StyledCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <th className={classes1.heading}>{headCell.label}</th>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, setDelToast } = props;
  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popover = open ? 'simple-popover' : undefined;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMultipleDeletes = () => {
    setLoader(true);
    multipleDeletes(selected)
      .then(async (response: Response) => {
        setOpen(false);
        setLoader(false);
        const data = await response.json();

        if (data.status === 200) {
          setDelToast(data.message, 1);
        } else {
          setDelToast(data.message, 0);
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setDelToast(err.message, 0);
      });
  };

  return numSelected > 0 ? (
    <Box pb={5}>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 && (
          <Typography className={classes.title} color="inherit" component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          id={popover}
          open={openPopover}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handleClosePopover}
        >
          <Box p={3} pl={0}>
            <TreeView
              className={classes.root}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <TreeItem label="Print Order Labels" nodeId="1" />
              <br />
              <TreeItem label="Download Reports" nodeId="2">
                <TreeItem label="To Zip" nodeId="3" />
                <TreeItem label="To PDF" nodeId="4" />
              </TreeItem>
            </TreeView>
          </Box>
        </Popover>
        {numSelected > 0 && (
          <Tooltip title="Delete">
            {loader ? (
              <CircularProgress />
            ) : (
              <>
                <IconButton aria-label="delete" onClick={handleClickOpen}>
                  <DeleteIcon />
                </IconButton>
                <Dialog
                  aria-describedby="alert-dialog-description"
                  aria-labelledby="alert-dialog-title"
                  open={open}
                  onClose={handleClose}
                >
                  <DialogTitle id="alert-dialog-title">Delete all</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete the selected {numSelected} rows
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button color="secondary" autoFocus onClick={handleMultipleDeletes}>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Tooltip>
        )}
      </Toolbar>
    </Box>
  ) : (
    <></>
  );
};

function OrdersListing(): React.ReactElement {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);

  const classes1 = useStyles1();
  const [id, setId] = React.useState(true);
  const [status, setStatus] = React.useState(true);
  const [customerAccountName, setCustomerAccountName] = React.useState(true);
  const [confirmationId, setConfirmationId] = React.useState(true);
  const [email, setEmail] = React.useState(true);
  const [appointmentDate, setAppointmentDate] = React.useState(true);
  const [travelType, setTravelType] = React.useState(true);
  const [destination, setDestination] = React.useState(true);
  const [isCancelled, setIsCancelled] = React.useState(true);
  const [cancelledDate, setCancelledDate] = React.useState(true);
  const [rescheduledDate, setRescheduledDate] = React.useState(true);
  const [samplesCount, setSamplesCount] = React.useState(true);
  const [testCount, setTestCount] = React.useState(true);
  const [dateReceived, setDateReceived] = React.useState(true);
  const [successDelete, setSuccessDelete] = React.useState(false);
  const [rows, setRows] = React.useState<Data[]>([]);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: ''
  });
  const [count, setCount] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const [filter, setFilter] = React.useState<Filter>({
    open: false,
    values: {
      id: '',
      customerAccountName: '',
      confirmationId: '',
      email: '',
      travelType: '',
      destination: '',
      isCancelled: '',
      samplesCount: '',
      testCount: '',
      status: ''
    }
  });
  const [filter_body, setFilter_Body] = React.useState<Body[]>([]);

  const handleCloseFilter = (args?: Export) => {
    if (args) {
      setFilter({ open: false, values: args });
      const keys = Object.keys(args);
      const tmp_filter_body = [];

      for (const key of keys) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((args as any)[key].length) {
          tmp_filter_body.push({
            key,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value: (args as any)[key]
          });
        }
      }

      setFilter_Body(tmp_filter_body);
    }
    setFilter({ ...filter, open: false });
  };

  const handleOpenFilter = () => {
    setFilter({ ...filter, open: true });
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, orderId: string) => {
    const selectedIndex = selected.indexOf(orderId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, orderId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (orderId: string) => selected.indexOf(orderId) !== -1;

  const fields: ShowFields = {
    id,
    customerAccountName,
    dateReceived,
    confirmationId,
    email,
    appointmentDate,
    travelType,
    destination,
    isCancelled,
    cancelledDate,
    rescheduledDate,
    samplesCount,
    testCount,
    status
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (arg?: ShowFields) => {
    if (arg) {
      setId(arg.id);
      setCustomerAccountName(arg.customerAccountName);
      setDateReceived(arg.dateReceived);
      setConfirmationId(arg.confirmationId);
      setEmail(arg.email);
      setAppointmentDate(arg.appointmentDate);
      setTravelType(arg.travelType);
      setDestination(arg.destination);
      setIsCancelled(arg.isCancelled);
      setCancelledDate(arg.cancelledDate);
      setRescheduledDate(arg.rescheduledDate);
      setSamplesCount(arg.samplesCount);
      setTestCount(arg.testCount);
      setStatus(arg.status);
    }
    setOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose2 = () => {
    setToast({ ...toast, open: false });
  };

  React.useEffect(() => {
    setLoader(true);
    fetchData(rowsPerPage, page + 1, filter_body)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoader(false);

        if (data.status === 200) {
          setCount(data.data.count);
          setRows(data.data.list);

          if (data.data.list.length === 0) {
            setToast({ open: true, severity: 0, message: 'No Data Found' });
          }
        } else {
          setToast({ open: true, severity: 0, message: data.message });
        }
      })
      .catch((err: Error) => {
        setLoader(false);
        setToast({ open: true, severity: 0, message: err.message });
      });
  }, [page, rowsPerPage, successDelete, filter_body]);

  const tmpExport: Export[] = [];

  return (
    <Container>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={toast.open} onClose={handleClose2}>
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose2}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Grid justify="flex-end" container>
        <Grid item>
          <Box pb={2}>
            <Button className={classes1.info}>
              <Link className={classes1.white} to="/center/samples/orderDetail">
                + New Order
              </Link>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <div>
        <Paper className={classes.paper} elevation={5}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            setDelToast={(message: string, severity: number) => {
              setToast({ open: true, message, severity });

              if (severity) {
                setSuccessDelete(!successDelete);
                setSelected([]);
              }
            }}
          />
          <Grid justify="flex-end" container>
            <Grid className={classes1.widthMaxContent} item>
              <Grid container>
                <Grid className={classes1.widthMaxContent} item>
                  <Tooltip placement="top" title="View Menu">
                    <StyledButton
                      className={classes1.info}
                      color="primary"
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      <AppsIcon />
                    </StyledButton>
                  </Tooltip>
                  <StyledDialog
                    open={open}
                    selectedValue={{
                      id,
                      customerAccountName,
                      dateReceived,
                      confirmationId,
                      email,
                      appointmentDate,
                      travelType,
                      destination,
                      isCancelled,
                      cancelledDate,
                      rescheduledDate,
                      samplesCount,
                      testCount,
                      status
                    }}
                    onClose={handleClose}
                  />
                  <ExcelFile
                    element={
                      <Tooltip placement="top" title="Export">
                        <StyledButton className={classes1.btnStyle} color="primary" variant="contained">
                          <FaDownload />
                        </StyledButton>
                      </Tooltip>
                    }
                  >
                    <ExcelSheet data={tmpExport} name="Employees">
                      <ExcelColumn label="Id" value="id" />
                      <ExcelColumn label="Customer Account" value="customerAccountName" />
                      <ExcelColumn label="Date Received" value="dateReceived" />
                      <ExcelColumn label="ConfirmationId" value="confirmationId" />
                      <ExcelColumn label="Email" value="email" />
                      <ExcelColumn label="Appointment Date" value="appointmentDate" />
                      <ExcelColumn label="Travel Type" value="travelType" />
                      <ExcelColumn label="Destination" value="destination" />
                      <ExcelColumn label="Is Cancelled" value="isCancelled" />
                      <ExcelColumn label="Cancelled Date" value="cancelledDate" />
                      <ExcelColumn label="Rescheduled Date" value="rescheduledDate" />
                      <ExcelColumn label="Samples Count" value="samplesCount" />
                      <ExcelColumn label="Test Count" value="testCount" />
                      <ExcelColumn label="Status" value="status" />
                    </ExcelSheet>
                  </ExcelFile>
                </Grid>
                <Tooltip placement="top" title="Filter">
                  <StyledButton
                    className={classes1.filterBtn}
                    color="primary"
                    variant="contained"
                    onClick={handleOpenFilter}
                  >
                    <FaFilter />
                  </StyledButton>
                </Tooltip>
                <FilterDialog filterOpen={filter.open} filterValues={filter.values} onFilterClose={handleCloseFilter} />
                <Grid className={classes1.widthMaxContent} item>
                  <StyledTablePagination
                    ActionsComponent={() => {
                      return (
                        <Grid direction="row" container>
                          <Grid item>
                            <StyledPagination
                              boundaryCount={1}
                              className={classes1.widthparent}
                              color="primary"
                              count={Math.ceil(count / rowsPerPage)}
                              page={page + 1}
                              shape="rounded"
                              siblingCount={0}
                              variant="outlined"
                              showFirstButton
                              showLastButton
                              onChange={(e, p) => {
                                handleChangePage(e, p - 1);
                              }}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                    className={classes1.widthparent}
                    count={count}
                    labelRowsPerPage=""
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 50, 100]}
                    // onChangePage={handleChangePage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table
              aria-label="enhanced table"
              aria-labelledby="tableTitle"
              className={classes.table}
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                classes={classes}
                fields={fields}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                rowCount={rows.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              {!loader && (
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    tmpExport.push(row);

                    return (
                      <TableRow
                        key={row.id}
                        aria-checked={isItemSelected}
                        className={+row.isCancelled === 1 ? classes1.red : classes1.green}
                        hover={+row.isCancelled === 1 ? false : true}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledCell className={classes1.grey} padding="checkbox">
                          <Box pl={0} pr={10}>
                            <Checkbox
                              checked={isItemSelected}
                              color="primary"
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </Box>
                        </StyledCell>
                        {id && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.id}
                          </StyledCell>
                        )}
                        {confirmationId && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Link className="a" to={'/center/samples/orderDetail/' + row.id}>
                              <Box className={classes1.border}>{row.confirmationId}</Box>
                            </Link>
                          </StyledCell>
                        )}
                        {customerAccountName && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Link className="a" to={'/center/samples/customerDetail/' + row.custId}>
                              <Box className={classes1.border}>{row.customerAccountName}</Box>
                            </Link>
                          </StyledCell>
                        )}
                        {dateReceived && (
                          <StyledCell align="left" className={classes1.grey}>
                            <Box>{moment(row.dateReceived).format('DD-MM-YYYY,hh:mm a')}</Box>
                          </StyledCell>
                        )}
                        {email && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.email}
                          </StyledCell>
                        )}
                        {appointmentDate && (
                          <StyledCell align="left" className={classes1.grey}>
                            {moment(row.appointmentDate).format('DD-MM-YYYY,h:mm a')}
                          </StyledCell>
                        )}
                        {testCount && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.testCount}
                          </StyledCell>
                        )}
                        {samplesCount && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.samplesCount}
                          </StyledCell>
                        )}
                        {rescheduledDate && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.rescheduledDate}
                          </StyledCell>
                        )}
                        {cancelledDate && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.cancelledDate}
                          </StyledCell>
                        )}
                        {isCancelled && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.isCancelled}
                          </StyledCell>
                        )}
                        {destination && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.destination}
                          </StyledCell>
                        )}
                        {travelType && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.travelType}
                          </StyledCell>
                        )}
                        {status && (
                          <StyledCell align="left" className={classes1.grey}>
                            {row.status}
                          </StyledCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
            <div hidden>
              <FormControlLabel
                control={<Switch checked={dense} color="primary" onChange={handleChangeDense} />}
                label="Dense padding"
              />
            </div>
          </TableContainer>
        </Paper>
        {loader && (
          <Grid xs={12}>
            <Grid justify="center" container>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
}

export default OrdersListing;
