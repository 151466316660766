import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Box, Container, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

// eslint-disable-next-line import/order
import { useStyles, StyledGlass, StyledAccessAlarmRoundedIcon, StyledPieChartRoundedIcon } from './styles';

import './App.css';

import { getDashBoardLabStats, getDashBoardMyLabStats } from './Api';

function App(): React.ReactElement {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [labStats, setLabStats]: any = useState();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [myLabStats, setMyLabStats]: any = useState();
  const [loader, setLoader] = React.useState(true);
  const history = useHistory();

  const dashBoardDetails = async () => {
    if (window.localStorage.limsKey) {
      await getDashBoardLabStats().then((data) => {
        setLabStats(data.data.data);
      });
      await getDashBoardMyLabStats().then((data) => {
        setMyLabStats(data.data.data);
        setLoader(false);
      });
    }
  };

  useEffect(() => {
    dashBoardDetails();
  }, []);

  return (
    <div>
      <div>
        <Container>
          <Backdrop className={classes.backdrop} open={loader}>
            <CircularProgress />
          </Backdrop>
          <Grid container>
            <Grid className={classes.root} xs={12} item>
              <Typography variant="h6">
                <Box pt={3}>Lab Stats</Box>
              </Typography>
            </Grid>
            <Grid className={classes.root} md={4} xs={12} item>
              <Grid
                className={classes.white}
                container
                onClick={() => history.push('/center/testings/status/NOTSTARTED')}
              >
                <Button fullWidth>
                  <Grid xs={6} item>
                    <Box p={2} pl={0}>
                      <div className="circle red">
                        <StyledGlass fontSize="large" />
                      </div>
                    </Box>
                  </Grid>
                  <Grid xs={6} item>
                    <Box pb={1} pr={2} pt={0}>
                      <Typography align="right" variant="h5">
                        {labStats && myLabStats ? labStats.countStarted : '0'}
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      <Typography align="right" color="textSecondary" variant="subtitle2">
                        <small className="text-capitalize">Tests Not Started</small>
                      </Typography>
                    </Box>
                  </Grid>
                </Button>
              </Grid>
            </Grid>

            <Grid className={classes.root} md={4} xs={12} item>
              <Grid
                className={classes.white}
                container
                onClick={() => history.push('/center/testings/status/INPROGRESS')}
              >
                <Button fullWidth>
                  <Grid xs={6} item>
                    <Box p={2} pl={0}>
                      <div className="circle blue">
                        <StyledPieChartRoundedIcon fontSize="large" />
                      </div>
                    </Box>
                  </Grid>
                  <Grid xs={6} item>
                    <Box pb={1} pr={2} pt={0}>
                      <Typography align="right" variant="h5">
                        {labStats && myLabStats ? labStats.countProgress : '0'}
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      <Typography align="right" color="textSecondary" variant="subtitle2">
                        <small className="text-capitalize">Tests in Progress</small>
                      </Typography>
                    </Box>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.root} md={4} xs={12} item>
              <Grid className={classes.white} container>
                <Button disabled fullWidth>
                  <Grid xs={6} item>
                    <Box p={2} pl={0}>
                      <div className="circle yellow">
                        <StyledAccessAlarmRoundedIcon fontSize="large" />
                      </div>
                    </Box>
                  </Grid>
                  <Grid xs={6} item>
                    <Box pb={1} pr={2} pt={0}>
                      <Typography align="right" color="textPrimary" variant="h5">
                        {labStats && myLabStats ? Number(labStats.avgTurnAroundTime) : '0'} Days
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      <Typography align="right" color="textSecondary" variant="subtitle2">
                        <small className="text-capitalize">Average Turnaround Time</small>
                      </Typography>
                    </Box>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.root} xs={12} item>
              <Typography variant="h6">
                <Box pt={3}>My Stats</Box>
              </Typography>
            </Grid>
            <Grid className={classes.root} md={4} xs={12} item>
              <Grid
                className={classes.white}
                container
                onClick={() => history.push(`/center/testings/status/NOTSTARTED/${localStorage.getItem('userId')}`)}
              >
                <Button fullWidth>
                  <Grid xs={6} item>
                    <Box p={2} pl={0}>
                      <div className="circle orange">
                        <StyledGlass fontSize="large" />
                      </div>
                    </Box>
                  </Grid>
                  <Grid xs={6} item>
                    <Box pb={1} pr={2} pt={0}>
                      <Typography align="right" variant="h5">
                        {labStats && myLabStats ? myLabStats.countStarted : '0'}
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      <Typography align="right" color="textSecondary" variant="subtitle2">
                        <small className="text-capitalize">Tests Not Started</small>
                      </Typography>
                    </Box>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.root} md={4} xs={12} item>
              <Grid
                className={classes.white}
                container
                onClick={() => history.push(`/center/testings/status/INPROGRESS/${localStorage.getItem('userId')}`)}
              >
                <Button fullWidth>
                  <Grid xs={6} item>
                    <Box p={2} pl={0}>
                      <div className="circle blue-2">
                        <StyledPieChartRoundedIcon fontSize="large" />
                      </div>
                    </Box>
                  </Grid>
                  <Grid xs={6} item>
                    <Box pb={1} pr={2} pt={0}>
                      <Typography align="right" variant="h5">
                        {labStats && myLabStats ? myLabStats.countProgress : '0'}
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      <Typography align="right" color="textSecondary" variant="subtitle2">
                        <small className="text-capitalize">Tests in Progress</small>
                      </Typography>
                    </Box>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.root} md={4} xs={12} item>
              <Grid className={classes.white} container>
                <Button disabled fullWidth>
                  <Grid xs={6} item>
                    <Box p={2} pl={0}>
                      <div className="circle yellow-2">
                        <StyledAccessAlarmRoundedIcon fontSize="large" />
                      </div>
                    </Box>
                  </Grid>
                  <Grid xs={6} item>
                    <Box pb={1} pr={2} pt={0}>
                      <Typography align="right" color="textPrimary" variant="h5">
                        {labStats && myLabStats ? Number(myLabStats.avgTurnAroundTime) : '0'} Days
                      </Typography>
                    </Box>
                    <Box pr={2}>
                      <Typography align="right" color="textSecondary" variant="subtitle2">
                        <small className="text-capitalize">Average Turnaround Time</small>
                      </Typography>
                    </Box>
                  </Grid>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}

export default App;
