import React from 'react';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { useHistory, Link, useParams } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import image from '../../assets/images/image.jpeg';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import './styles.scss';
import { config } from '../../config';

import { resetPassword } from './api';
import { Toast } from './Model';

type Params = {
  mail: string;
  code: string;
};

const ForgetPassword: React.FC = () => {
  const history = useHistory();
  const params = useParams<Params>();

  const encryptedMail: string = params.mail + params.code;
  const [password, setPassword] = React.useState('');
  const [load, setLoad] = React.useState(false);
  const [toast, setToast] = React.useState<Toast>({
    open: false,
    severity: 0,
    message: '',
    redirect: false
  });

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const login = (token: string) => {
    resetPassword(encryptedMail, password, token)
      .then(async (response: Response) => {
        const data = await response.json();
        setLoad(false);

        if (data.status === 200) {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: true });
        } else {
          setToast({ ...toast, open: true, severity: 0, message: data.message, redirect: false });
        }
      })
      .catch((err: Error) => {
        setLoad(false);
        setToast({ ...toast, open: true, severity: 0, message: err.message, redirect: false });
      });
  };

  const accessToken = (token: string) => {
    axios({
      method: 'post',
      url: config.baseUrl + config.apiURL.userGetAccessToken,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      data: {
        assertion: token
      }
    })
      .then((response) => {
        if (response.data.status === 200) {
          login(response.data.data.token);
        }
      })
      .catch((err) => alert(err.message));
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setLoad(true);

    if (encryptedMail) {
      const token: string = jwt.sign({ sub: config.clent.id }, config.clent.secretKey, {
        expiresIn: config.clent.expiresIn
      });

      accessToken(token);
    }
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });

    if (toast.redirect) {
      history.push('/resetPassword');
    }
  };

  return (
    <div className="loginPage">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={1500}
        open={toast.open}
        onClose={handleClose}
      >
        <Alert severity={toast.severity ? 'success' : 'error'} onClose={handleClose}>
          {toast.message}
        </Alert>
      </Snackbar>
      <div className="login-wrapper">
        <Backdrop
          className="a"
          open={load}
          style={{
            zIndex: 3000,
            color: '#fff'
          }}
        >
          <CircularProgress />
        </Backdrop>

        <Header />
        <div className="row login-container">
          <div className="left-container">
            <div>
              <h2 className="login-header">Forgot Password</h2>
              <h3>Enter the registered email to recover your password </h3>
            </div>

            <form className="pad-top-20" onSubmit={handleSubmit}>
              <TextField
                color="primary"
                size="small"
                style={{ width: '63.5%' }}
                value={encryptedMail}
                variant="outlined"
                disabled
                required
              />
              <br />
              <TextField
                color="primary"
                placeholder="New Password"
                size="small"
                style={{ width: '63.5%', marginTop: '10px' }}
                type="password"
                variant="outlined"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <br />
              <br />
              <button className="button-success width-65" type="submit">
                {load ? 'Submitting...' : 'Submit'}
              </button>
              <br />
              <br />
            </form>
            <div>
              <p>
                Remembered Password?{' '}
                <Link className="forgot-password-link" to="/login">
                  &nbsp; Login
                </Link>
              </p>
            </div>
          </div>
          <div className="right-container">
            <img alt="promotional" draggable={false} src={image} />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ForgetPassword;
