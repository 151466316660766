import React from 'react';

import icon from '../../assets/images/logo.png';
import './styles.scss';

const Header: React.FC = () => {
  return (
    <div className="header-container">
      <img alt="company icon" draggable={false} src={icon} />
    </div>
  );
};

export default Header;
