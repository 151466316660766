import { config } from '../../config';

import { Body, UpdateMany } from './Models';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchData = async (rowsPerPage: number, page: number, filters: Body[]) => {
  return await fetch(config.baseUrl + config.apiURL.testList, {
    method: 'POST',
    body: JSON.stringify({
      pageSize: rowsPerPage,
      pageNo: page,
      field: 'id',
      sort: 'DESC',
      filters
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });

};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const multipleDeletes = async (ids: string[]) => {
  return await fetch(config.baseUrl + config.apiURL.testDeleteById, {
    method: 'POST',
    body: JSON.stringify({
      id: [...ids],
      deleteStatus: 1
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });

};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateMany = async (ids: string[], updated: UpdateMany) => {
  const body: UpdateMany = { ...updated, id: [...ids] };
  
  return await fetch(config.baseUrl + config.apiURL.testUpdateMany, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });

};


