import { config } from '../../config';

export const resetPassword = async (email: string, password: string, accessToken: string): Promise<Response> => {
  const response = await fetch(config.baseUrl + config.apiURL.userResetPassword, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password
    }),
    headers: {
      authorization: accessToken,
      'content-type': config.contentType
    }
  });

  return response;
};
