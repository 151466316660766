import { Dialog, DialogTitle, Grid, Box, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import { FilterProps, Export } from './Models';
import { useStyles1, SaveButton, DeleteButton, CloneButton } from './styles';

export default function FilterDialog(props: FilterProps): React.ReactElement {
  const classes = useStyles1();
  const { filterOpen, filterValues, onFilterClose } = props;

  const [filters, setFilters] = React.useState<Export>({
    id: filterValues.id,
    customerAccountName: filterValues.customerAccountName,
    confirmationId: filterValues.confirmationId,
    email: filterValues.email,
    travelType: filterValues.travelType,
    destination: filterValues.destination,
    isCancelled: filterValues.isCancelled,
    samplesCount: filterValues.samplesCount,
    testCount: filterValues.testCount,
    status: filterValues.status
  });

  const [state, setState] = React.useState(false);

  const [able, setAble] = React.useState(false);

  let count = 1;

  const handleClose = (args: boolean) => {
    if (args) {
      onFilterClose({ ...filters });
    }
    onFilterClose();
  };

  const handleCloses = (args: boolean) => {
    if (args) {
      onFilterClose({
        id: '',
        customerAccountName: '',
        confirmationId: '',
        email: '',
        travelType: '',
        destination: '',
        isCancelled: '',
        samplesCount: '',
        testCount: '',
        status: ''
      });
    }
    onFilterClose();
  };

  const close = () => {
    handleClose(true);
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      maxWidth="sm"
      open={filterOpen}
      fullWidth
      onClose={() => {
        handleClose(false);
      }}
    >
      <Box p={4}>
        <div className={classes.widthparents}>
          <DialogTitle className={classes.widthparent} id="simple-dialog-title">
            Filters
          </DialogTitle>
          <div className={classes.close} onClick={() => handleClose(false)}>
            <CloseIcon />
          </div>
        </div>

        <form
          onSubmit={(event: React.SyntheticEvent) => {
            event.preventDefault();
            handleClose(true);
          }}
        >
          <Grid container>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Order Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Order Id"
                size="small"
                value={filters.id}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.id = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Customer Account Name</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Customer Account Name"
                size="small"
                value={filters.customerAccountName}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.customerAccountName = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Confirmation Id</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Confirmation Id"
                size="small"
                value={filters.confirmationId}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.confirmationId = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Email</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Email"
                size="small"
                value={filters.email}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.email = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>

            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Travel Type</b>

              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.travelType}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilters({ ...filters, travelType: event.target.value as string });
                  }}
                >
                  <MenuItem value={undefined}>Travel Type</MenuItem>
                  <MenuItem value="DOM">Domestic</MenuItem>
                  <MenuItem value="INT">International</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Destination</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Destination"
                size="small"
                value={filters.destination}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.destination = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}> Is Cancelled</b>

              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.isCancelled}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setFilters({ ...filters, isCancelled: event.target.value as string });
                  }}
                >
                  <MenuItem value="0">No</MenuItem>
                  <MenuItem value="1">Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Samples Count</b>
              <TextField
                className={classes.width}
                color="primary"
                placeholder="Samples Count"
                size="small"
                type="number"
                value={filters.samplesCount}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.samplesCount = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}>Test Count</b>

              <TextField
                className={classes.width}
                color="primary"
                placeholder="Test Count"
                size="small"
                type="number"
                value={filters.testCount}
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  const tmp = { ...filters };
                  tmp.testCount = event.target.value;
                  setFilters(tmp);
                  setAble(true);
                }}
              />
            </Grid>
            <Grid className={classes.padding} xs={6} item>
              <b className={classes.label}> Status</b>

              <FormControl className={classes.width} size="small">
                <Select
                  value={filters.status}
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    const tmp = { ...filters };
                    tmp.status = event.target.value as string;
                    setFilters(tmp);
                    setAble(true);
                  }}
                >
                  <MenuItem value="NOT STARTED">Not Started</MenuItem>
                  <MenuItem value="IN PROGRESS">In Progress</MenuItem>
                  <MenuItem value="NEEDS REVIEW">Needs Review</MenuItem>
                  <MenuItem value="CREATED">Created</MenuItem>
                  <MenuItem value="COMPLETED">Completed</MenuItem>
                  <MenuItem value="CANCELLED">Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.padding} container>
              <Box mr={2}>
                <SaveButton type="submit" variant="contained">
                  Apply Filters
                </SaveButton>
              </Box>
              <Box mr={2}>
                <DeleteButton
                  onClick={() => {
                    setFilters({
                      id: '',
                      customerAccountName: '',
                      confirmationId: '',
                      email: '',
                      travelType: '',
                      destination: '',
                      isCancelled: '',
                      samplesCount: '',
                      testCount: '',
                      status: ''
                    });
                    setState(able);
                  }}
                >
                  Clear Filter
                </DeleteButton>
              </Box>
              <Box mr={2}>
                <CloneButton
                  onClick={() => {
                    setFilters({
                      id: '',
                      customerAccountName: '',
                      confirmationId: '',
                      email: '',
                      travelType: '',
                      destination: '',
                      isCancelled: '',
                      samplesCount: '',
                      testCount: '',
                      status: ''
                    });
                    setState(able);
                    handleCloses(true);

                    if (count !== 1) {
                      if (!state) {
                        close();
                      }
                      count++;
                    }
                  }}
                >
                  Reset Filter
                </CloneButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
}
