import { config } from '../../../config';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fetchData = async (rowsPerPage: number, page: number) => {
  return await fetch(config.baseUrl + config.apiURL.sampleTypeList, {
    method: 'POST',
    body: JSON.stringify({
      pageSize: rowsPerPage,
      pageNo: page,
      field: 'id',
      sort: 'DESC'
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const multipleDeletes = async (ids: string[]) => {
  return await fetch(config.baseUrl + config.apiURL.sampleTypeDeleteById, {
    method: 'POST',
    body: JSON.stringify({
      id: [...ids],
      deleteStatus: 1
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const submit = async (body: { id?: string; name: string; description: string }) => {
  let endpoint = config.apiURL.sampleTypeAdd;

  if (body.id) {
    endpoint = config.apiURL.sampleTypeModify;
  }

  return await fetch(config.baseUrl + endpoint, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
