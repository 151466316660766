import { config } from '../../../config';

import { Params } from './Models';

export const fetchData = async (id: number): Promise<Response> => {
  return await fetch(config.baseUrl + config.apiURL.testDetails, {
    method: 'POST',
    body: JSON.stringify({
      id
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
export const submit = async (params: Params, body: string): Promise<Response> => {
  let url = config.baseUrl + config.apiURL.testAdd;

  if (params.id) {
    url = config.baseUrl + config.apiURL.testModify;
  }

  return await fetch(url, {
    method: 'POST',
    body,
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

export const del = async (id: number): Promise<Response> => {
  return await fetch(config.baseUrl + config.apiURL.testDeleteById, {
    method: 'POST',
    body: JSON.stringify({
      id,
      deleteStatus: 1
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

export const userDropdown = async (): Promise<Response> => {
  return await fetch(config.baseUrl + config.apiURL.userDropdown, {
    method: 'POST',
    body: JSON.stringify({
      field: 'id',
      sort: 'ASC'
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

export const assayDropdown = async (): Promise<Response> => {
  return await fetch(config.baseUrl + config.apiURL.testTypeDropdown, {
    method: 'POST',
    body: JSON.stringify({
      field: 'id',
      sort: 'ASC'
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};

export const testResults = async (id: number, orderId: number): Promise<Response> => {
  return await fetch(config.baseUrl + config.apiURL.testSendTestResults, {
    method: 'POST',
    body: JSON.stringify({
      id,
      orderId
    }),
    headers: {
      authorization: window.localStorage.limsKey,
      'content-type': config.contentType
    }
  });
};
